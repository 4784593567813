import React, { useEffect } from "react";
import { Routes, Route, Navigate, Switch } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Dealer from "../pages/Dealer";
import DealerDetails from "../pages/DealerDetails";
import Complaint from "../pages/Company";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import { ToastContainer } from "react-toastify";
import Contact from "../pages/Contact";
import Career from "../pages/career";
import ComplaintDetail from "../pages/ComplaintDetail";
import Careerdetail from "../pages/careerdetail";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/cars" element={<CarListing />} />
        <Route path="/dealer" element={<Complaint />} />
        <Route path="/dealer/:slug" element={<ComplaintDetail />} />
        <Route path="/company" element={<Dealer />} />
        <Route path="/company/:id" element={<DealerDetails />} />
        {/* <Route path="/cardetail/:id" element={<CarDetails />} /> */}
        <Route path="/car-detail/:slug" element={<CarDetails />} />
        <Route path="/new-car/:slug" element={<CarDetails />} />
        <Route path="/used-car/:slug" element={<CarDetails />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/career" element={<Career />} />
        {/* <Route path="/careerqwrer" element={<Career />} /> */}
        <Route path="/careerdetail/:id" element={<Careerdetail />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default Routers;
