import React, { useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import image from "../assets/all-images/slider-img/image_2023_03_29T19_55_03_939Z.png";
import { Formik, useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";

import "../styles/contact.css";
import { toast } from "react-toastify";

const ContactFormSchema = Yup.object({
  // nameregix: Yup.string().required("Name is required"),
  nameregix: Yup.string()
    .min(3, "Too short!")
    .max(30, "Too long!")
    .trim()
    .matches(/^[A-Za-z\s]*$/, "Name must contain only letters")
    .required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  subject: Yup.string()
    .max(150, "Maximum character limit (140) exceeded")
    .required("subject is required"),
  message: Yup.string()
    .max(500, "Maximum character limit (490) exceeded")
    .required("Message is required"),
});

const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const addContact = () => {
    setIsLoading(true);
    // e.preventDefault();
    // IF INPUTS EMPTY OR NULL OR UNDEFINED
    if (
      name != "" &&
      name != undefined &&
      name != null &&
      email != "" &&
      email != undefined &&
      email != null &&
      subject != "" &&
      subject != undefined &&
      subject != null &&
      message != "" &&
      message != undefined &&
      message != null
    ) {
      const data = new FormData();
      data.append("name", name);
      data.append("email", email);
      data.append("subject", subject);
      data.append("message", message);
      fetch(process.env.REACT_APP_URL + "/contact-us", {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            toast.success(data.message);
            navigate("/contact");
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setIsLoading(false);
          } else {
            toast.error("Cannot submit your query");
            setIsLoading(false);
          }
        });
    } else {
      toast.error("Fill the details");
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      nameregix: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: ContactFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      // seterror("");
      // fetchUserDetail(values);
      if (formik.isValid) {
        // alert("")
        addContact();
      }
      setSubmitting(false);

      // dispatch(setLoading(true));
    },
  });
  return (
    <Helmet title="Contact">
      {/* <CommonSection title="Contact" /> */}
      {/* <img src={image} /> */}
      <section>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Get In Touch</h6>
              {/* {console.log(formik, "formik")} */}
              <Formik>
                <Form onSubmit={formik.handleSubmit}>
                  <div style={{ position: "relative" }}>
                    <FormGroup className="contact__form">
                      <Input
                        placeholder="Your Name"
                        type="text"
                        name="nameregix"
                        value={name}
                        // onChange={(e) => setName(e.target.value)}
                        onChange={(e) => {
                          setName(e.target.value);
                          formik.setFieldValue("nameregix", e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </FormGroup>

                    {Boolean(formik?.errors?.nameregix) ? (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-18px",
                          left: "1px",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        {formik?.errors.nameregix}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ position: "relative" }}>
                    <FormGroup className="contact__form">
                      <Input
                        placeholder="Email"
                        onBlur={formik.handleBlur}
                        name="email"
                        value={email}
                        // onChange={(e) => setEmail(e.target.value)}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          formik.setFieldValue("email", e.target.value);
                        }}
                      />
                    </FormGroup>
                    {Boolean(formik?.errors?.email) ? (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-18px",
                          left: "1px",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        {formik?.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ position: "relative" }}>
                    <FormGroup className="contact__form">
                      <Input
                        placeholder="Subject"
                        type="text"
                        value={subject}
                        name="subject"
                        // onChange={(e) => setSubject(e.target.value)}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length <= 150) {
                            setSubject(value);
                            formik.setFieldValue("subject", value);
                          } else {
                            toast.error(
                              "Maximum character limit (150) exceeded"
                            );
                          }
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {/* {console.log(
                        formik.touched.subject,
                        formik?.errors?.subject,
                        "formik?.errors?.subject"
                      )} */}
                      <span
                        style={{ fontSize: "14px", color: "rgbs(0, 0, 0, 0.7" }}
                      >
                        Maximum character limit (150)
                      </span>
                    </FormGroup>
                    {Boolean(formik?.errors?.subject) ? (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-18px",
                          left: "1px",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        {formik?.errors.subject}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ position: "relative" }}>
                    <FormGroup className="contact__form">
                      <textarea
                        rows="5"
                        placeholder="Message"
                        className="textarea"
                        name="message"
                        value={message}
                        onBlur={formik.handleBlur}
                        // onChange={(e) => setMessage(e.target.value)}

                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length <= 500) {
                            setMessage(value);
                            formik.setFieldValue("message", value);
                          } else {
                            toast.error(
                              "Maximum character limit (500) exceeded"
                            );
                          }
                        }}
                      ></textarea>
                      <span
                        style={{ fontSize: "14px", color: "rgbs(0, 0, 0, 0.7" }}
                      >
                        Maximum character limit (500)
                      </span>
                    </FormGroup>
                    {Boolean(formik?.errors?.message) ? (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-18px",
                          left: "1px",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        {formik?.errors.message}
                      </div>
                    ) : null}
                  </div>

                  {/* <button
                    className=" contact__btn"
                    type="submit"
                    onClick={addContact}
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Send Message
                  </button> */}

                  {!isLoading ? (
                    <button className="contact__btn" type="submit">
                      Send Message
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="contact__btn"
                      // onClick={registerUser}
                      style={{
                        width: "124px",
                      }}
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  )}
                  {(!formik.isValid || !formik.dirty) && (
                    <h6>Kindly fill all the fields above</h6>
                  )}
                </Form>
              </Formik>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>
                <p className="section__description mb-0">
                  Instant Solutions Lab, Karachi, Pakistan
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+0995345875365</p>
                </div>

                <div className=" d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">Instant@gmail.com</p>
                </div>

                <h6 className="fw-bold mt-4">Follow Us</h6>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    // <NavLink to="http://carme.instantsolutionslab.site/web-app/">
                    <Link
                      // to={item.url}
                      to="https://cars.me/web-app/"
                      key={index}
                      className="social__link-icon"
                    >
                      {" "}
                      <i
                        className={item.icon}
                        // onClick={(e) => {
                        //   console.log("it clicked");
                        //   e.preventDefault();
                        //   navigate("/front"); // Call the navigate function to go to the /login page
                        // }}
                      ></i>
                    </Link>
                    // </NavLink>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
