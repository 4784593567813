import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/car-item.css";
import { useNavigate } from "react-router-dom";
import favicon from "../../assets/all-images/white-heart.png";
import favicon1 from "../../assets/all-images/red-heart.png";
import { toast } from "react-toastify";
const CarItem = ({ car, style = false }) => {
  // const { imgUrl, model, carName, automatic, speed, price } = props.item;
  let navigate = useNavigate();
  const [favouriteIcon, setFavouriteIcon] = useState(false);
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo")); //GET LOGIN INFO
  let token;
  let userId;
  if (loginInfo) {
    token = loginInfo["token"]; //GET TOKEN
    userId = loginInfo["user"]["id"];
  }

  useEffect(() => {
    checkFavoruiteCar(car?.id);
  }, []);

  const favouriteCarList = async (id) => {
    if (userId) {
      const data = new FormData();
      data.append("user_id", userId);
      data.append("subject_id", id);
      await fetch(process.env.REACT_APP_URL + "/favorite", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message == "Added To Favourites") {
            setFavouriteIcon(true);
            toast.success(data.message);
          } else {
            setFavouriteIcon(false);
            toast.success(data.message);
          }
        });
    } else {
      toast.error("Please Login");
      setTimeout(() => {
        window.location.href = "https://cars.me/web-app/";
      }, 1000);
    }
  };

  const checkFavoruiteCar = async (id) => {
    if (userId) {
      await fetch(
        process.env.REACT_APP_URL +
          `/check-favourite-car?user_id=${userId}&subject_id=${id}`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.data == "true") {
            setFavouriteIcon(true);
          } else {
            setFavouriteIcon(false);
          }
        });
    }
  };
  return (
    // <Col lg="4" md="4" sm="6" className="mb-5">
    <Col lg="4" md="4" sm="6" className="mb-4">
      <div style={{ position: "relative" }} className="car__item">
        <img
          style={{
            width: "20px",
            position: "absolute",
            right: "25px",
            top: "27px",
            cursor: "pointer",
          }}
          onClick={() => favouriteCarList(car?.id)}
          src={favouriteIcon ? favicon1 : favicon}
        />
        {/* {console.log(car?.type, "carss")}/ */}
        <div
          className="car__img"
          style={{ cursor: "pointer" }}
          onClick={() => {
            // navigate(`/car-detailss/${car?.slug}`);

            if (car?.type === "New") {
              navigate(`/new-car/${car?.slug}`);
            } else if (car?.type === "Used") {
              navigate(`/used-car/${car?.slug}`);
            }
          }}
        >
          {/* <div
          className="car__img"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/car/${car?.id}`);
          }}
        > */}
          <img
            src={car?.featured_image}
            alt=""
            className="w-100"
            width="314"
            height="167"
            style={style}
          />
        </div>
        <div className="car__item-content mt-4">
          <h4 className="section__title text-center">{car?.name}</h4>
          <h6 className="rent__price text-center mt-">AED {car?.price}.00</h6>

          {/* <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4"> */}
          <div className="car__item-info d-flex align-items-center justify-content-between mt-3 ">
            <span className=" d-flex align-items-center gap-1">
              <i className="ri-car-line"></i>Model {car?.model}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <i className="ri-settings-2-line"></i> {car?.transmission}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <i className="ri-timer-flash-line"></i> {car?.meter_counter} Km
            </span>
          </div>

          {/* <button className=" w-50 car__item-btn car__btn-rent">
            <Link to={`/cars/${carName}`}>Rent</Link>
          </button>

          <button className=" w-50 car__item-btn car__btn-details">
            <Link to={`/cars/${carName}`}>Details</Link>
          </button> */}
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
