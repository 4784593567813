import React, { useEffect, useState } from "react";
import "../../styles/find-car-form.css";
import "../../styles/find-car-form.css";
import { Form, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";

const FindCarForm = () => {
  const [countries, setCountries] = useState();
  const [countryId, setCountryId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [brands, setBrands] = useState();
  const [fromPrice, setFromPrice] = useState("");
  const [toPrice, setToPrice] = useState("");
  const [transmission, setTransmission] = useState("");

  useEffect(() => {
    fetchAllCountriesDetails();
    fetchAllBrands();
  }, []);

  const fetchAllCountriesDetails = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-all-home-countries", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCountries(data.data); //get data in data
      });
  };

  //FETCH ALL BRANDS TO GET DATA IN SELECT OPTION, SO USER CAN ADD MODELS
  const fetchAllBrands = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-brands", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setBrands(data); //get data in data
      });
  };

  const setItems = (brandId, fromPrice, countryId, transmission, toPrice) => {
    localStorage.setItem("brandId", brandId);
    localStorage.setItem("fromPrice", fromPrice);
    localStorage.setItem("countryId", countryId);
    localStorage.setItem("transmission", transmission);
    localStorage.setItem("toPrice", toPrice);
  };

  let queryParams = "/cars?";

  if (fromPrice) {
    queryParams += `fromPrice=${fromPrice}&`;
  }

  if (toPrice) {
    queryParams += `toPrice=${toPrice}&`;
  }

  if (countryId) {
    queryParams += `countryId=${countryId}&`;
  }

  if (brandId) {
    queryParams += `brandId=${brandId}&`;
  }

  if (transmission) {
    queryParams += `transmission=${transmission}&`;
  }

  if (queryParams !== "/cars?") {
    queryParams = queryParams.slice(0, -1);
  }
  return (
    <Form className="form">
      <div className=" d-flex align-items-center justify-content-between flex-wrap">
        <FormGroup className="form__group">
          <input
            type="number"
            placeholder="From"
            name="fromPrice"
            value={fromPrice}
            onChange={(e) => setFromPrice(e.target.value)}
          />
        </FormGroup>

        <FormGroup className="form__group">
          <input
            type="number"
            placeholder="To"
            name="toPrice"
            value={toPrice}
            onChange={(e) => setToPrice(e.target.value)}
          />
        </FormGroup>

        <FormGroup className="select__group">
          {/* <input type="date" placeholder="Journey date" required /> */}
          <select
            name="countryId"
            value={countryId}
            onChange={(e) => setCountryId(e.target.value)}
          >
            <option>Select Country</option>
            {countries?.map((country) => {
              if (country?.name === "United Arab Emirates") {
                return (
                  <option key={country?.id} value={country?.id}>
                    {country?.name}
                    {/* ({country?.car_count}) */}
                  </option>
                );
              }
              return null; // Skip rendering for other countries
            })}
          </select>
        </FormGroup>

        <FormGroup className="select__group">
          <select
            name="BrandId"
            value={brandId}
            onChange={(e) => setBrandId(e.target.value)}
          >
            <option>Select Brand</option>
            {brands?.map((brand) => {
              return (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              );
            })}
          </select>
        </FormGroup>
        <FormGroup className="select__group">
          <select
            name="transmission"
            value={transmission}
            onChange={(e) => setTransmission(e.target.value)}
          >
            <option value="">Select Transmission</option>
            <option value="Automatic">Automatic</option>
            <option value="Manual">Manual</option>
          </select>
        </FormGroup>

        <FormGroup className="form__group">
          <Link
            // to={`/cars?`}

            // to={`/cars?fromPrice=${fromPrice}&toPrice=${toPrice}&countryId=${countryId}&brandId=${brandId}&transmission=${transmission}`}
            to={queryParams}
          >
            <button
              className="btn find__car-btn"
              onClick={() =>
                setItems(brandId, fromPrice, countryId, transmission, toPrice)
              }
            >
              Find Car
            </button>
          </Link>
        </FormGroup>
      </div>
    </Form>
  );
};

export default FindCarForm;
