import React, { useRef } from "react";
import { Container } from "reactstrap";
import "../../styles/common-section.css";
import banner from "../../assets/all-images/197687-OYP0MC-710.jpg";
import { useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import Info from "../../assets/all-images/infoIcon.svg";

const CommonSection = ({
  title,
  slug = "car",
  bannerIDD,
  handlemidBannerRef = false,
}) => {
  // console.log(slug, "slug");
  const settings = {
    dots: true,
    // infinite: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 587,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };
  const [carTopBanner, setCarTopBanner] = useState();
  let topBannerSliderCondition2;
  const [topBannerSliderCondition, setTopBannerSliderCondition] =
    useState(null);
  const topBannerRef = useRef();
  const observer = useRef(null);
  useEffect(() => {
    fetchCarTopBanner();
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeBannerId = document.querySelector(
            ".slick-active [data-top-banner-id]"
          );
          setTopBannerSliderCondition("abc");
          // console.log("Active Banner ID:", activeBannerId?.getAttribute('data-banner-id'));
          openBannerLinkPage(
            null,
            "0",
            activeBannerId?.getAttribute("data-top-banner-id"),
            "No",
            "Yes"
          );
        } else {
          setTopBannerSliderCondition("");
        }
      });
    });

    if (topBannerRef.current) {
      observer.current.observe(topBannerRef.current);
    }

    return () => {
      observer.current.disconnect();
    };
  }, []);

  const afterTopBannerChangeHandler = (currentSlide) => {
    const activeSlide = carTopBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      topBannerSliderCondition2 = "xyz";
      if (
        topBannerSliderCondition === "abc" &&
        topBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  const fetchCarTopBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL + `/get-banner?page_slug=${slug}&position=Top`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarTopBanner(data.data); //get data in data
      });
  };
  const openBannerLinkPage = (link, target) => {
    if (link) {
      if (target == 1) {
        window.open(link, "_blank");
      } else {
        window.location.href = link;
      }
    }
  };
  return (
    <section className="common__section testing-date">
      <Container className="text-center slider-container-banner">
        <div ref={topBannerRef}>
          <Slider {...settings} afterChange={afterTopBannerChangeHandler}>
            {carTopBanner?.data?.length == 0 && (
              <img className="banner-ned-jugar" src={banner} />
            )}
            {carTopBanner?.data?.length > 0 &&
              carTopBanner?.data?.map((midBanner, index) => {
                return (
                  <React.Fragment key={index}>
                    {midBanner?.image && (
                      <>
                        <img
                          src={midBanner?.image}
                          key={index}
                          className="banner-ned-jugar"
                          onClick={() =>
                            openBannerLinkPage(
                              midBanner?.link,
                              midBanner?.target,
                              midBanner?.id,
                              "Yes",
                              "No"
                            )
                          }
                          data-top-banner-id={midBanner?.id}
                        />
                        {bannerIDD && (
                          <img
                            src={Info}
                            alt=""
                            style={{
                              position: "absolute",
                              top: 10,
                              right: 10,
                              cursor: "pointer",
                              backgroundColor: "#fff",
                              borderRadius: "16px",
                            }}
                            onClick={(e) =>
                              handlemidBannerRef(e, midBanner?.id)
                            }
                          />
                        )}
                      </>
                    )}
                    {midBanner?.script && (
                      <div
                        dangerouslySetInnerHTML={{ __html: midBanner.script }}
                        key={index}
                      />
                    )}
                  </React.Fragment>
                );
                // }
              })}
          </Slider>
        </div>
      </Container>
    </section>
  );
};

export default CommonSection;
