import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import carData from "../assets/data/carData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BookingForm from "../components/UI/BookingForm";
import PaymentMethod from "../components/UI/PaymentMethod";
import simgUrl from "../assets/all-images/cars-img/black-sport-car-highway-drive-sunset.jpg";
import "../styles/cardetial.css";
import Slider from "react-slick";
import img1 from "../assets/all-images/car-history-check-icon.svg";
import img2 from "../assets/all-images/car1.png";
import img3 from "../assets/all-images/Calendar.png";
import img4 from "../assets/all-images/Km.png";
import img5 from "../assets/all-images/Region.png";
import img7 from "../assets/all-images/hp.svg";
import img6 from "../assets/all-images/engine.png";
import banner1 from "../assets/all-images/20890892.jpg";
import banner from "../assets/all-images/3307710930933977424.png";
import banner2 from "../assets/all-images/691.jpg";
import slider1 from "../assets/all-images/slider-img/slider-3.jpg";
import { useState } from "react";
import BootstrapModals from "../components/Modal/Modal";
import Info from "../assets/all-images/infoIcon.svg";

const ScrollbarStyles = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 13px !important;
  }
  @media (max-width: 768px) {
    &::-webkit-scrollbar {
      height: 0px !important;
    }
  }
`;
const CustompagSlider = styled.div`
  .customPag ul.slick-dots.slick-thumb {
    margin-bottom: 38px;
    backgroundcolor: "red";
    overflow: auto;
    &::-webkit-scrollbar {
      height: 13px !important;
    }
    @media (max-width: 768px) {
      margin-bottom: 0px;
      &::-webkit-scrollbar {
        height: 0px !important;
      }
    }
  }
`;

const CarDetails = () => {
  const params = useParams();
  const [showPhoneNo, setShowPhoneNo] = useState(false);
  const settings1 = {
    dots: true,
    // infinite: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          // infinite: true,
          dots: true,
          draggable: true,
          touchMove: true,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
          draggable: true,
          touchMove: true,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
          draggable: true,
          touchMove: true,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 587,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          draggable: true,
          touchMove: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  // useEffect(() => {
  //   const items = window.location.href.substring(
  //     0,
  //     window.location.href.lastIndexOf("/")
  //   );
  //   console.log(items, "items");
  //   window.history.pushState("page2", "Title", items);
  // }, []);

  // const singleCarItem = carData.find((item) => item.carName === slug);

  const CustomPaging = ({ i }) => {
    if (carDetail && carDetail.images) {
      const featuredImages = carDetail?.images?.filter(
        (img) => img?.is_featured == 0
      );
      const imageIndex = i % featuredImages.length;
      const image = featuredImages[imageIndex];
      return (
        <a>
          <img style={{ width: "100px", height: "66px" }} src={image.images} />
        </a>
      );
    }

    return null;
  };

  const settings = {
    customPaging: function (i) {
      if (carDetail && carDetail.images) {
        const featuredImages = carDetail?.images?.filter(
          (img) => img?.is_featured == 0
        );
        const imageIndex = i % featuredImages.length;
        const image = featuredImages[imageIndex];
        return (
          <a>
            <img
              style={{ width: "100px", height: "66px", objectFit: "contain" }}
              src={image.images}
            />
          </a>
        );
      }
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // draggable: true,
    touchMove: true,
    swipeToSlide: true,
    autoplay: true,
    draggable: true,
    className: "customPag",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [carBottomBanner, setCarBottomBanner] = useState();
  const [carMidBanner, setCarMidBanner] = useState();
  const [carTopSidebarBanner, setCarTopSidebarBanner] = useState();

  let midBannerSliderCondition2;
  const [midBannerSliderCondition, setMidBannerSliderCondition] =
    useState(null);
  let bottomBannerSliderCondition2;
  const [bottomBannerSliderCondition, setBottomBannerSliderCondition] =
    useState(null);
  const midBannerRef = useRef();
  const bottomBannerRef = useRef();
  const observer = useRef(null);
  const observer2 = useRef(null);

  let topSiderbarBannerSliderCondition2;
  const [topSiderbarSliderCondition, setTopSiderbarBannerSliderCondition] =
    useState(null);
  const topSidebarBannerRef = useRef();
  const observer3 = useRef(null);

  const [carDetail, setCarDetail] = useState();

  useEffect(() => {
    fetchCarDetailBottomBanner();
    fetchCarDetailMidBanner();
    fetchCarDetailTopSidebarBanner();
    fetchCarDetail();
    updateCarImpression(params.slug);
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeBannerId = document.querySelector(
            ".slick-active [data-mid-banner-id]"
          );
          setMidBannerSliderCondition("abc");
          openBannerLinkPage(
            null,
            "0",
            activeBannerId?.getAttribute("data-mid-banner-id"),
            "No",
            "Yes"
          );
        } else {
          setMidBannerSliderCondition("");
        }
      });
    });
    observer2.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeBannerId = document.querySelector(
            ".slick-active [data-bottom-banner-id]"
          );
          setBottomBannerSliderCondition("abc");
          openBannerLinkPage(
            null,
            "0",
            activeBannerId?.getAttribute("data-bottom-banner-id"),
            "No",
            "Yes"
          );
        } else {
          setBottomBannerSliderCondition("");
        }
      });
    });
    observer3.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeBannerId = document.querySelector(
            ".slick-active [data-top=sidebar-banner-id]"
          );
          setTopSiderbarBannerSliderCondition("abc");
          openBannerLinkPage(
            null,
            "0",
            activeBannerId?.getAttribute("data-top-sidebar-banner-id"),
            "No",
            "Yes"
          );
        } else {
          setTopSiderbarBannerSliderCondition("");
        }
      });
    });

    if (midBannerRef.current) {
      observer.current.observe(midBannerRef.current);
    }

    if (bottomBannerRef.current) {
      observer2.current.observe(bottomBannerRef.current);
    }

    if (topSidebarBannerRef.current) {
      observer.current.observe(topSidebarBannerRef.current);
    }

    return () => {
      observer.current.disconnect();
      observer2.current.disconnect();
      observer3.current.disconnect();
    };
  }, []);

  const updateCarImpression = async (slug) => {
    await fetch(
      process.env.REACT_APP_URL + `/update-car-impression?slug=${slug}`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {});
  };

  const afterMidBannerChangeHandler = (currentSlide) => {
    const activeSlide = carMidBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      midBannerSliderCondition2 = "xyz";
      if (
        midBannerSliderCondition === "abc" &&
        midBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  const afterBottomBannerChangeHandler = (currentSlide) => {
    const activeSlide = carBottomBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomBannerSliderCondition2 = "xyz";
      if (
        bottomBannerSliderCondition === "abc" &&
        bottomBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };

  const afterTopSidebarBannerChangeHandler = (currentSlide) => {
    const activeSlide = carTopSidebarBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      topSiderbarBannerSliderCondition2 = "xyz";
      if (
        topSiderbarSliderCondition === "abc" &&
        topSiderbarBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  const fetchCarDetailBottomBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=cardetail&position=Bottom",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarBottomBanner(data.data); //get data in data
      });
  };
  const fetchCarDetailMidBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=cardetail&position=Mid",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarMidBanner(data.data); //get data in data
      });
  };

  const fetchCarDetailTopSidebarBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=cardetail&position=Sidebar",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarTopSidebarBanner(data.data); //get data in data
      });
  };

  const openBannerLinkPage = async (link, target, id, click, impression) => {
    await fetch(
      process.env.REACT_APP_URL +
        "/update-banner-clicks-and-impressions?id=" +
        id +
        "&click=" +
        click +
        "&impression=" +
        impression,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {});
    if (link) {
      if (target == 1) {
        window.open(link, "_blank");
      } else {
        window.location.href = link;
      }
    }
  };

  const fetchCarDetail = async () => {
    // await fetch(process.env.REACT_APP_URL + "/get-car-by-id?id=" + params.id, {
    //   method: "GET",
    await fetch(
      process.env.REACT_APP_URL + "/get-car-by-slug?slug=" + params.slug,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarDetail(data.data); //get data in data
        // console.log(data.data, "testing");
        if (data?.data === undefined) {
          // ("/destination-route");
          window.location.href = "/notfound";
        }
      });
  };

  const handleWhatsAppClick = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  // banner report
  // banner calls end
  const [name1, setName1] = useState();
  const [email1, setEmail1] = useState();
  const [message1, setMessage1] = useState();
  const [contant1, setContant1] = useState();
  const [bannerId, setBannerId] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const handlemidBannerRef = (e, id) => {
    setBannerId(id);
    setModalOpen(true);
  };
  const handlemidBannerRefClose = (e) => {
    // console.log(e);
    setModalOpen(false);
  };
  let token;
  const handleSubmit1 = async () => {
    // e.preventDefault();
    console.log(name1, email1, message1, contant1);

    if (
      name1 != "" &&
      name1 != undefined &&
      name1 != null &&
      email1 != "" &&
      email1 != undefined &&
      email1 != null &&
      message1 != "" &&
      message1 != undefined &&
      message1 != null &&
      contant1 != "" &&
      contant1 != undefined &&
      contant1 != null
    ) {
      // bannner data
      // IF INPUTS EMPTY OR NULL OR UNDEFINED
      const data = new FormData();
      data.append("name", name1);
      data.append("email", email1);
      data.append("message", message1);
      data.append("contact", contant1);
      data.append("banner_id", bannerId);
      fetch(process.env.REACT_APP_URL + "/create-banner-complaint", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);

          toast.success(data.message);
          setModalOpen(false);
          setName1();
          setEmail1();
          setMessage1();
          setContant1();
        });
      // .catch((e) => console.log(e));
    } else {
      toast.error("Invalid details");
    }
  };
  const sliderRef = useRef(null);
  // console.log(sliderRef.current, "sliderRef");

  const handleScroll = (e) => {
    e.preventDefault();
    // console.log(e, "handleScroll");
  };

  const handleScrollOverview = (e) => {
    // e.preventDefault();
    // console.log(e, "sliderRefsliderRef");
  };

  const handleWheel = (e) => {
    // console.log(e);
    e.preventDefault();
    // const slider = sliderRef.current;
    // if (e.deltaY < 0) {
    //   slider.slickPrev();
    // } else {
    //   slider.slickNext();
    // }
  };

  return (
    <Helmet title="Car Details">
      <BootstrapModals
        isOpen={modalOpen}
        onRequestClose={handlemidBannerRefClose}
        name={name1}
        setName={setName1}
        email={email1}
        setEmail={setEmail1}
        message={message1}
        setMessage={setMessage1}
        contact={contant1}
        setContact={setContant1}
        handleSubmit={handleSubmit1}
      />
      <section className="section-bottom">
        <Container>
          <h1 style={{ paddingTop: 0, paddingBottom: 0 }}>
            {carDetail?.model}
          </h1>
          <Row>
            <Col lg="8" className="your-slider-wrapper">
              {/* <img src={simgUrl} alt="" className="w-100" /> */}
              <CustompagSlider>
                <Slider
                  {...settings}
                  ref={sliderRef}
                  onWheel={handleWheel}
                  // onScroll={handleScroll}
                  // onDrag={handleScroll}
                >
                  {/* {carDetail?.images
                    ? carDetail?.images?.map((img, index) => {
                        if (img?.is_featured == 0) {
                          return (
                            <div key={index}>
                              <img
                                // style={{ maxWidth: "100%", maxHeight: "460px" }}
                                className="slider-car-responsive"
                                src={img?.images}
                              />
                            </div>
                          );
                        }
                      })
                    : "tesing"} */}
                  {carDetail?.images ? (
                    carDetail?.images?.map((img, index) => {
                      if (img?.is_featured == 0) {
                        return (
                          <div
                            key={index}
                            style={{ backgroundColor: "red !important" }}
                          >
                            <img
                              // style={{ maxWidth: "100%", maxHeight: "460px" }}
                              className="slider-car-responsive"
                              src={img?.images}
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <>
                      <div
                        className="slider-car-responsive col-sm-12 placeholder-glow"
                        style={{
                          position: "relative",
                          width: "730px",
                          height: "570px",
                        }}
                      >
                        <svg
                          className="bd-placeholder-img card-img-top placeholder"
                          width="100%"
                          height="570"
                          xmlns="http://www.w3.org/2000/svg"
                          role="img"
                          aria-label="Placeholder"
                          preserveAspectRatio="xMidYMid slice"
                          focusable="false"
                        >
                          <title>Placeholder</title>
                          <rect
                            width="100%"
                            height="100%"
                            fill="#2e3192"
                          ></rect>
                        </svg>
                      </div>
                    </>
                  )}
                </Slider>
              </CustompagSlider>

              <Col
                lg="4"
                style={{ position: "relative" }}
                className="mobile-view-car-history"
              >
                <p className="pricetag-detail-page">
                  AED{" "}
                  {carDetail?.car_prices?.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <div className="box-whatsapp-detailbox">
                  <span style={{ fontSize: "13px" }}>Posted by</span>
                  <p style={{ fontWeight: "600" }}>Royal Motors</p>
                  <button
                    className="deatil-button-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowPhoneNo(!showPhoneNo);
                    }}
                  >
                    {showPhoneNo ? carDetail?.contact : "Show Phone Number"}
                  </button>{" "}
                  <br />
                  <button
                    className="deatil-button-1"
                    onClick={() => {
                      handleWhatsAppClick(carDetail?.contact);
                    }}
                  >
                    WhatsApp
                  </button>
                </div>
                {/* <img className="cardetails-banner" src={banner} /> */}
                <div style={{ position: "relative", marginTop: "20px" }}>
                  <div
                    className="sliderabsolute"
                    ref={topSidebarBannerRef}
                    style={{ position: "relative" }}
                  >
                    <Slider
                      {...settings1}
                      afterChange={afterTopSidebarBannerChangeHandler}
                      style={{ position: "relative" }}
                    >
                      {carTopSidebarBanner?.data?.length == 0 ? (
                        <img className="sidebar-car-detail" src={banner} />
                      ) : (
                        <></>
                      )}
                      {carTopSidebarBanner?.data?.length > 0 &&
                        carTopSidebarBanner?.data?.map((midBanner, index) => {
                          return (
                            <>
                              {midBanner?.image && (
                                <>
                                  <img
                                    className="sidebar-car-detail"
                                    src={midBanner?.image}
                                    key={index}
                                    onClick={() =>
                                      openBannerLinkPage(
                                        midBanner?.link,
                                        midBanner?.target,
                                        midBanner?.id,
                                        "Yes",
                                        "No"
                                      )
                                    }
                                    data-top-sidebar-banner-id={midBanner?.id}
                                  />
                                  <img
                                    src={Info}
                                    alt=""
                                    style={{
                                      position: "absolute",
                                      top: 10,
                                      right: 10,
                                      cursor: "pointer",
                                      backgroundColor: "#fff",
                                      borderRadius: "16px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      handlemidBannerRef(e, midBanner?.id)
                                    }
                                  />
                                </>
                              )}
                              {midBanner?.script && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: midBanner.script,
                                  }}
                                  key={index}
                                />
                              )}
                            </>
                          );
                          // }
                        })}
                    </Slider>
                  </div>
                </div>
              </Col>
              <Col lg="12" className="mt-2">
                <div className="booking-info mt-2">
                  <h5 className="mb-3 fw-bold ">Item overview</h5>
                  <ScrollbarStyles>
                    {/* <div
                      style={{
                        display: "flex",
                        overflow: "auto",
                        whiteSpace: "nowrap",
                        "&::-webkit-scrollbar": { height: "auto !important" },
                      }}
                      // onScroll={handleScrollOverview}
                      // className="table-wrapper"
                    > */}
                    <div className="item-oveiewview-box">
                      <p className="item-overview-p">Transmission</p>
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={img1}
                      />
                      <p className="item-request-p2">
                        {carDetail?.transmission}
                      </p>
                    </div>
                    <div className="item-oveiewview-box">
                      <p className="item-overview-p">Type</p>
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={img2}
                      />
                      <p className="item-request-p2">{carDetail?.type}</p>
                    </div>
                    <div className="item-oveiewview-box">
                      <p className="item-overview-p">YEAR</p>
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={img3}
                      />
                      <p className="item-request-p2">{carDetail?.year}</p>
                    </div>
                    <div className="item-oveiewview-box">
                      <p className="item-overview-p">KILOMETERS</p>
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={img4}
                      />
                      <p className="item-request-p2">
                        {carDetail?.meter_counter}
                      </p>
                    </div>
                    <div className="item-oveiewview-box">
                      <p className="item-overview-p">HP</p>
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={img7}
                      />
                      <p className="item-request-p2">
                        {carDetail?.power_type} CC
                      </p>
                    </div>
                    <div className="item-oveiewview-box">
                      <p className="item-overview-p">Engine</p>
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={img6}
                      />
                      <p className="item-request-p2">
                        {carDetail?.engine_type}
                      </p>
                    </div>
                    {/* </div> */}
                  </ScrollbarStyles>
                </div>
              </Col>
              <Col lg="12" className="mt-2">
                <div ref={midBannerRef} style={{ position: "relative" }}>
                  <Slider
                    {...settings1}
                    afterChange={afterMidBannerChangeHandler}
                  >
                    {carMidBanner?.data?.length == 0 && (
                      <img style={{ width: "100%" }} src={banner1} />
                    )}
                    {carMidBanner?.data?.length > 0 &&
                      carMidBanner?.data?.map((midBanner, index) => {
                        return (
                          <>
                            {midBanner?.image && (
                              <>
                                <img
                                  style={{ width: "100%" }}
                                  src={midBanner?.image}
                                  key={index}
                                  onClick={() =>
                                    openBannerLinkPage(
                                      midBanner?.link,
                                      midBanner?.target,
                                      midBanner?.id,
                                      "Yes",
                                      "No"
                                    )
                                  }
                                  data-mid-banner-id={midBanner?.id}
                                />
                                <img
                                  src={Info}
                                  alt=""
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10,
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    borderRadius: "16px",
                                  }}
                                  onClick={(e) =>
                                    handlemidBannerRef(e, midBanner?.id)
                                  }
                                />
                              </>
                            )}
                            {midBanner?.script && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: midBanner.script,
                                }}
                                key={index}
                              />
                            )}
                          </>
                        );
                        // }
                      })}
                  </Slider>
                </div>
              </Col>
              <Col
                style={{ borderBottom: "solid 1px rgb(221, 221, 221)" }}
                lg="12"
                className="mt-5"
              >
                <div className="booking-info mt-5">
                  <h5 className="mb-4 fw-bold">Additional Details</h5>
                  <BookingForm data={carDetail} />
                </div>
              </Col>
              <Col lg="12" className="mt-4">
                <div ref={bottomBannerRef} style={{ position: "relative" }}>
                  <Slider
                    {...settings1}
                    afterChange={afterBottomBannerChangeHandler}
                  >
                    {carBottomBanner?.data?.length == 0 && (
                      <img style={{ width: "100%" }} src={banner2} />
                    )}
                    {carBottomBanner?.data?.length > 0 &&
                      carBottomBanner?.data?.map((midBanner, index) => {
                        return (
                          <>
                            {midBanner?.image && (
                              <>
                                <img
                                  style={{ width: "100%" }}
                                  src={midBanner?.image}
                                  key={index}
                                  onClick={() =>
                                    openBannerLinkPage(
                                      midBanner?.link,
                                      midBanner?.target,
                                      midBanner?.id,
                                      "Yes",
                                      "No"
                                    )
                                  }
                                  data-bottom-banner-id={midBanner?.id}
                                />
                                <img
                                  src={Info}
                                  alt=""
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10,
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    borderRadius: "16px",
                                  }}
                                  onClick={(e) =>
                                    handlemidBannerRef(e, midBanner?.id)
                                  }
                                />
                              </>
                            )}
                            {midBanner?.script && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: midBanner.script,
                                }}
                                key={index}
                              />
                            )}
                          </>
                        );
                        // }
                      })}
                  </Slider>
                </div>
              </Col>
              <Col
                style={{ borderBottom: "solid 1px rgb(221, 221, 221)" }}
                lg="12"
                className="mt-lg-4 mt-md-4 mt-sm-1"
              >
                <div className="booking-info mt-lg-4 mt-md-4 mt-sm-1">
                  <h5 className="mb-lg-3 mb-md-3 mb-sm-2 fw-bold">
                    Description
                  </h5>
                  {/* <p>{carDetail?.details?.description}</p> */}
                  <p>
                    {" "}
                    {carDetail?.details?.description.slice(0, 1).toUpperCase() +
                      carDetail?.details?.description.slice(1)}
                  </p>
                </div>
              </Col>
              <Col lg="12" className="mt-lg-4 mt-md-4 mt-sm-1">
                <div className="booking-info mt-lg-4 mt-md-4 mt-sm-1">
                  <h5 className="mb-lg-3 mb-md-3 mb-sm-2 mt-lg-3 mt-md-3 mt-sm-2 fw-bold history-heading-for-mb">
                    Car History
                  </h5>
                  {/* <p style={{ marginBottom: "0", fontWeight: "600" }}>Accident History Check</p> */}
                  <p style={{ fontSize: "13px" }}>
                    {carDetail?.details?.car_history.slice(0, 1).toUpperCase() +
                      carDetail?.details?.car_history.slice(1)}
                  </p>
                </div>
              </Col>
              {/* <Col lg="4" className="mt-5"> */}
              {/* <div className="payment__info mt-5">
                <h5 className="mb-4 fw-bold ">Payment Information</h5>
                <PaymentMethod />
              </div> */}
              {/* </Col> */}
            </Col>

            <Col
              lg="4"
              style={{ position: "relative" }}
              className="desktop-view-car-history"
            >
              <p className="pricetag-detail-page">
                AED{" "}
                {carDetail?.car_prices?.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <div className="box-whatsapp-detailbox">
                <span style={{ fontSize: "13px" }}>Posted by</span>
                <p style={{ fontWeight: "600" }}>Royal Motors</p>
                <button
                  className="deatil-button-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPhoneNo(!showPhoneNo);
                  }}
                >
                  {showPhoneNo ? carDetail?.contact : "Show Phone Number"}
                </button>{" "}
                <br />
                <button
                  className="deatil-button-1"
                  onClick={() => {
                    handleWhatsAppClick(carDetail?.contact);
                  }}
                >
                  WhatsApp
                </button>
              </div>
              {/* <img className="cardetails-banner" src={banner} /> */}
              <div style={{ position: "relative", marginTop: "20px" }}>
                <div
                  className="sliderabsolute"
                  ref={topSidebarBannerRef}
                  style={{ position: "relative" }}
                >
                  <Slider
                    {...settings1}
                    afterChange={afterTopSidebarBannerChangeHandler}
                    style={{ position: "relative" }}
                  >
                    {carTopSidebarBanner?.data?.length == 0 && (
                      <img className="sidebar-car-detail" src={banner} />
                    )}

                    {carTopSidebarBanner?.data == undefined && (
                      // <img className="sidebanner-slider-img" src={banner3} />
                      <>
                        <div
                          // className="slider-car-responsive col-sm-12 placeholder-glow sidebanner-slider-img"
                          className="col-sm-12 placeholder-glow sidebanner-slider-img"
                          style={{
                            position: "relative",
                            // width: "730px",
                            // height: "570px",
                          }}
                        >
                          <svg
                            className="bd-placeholder-img card-img-top placeholder"
                            width="100%"
                            height="1370"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-label="Placeholder"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                          >
                            <title>Placeholder</title>
                            <rect
                              width="100%"
                              height="100%"
                              fill="#2e3192"
                            ></rect>
                          </svg>
                        </div>
                      </>
                    )}

                    {carTopSidebarBanner?.data?.length > 0 &&
                      carTopSidebarBanner?.data?.map((midBanner, index) => {
                        return (
                          <>
                            {midBanner?.image && (
                              <>
                                <img
                                  className="sidebar-car-detail"
                                  src={midBanner?.image}
                                  key={index}
                                  onClick={() =>
                                    openBannerLinkPage(
                                      midBanner?.link,
                                      midBanner?.target,
                                      midBanner?.id,
                                      "Yes",
                                      "No"
                                    )
                                  }
                                  data-top-sidebar-banner-id={midBanner?.id}
                                />
                                <img
                                  src={Info}
                                  alt=""
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10,
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    borderRadius: "16px",
                                  }}
                                  onClick={(e) =>
                                    handlemidBannerRef(e, midBanner?.id)
                                  }
                                />
                              </>
                            )}
                            {midBanner?.script && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: midBanner.script,
                                }}
                                key={index}
                              />
                            )}
                          </>
                        );
                        // }
                      })}
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
