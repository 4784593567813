import React, { useState, useLayoutEffect, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
// import { ClickAwayListener } from "@mui/base";
import { ClickAwayListener } from "@mui/material";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";
import carData from "../assets/data/carData";
import Cardsitemlista from "../components/UI/Cardsitemlista";
import carimg1 from "../assets/all-images/cars-img/white-offroader-jeep-parking (1).jpg";
import carimg2 from "../assets/all-images/cars-img/chrome-black-sport-car-parking-with-front-lights.jpg";
import carimg3 from "../assets/all-images/cars-img/black-sport-car-highway-drive-sunset.jpg";
import carimg4 from "../assets/all-images/cars-img/yellow-sport-car-with-black-autotuning-road.jpg";
import carimg5 from "../assets/all-images/cars-img/red-luxury-sedan-road.jpg";
import carimg6 from "../assets/all-images/cars-img/tesla.jpg";
import carimg7 from "../assets/all-images/cars-img/yellow-sport-car-with-black-autotuning-road.jpg";
import carimg8 from "../assets/all-images/cars-img/black-sport-car-highway-drive-sunset.jpg";
import carimg9 from "../assets/all-images/cars-img/white-offroader-jeep-parking (1).jpg";
import Slider from "react-slick";
import { toast } from "react-toastify";
import banner1 from "../assets/all-images/690.jpg";
import banner2 from "../assets/all-images/691.jpg";
import Pagination from "react-js-pagination";
import BootstrapModals from "../components/Modal/Modal";
import Info from "../assets/all-images/infoIcon.svg";

import banner3 from "../assets/all-images/197687-OYP0MC-7101.jpg";

import banner4 from "../assets/all-images/202014-OZCYW7-111.jpg";
import "../styles/carlisting.css";

import check from "../assets/all-images/tick.svg";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const CarListing = () => {
  const settings = {
    dots: false,
    // infinite: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 587,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [citiesDataa, setCitiesDataa] = useState();

  const [selectimg1, setSelectimg1] = useState("");

  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);
  const [clickDropdown, setClickDropdown] = useState(false);

  const [containerWidth, setContainerWidth] = useState(0);
  const dropdown1fun = () => {
    if (dropdown1) {
      setDropdown1(false);
      setCities(citiesDataa);
      // console.log(false, "dropdowen close");
    } else {
      setDropdown1(true);
      setDropdown2(false);
      setDropdown3(false);
      setDropdown4(false);
      setDropdown5(false);
    }
  };
  const dropdown2fun = () => {
    if (dropdown2) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
      setDropdown1(false);
      setDropdown3(false);
      setDropdown4(false);
      setDropdown5(false);
    }
  };

  const dropdown3fun = () => {
    if (dropdown3) {
      setDropdown3(false);
    } else {
      setDropdown3(true);
      setDropdown2(false);
      setDropdown1(false);
      setDropdown4(false);
      setDropdown5(false);
    }
  };
  const dropdown4fun = () => {
    if (dropdown4) {
      setDropdown4(false);
    } else {
      setDropdown4(true);
      setDropdown2(false);
      setDropdown3(false);
      setDropdown1(false);
      setDropdown5(false);
    }
  };

  const dropdown5fun = () => {
    if (dropdown5) {
      setDropdown5(false);
    } else {
      setDropdown5(true);
      setDropdown2(false);
      setDropdown3(false);
      setDropdown4(false);
      setDropdown1(false);
    }
  };

  const dropdownclose = () => {
    setDropdown1(false);
    setDropdown2(false);
    setDropdown3(false);
    setDropdown4(false);
    setDropdown5(false);
  };

  const [carBottomBanner, setCarBottomBanner] = useState();
  const [carMidBanner, setCarMidBanner] = useState();
  const [carTopSidebarBanner, setCarTopSidebarBanner] = useState();
  const [carBottomSidebarBanner, setCarBottomSidebarBanner] = useState();
  const [cities, setCities] = useState();
  const [cityName, setCityName] = useState("All Cities");
  const [make, setMake] = useState("Select");
  const [priceRange, setPriceRange] = useState("Select");
  const [filterYear, setFilterYear] = useState("Select");
  const [filterKilometer, setFilterKilometer] = useState("Select");
  const [cityID, setCityID] = useState("");
  const [brand, setbrand] = useState("");
  const [brands, setBrands] = useState();
  const [fromCarPrice, setFromCarPrice] = useState("");
  const [fromCarYear, setFromCarYear] = useState("");
  const [toCarPrice, setToCarPrice] = useState("");
  const [toCarYear, setToCarYear] = useState("");
  const [fromMeterCounter, setFromMeterCounter] = useState("");
  const [toMeterCounter, setToMeterCounter] = useState("");
  let midBannerSliderCondition2;
  const testingUrl = window?.location?.href;
  const [midBannerSliderCondition, setMidBannerSliderCondition] =
    useState(null);
  let bottomBannerSliderCondition2;
  const [bottomBannerSliderCondition, setBottomBannerSliderCondition] =
    useState(null);
  const midBannerRef = useRef();
  const bottomBannerRef = useRef();
  const observer = useRef(null);
  const observer2 = useRef(null);

  let topSiderbarBannerSliderCondition2;
  const [topSiderbarSliderCondition, setTopSiderbarBannerSliderCondition] =
    useState(null);
  let bottomSiderbarBannerSliderCondition2;
  const [
    bottomSiderbarBannerSliderCondition,
    setBottomSiderbarBannerSliderCondition,
  ] = useState(null);
  const topSidebarBannerRef = useRef();
  const bottomSiderbarBannerRef = useRef();
  const observer3 = useRef(null);
  const observer4 = useRef(null);

  const [carItems, setCarItems] = useState();

  const location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  // Extract the filter values from the query parameters
  let from_price = queryParams.get("fromPrice");
  let to_price = queryParams.get("toPrice");
  let country_id = queryParams.get("countryId");
  let brand_id = queryParams.get("brand");
  let transmission = queryParams.get("transmission");
  let from_year = queryParams.get("fromYear");
  let to_year = queryParams.get("toYear");
  let from_meter_counter = queryParams.get("fromMeterCounter");
  let to_meter_counter = queryParams.get("toMeterCounter");
  let model_id = queryParams.get("modelId");
  let city_id = queryParams.get("cityId");
  let pageNumber = 1;
  let filters = {
    from_price,
    to_price,
    country_id,
    brand_id,
    model_id,
    city_id,
    transmission,
    from_year,
    to_year,
    from_meter_counter,
    to_meter_counter,
  };

  useEffect(() => {
    // Fetch data based on the filter values
    setTimeout(() => {
      if (queryParams?.get("city") !== null) {
        setCityName(localStorage.getItem("cityName") ?? "All Cities");
      }

      fetchCarBottomBanner();
      fetchCarMidBanner();
      fetchCarTopSidebarBanner();
      fetchCarBottomSidebarBanner();
      fetchAllCitiesDetails();
      fetchAllBrands();
      fetchCarItems(pageNumber, filters);
      observer.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-mid-banner-id]"
            );
            setMidBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-mid-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setMidBannerSliderCondition("");
          }
        });
      });
      observer2.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-bottom-banner-id]"
            );
            setBottomBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-bottom-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setBottomBannerSliderCondition("");
          }
        });
      });
      observer3.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-top=sidebar-banner-id]"
            );
            setTopSiderbarBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-top-sidebar-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setTopSiderbarBannerSliderCondition("");
          }
        });
      });
      observer4.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-bottom-sidebar-banner-id]"
            );
            setBottomSiderbarBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-bottom-sidebar-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setBottomSiderbarBannerSliderCondition("");
          }
        });
      });

      if (midBannerRef.current) {
        observer.current.observe(midBannerRef.current);
      }

      if (bottomBannerRef.current) {
        observer2.current.observe(bottomBannerRef.current);
      }

      if (topSidebarBannerRef.current) {
        observer.current.observe(topSidebarBannerRef.current);
      }

      if (bottomSiderbarBannerRef.current) {
        observer2.current.observe(bottomSiderbarBannerRef.current);
      }

      return () => {
        observer.current.disconnect();
        observer2.current.disconnect();
        observer3.current.disconnect();
        observer4.current.disconnect();
      };
    }, 10000);
  }, []);

  const afterMidBannerChangeHandler = (currentSlide) => {
    const activeSlide = carMidBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      midBannerSliderCondition2 = "xyz";
      if (
        midBannerSliderCondition === "abc" &&
        midBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  const afterBottomBannerChangeHandler = (currentSlide) => {
    const activeSlide = carBottomBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomBannerSliderCondition2 = "xyz";
      if (
        bottomBannerSliderCondition === "abc" &&
        bottomBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };

  const afterTopSidebarBannerChangeHandler = (currentSlide) => {
    const activeSlide = carTopSidebarBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      topSiderbarBannerSliderCondition2 = "xyz";
      if (
        topSiderbarSliderCondition === "abc" &&
        topSiderbarBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  const afterBottomSidebarBannerChangeHandler = (currentSlide) => {
    const activeSlide = carBottomSidebarBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomSiderbarBannerSliderCondition2 = "xyz";
      if (
        bottomSiderbarBannerSliderCondition === "abc" &&
        bottomSiderbarBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };

  const getFilters = (pageNumber, filters) => {
    filters.brand_id = localStorage.getItem("brand");
    filters.from_price = localStorage.getItem("fromPrice");
    filters.country_id = localStorage.getItem("countryId");
    filters.transmission = localStorage.getItem("transmission");
    filters.to_price = localStorage.getItem("toPrice");
    filters.from_year = localStorage.getItem("fromYear");
    filters.to_year = localStorage.getItem("toYear");
    filters.from_meter_counter = localStorage.getItem("fromMeterCounter");
    filters.to_meter_counter = localStorage.getItem("toMeterCounter");
    filters.model_id = localStorage.getItem("modelId");
    filters.city_id = localStorage.getItem("cityId");
    // console.log(localStorage.getItem("cityName"));
    fetchCarItems(pageNumber, filters);
  };

  const fetchAllCitiesDetails = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-uae-cities", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCities(data.data); //get data in data
        setCitiesDataa(data.data);
      });
  };

  const fetchAllBrands = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-brands", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setBrands(data); //get data in data
      });
  };

  const fetchCarBottomBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-banner?page_slug=car&position=Bottom",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarBottomBanner(data.data); //get data in data
      });
  };
  const fetchCarMidBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-banner?page_slug=car&position=Mid",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarMidBanner(data.data); //get data in data
      });
  };

  // const fetchCarTopSidebarBanner = async () => {
  //   await fetch(
  //     process.env.REACT_APP_URL +
  //       "/get-banner?page_slug=car&position=Top Sidebar",
  //     {
  //       method: "GET",
  //       headers: new Headers({
  //         Accept: "application/json",
  //       }),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setCarTopSidebarBanner(data.data); //get data in data
  //     });
  // };
  const fetchCarTopSidebarBanner = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_URL +
          "/get-banner?page_slug=car&position=Top Sidebar",
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      );
      const data = await response.json();
      setCarTopSidebarBanner(data.data);
    } catch (error) {
      // Handle any errors
      // console.log(error);
    }
  };
  const fetchCarBottomSidebarBanner = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_URL +
          "/get-banner?page_slug=car&position=Bottom Sidebar",
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      );
      const data = await response.json();
      setCarBottomSidebarBanner(data.data);
    } catch (error) {
      // Handle any errors
      // console.log(error);
    }
  };
  // const fetchCarBottomSidebarBanner = async () => {
  //   await fetch(
  //     process.env.REACT_APP_URL +
  //       "/get-banner?page_slug=car&position=Bottom Sidebar",
  //     {
  //       method: "GET",
  //       headers: new Headers({
  //         Accept: "application/json",
  //       }),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setCarBottomSidebarBanner(data.data); //get data in data
  //     });
  // };
  const openBannerLinkPage = async (link, target, id, click, impression) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/update-banner-clicks-and-impressions?id=${id}&click=${click}&impression=${impression}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update banner clicks and impressions.");
      }

      if (link) {
        if (target === 1) {
          window.open(link, "_blank");
        } else {
          window.location.href = link;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const openBannerLinkPage = async (link, target, id, click, impression) => {
  //   await fetch(
  //     process.env.REACT_APP_URL +
  //       "/update-banner-clicks-and-impressions?id=" +
  //       id +
  //       "&click=" +
  //       click +
  //       "&impression=" +
  //       impression,
  //     {
  //       method: "POST",
  //       headers: new Headers({
  //         Accept: "application/json",
  //       }),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {});
  //   if (link) {
  //     if (target == 1) {
  //       window.open(link, "_blank");
  //     } else {
  //       window.location.href = link;
  //     }
  //   }
  // };
  const fetchCarItems = async (pageNumber, filters = {}, types = "") => {
    let type = types ? `?type=${types}` : "";
    const url = new URL(process.env.REACT_APP_URL + `/get-cars${type}`);
    url.searchParams.append("page", pageNumber);
    // Object.keys(filters).forEach((key) => {
    //   if (filters[key]) {
    //     url.searchParams.append(key, filters[key]);
    //   }
    // });
    console.log("query", queryParams);
    console.log("querybrand", queryParams.get("brand"));

    let isBrandName = queryParams.get("brand");
    if (
      window?.location?.href.includes("brand") &&
      localStorage?.getItem("brand") !== null
    ) {
      // let isBrandName = queryParams.get("brand");
      // setMakeFilter(isBrandName);

      // setMake("testing");
      const url2 = window?.location?.href;
      const urlObj = new URL(url2);
      const queryParams = new URLSearchParams(urlObj.search);

      const brandValue = queryParams.get("brand");
      // setMake(isBrandName);
      setMake(brandValue);

      url.searchParams.append("brand_id", localStorage?.getItem("brand"));
      // url.searchParams.append("brand_id", "testing");
    }
    let isCityName = queryParams.get("city");
    console.log(isCityName !== null, isCityName, "isCityName");
    console.log(localStorage?.getItem("cityId"), "isCityName");
    // if (isCityName !== null && localStorage?.getItem("cityId") !== null) {
    if (
      window?.location?.href.includes("city") &&
      localStorage?.getItem("cityId") !== null
    ) {
      url.searchParams.append("city_id", localStorage?.getItem("cityId"));
      setCityName(localStorage?.getItem("cityName"));
    }
    let fromPrice = queryParams.get("fromPrice");
    console.log(fromPrice, "fromPrice");
    if (
      window?.location?.href.includes("fromPrice") &&
      filters?.from_price !== undefined
    ) {
      const url2 = window?.location?.href;
      const urlObj = new URL(url2);
      const queryParams = new URLSearchParams(urlObj.search);

      const frompriceValue = queryParams.get("fromPrice");
      const frompriceValueto = queryParams.get("toPrice");

      url.searchParams.append("from_price", frompriceValue);
      // url.searchParams.append("from_price", fromPrice);
      // setFromCarPrice(fromPrice);
      // setPriceRange(`${filters.from_price} to ${filters.to_price}`);
      setPriceRange(`${frompriceValue} to ${frompriceValueto}`);
    }
    let toPrice = queryParams.get("toPrice");
    console.log(filters?.to_price, "filters?.to_price");
    if (
      window?.location?.href.includes("toPrice") &&
      filters?.to_price !== undefined
    ) {
      const url2 = window?.location?.href;
      const urlObj = new URL(url2);
      const queryParams = new URLSearchParams(urlObj.search);

      const topriceValue = queryParams.get("toPrice");
      const frompriceValue = queryParams.get("fromPrice");

      url.searchParams.append("to_price", topriceValue);
      // setToCarPrice(toPrice);
      // setPriceRange(`${filters.from_price} to ${filters.to_price}`);
      setPriceRange(`${frompriceValue} to ${topriceValue}`);
    }

    let fromYeaar = queryParams.get("fromYear");
    console.log(filters?.from_year, "testingtesting");
    if (
      window?.location?.href.includes("fromYear") &&
      (filters?.from_year !== undefined || null)
    ) {
      const url2 = window?.location?.href;
      const urlObj = new URL(url2);
      const queryParams = new URLSearchParams(urlObj.search);

      const fromyearValue = queryParams.get("fromYear");
      const fromyearValue2 = queryParams.get("toYear");

      url.searchParams.append("from_year", fromyearValue);
      // setFromCarYear(fromYeaar);
      // setFilterYear(`${filters.from_year} to ${filters.to_year}`);
      setFilterYear(`${fromyearValue} to ${fromyearValue2}`);
    }
    let toYear = queryParams.get("toYear");
    console.log(fromYeaar, "fromYeaar");
    console.log(toYear, "fromYeaartoYear");
    if (
      window?.location?.href.includes("toYear") &&
      (filters?.to_year !== undefined || null)
    ) {
      const url2 = window?.location?.href;
      const urlObj = new URL(url2);
      const queryParams = new URLSearchParams(urlObj.search);

      const toyearValue = queryParams.get("fromYear");
      const toyearValue2 = queryParams.get("toYear");

      url.searchParams.append("to_year", toyearValue);
      // setToCarYear(toYear);
      // setFilterYear(`${filters.from_year} to ${filters.to_year}`);
      setFilterYear(`${toyearValue} to ${toyearValue2}`);
    }

    let fromMeterCounter = queryParams.get("fromMeterCounter");
    console.log(
      fromMeterCounter,
      filters?.fromMeterCounter,
      "fromMeterCounter"
    );
    if (
      window?.location?.href.includes("fromMeterCounter") &&
      filters?.from_meter_counter !== undefined
    ) {
      filters.from_meter_counter = fromMeterCounter;

      const url2 = window?.location?.href;
      const urlObj = new URL(url2);
      const queryParams = new URLSearchParams(urlObj.search);

      const fromMeterCounterValue = queryParams.get("fromMeterCounter");
      const toMeterCounterValue2 = queryParams.get("toMeterCounter");
      // setFromMeterCounter(fromMeterCounter);
      // url.searchParams.append("from_meter_counter", fromMeterCounter);
      url.searchParams.append("from_meter_counter", fromMeterCounterValue);
      // setFilterKilometer(
      //   `${filters.from_meter_counter} to ${filters.to_meter_counter}`
      // );
      setFilterKilometer(`${fromMeterCounterValue} to ${toMeterCounterValue2}`);
    }
    let toMeterCounter = queryParams.get("toMeterCounter");
    if (
      window?.location?.href.includes("toMeterCounter") &&
      filters?.to_meter_counter !== undefined
    ) {
      filters.to_meter_counter = toMeterCounter;

      const url2 = window?.location?.href;
      const urlObj = new URL(url2);
      const queryParams = new URLSearchParams(urlObj.search);

      const fromMeterCounterValue = queryParams.get("fromMeterCounter");
      const toMeterCounterValue = queryParams.get("toMeterCounter");

      // url.searchParams.append("to_meter_counter", toMeterCounter);
      url.searchParams.append("to_meter_counter", toMeterCounterValue);
      // setToMeterCounter(toMeterCounter);
      // setFilterKilometer(
      //   `${filters.from_meter_counter} to ${filters.to_meter_counter}`
      // );
      setFilterKilometer(`${fromMeterCounterValue} to ${toMeterCounterValue}`);
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch car items.");
      }

      const data = await response.json();
      setCarItems(data.data); // Get data from the response
    } catch (error) {
      console.error(error);
    }
  };

  // const fetchCarItems = async (pageNumber, filters = {}, types) => {
  //   let type = types ? `?type=${types}` : "";
  //   const url = new URL(process.env.REACT_APP_URL + `/get-cars${type}`);
  //   // ?type=New Used
  //   url.searchParams.append("page", pageNumber);

  //   // Add filter parameters to the request
  //   Object.keys(filters).forEach((key) => {
  //     if (filters[key]) {
  //       url.searchParams.append(key, filters[key]);
  //     }
  //   });

  //   await fetch(url, {
  //     method: "GET",
  //     headers: new Headers({
  //       Accept: "application/json",
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setCarItems(data.data); //get data in data
  //     });
  // };

  const applyBrandFilter = (id, brandNamee4) => {
    setDropdown2(false);
    setSelectimg1(`${id}`);
    filters.brand_id = String(id);
    brand_id = String(id);
    localStorage.setItem("brand", String(id));
    // queryParams.append("brand", String(id));
    // queryParams.append("brand", brandNamee4);
    // queryParams.append("brand", brandNamee4);
    // queryParams.set("brand", brandNamee4);
    // queryParams.searchParams.append("brand", brandNamee4);
    // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

    // const queryParams = new URLSearchParams(window.location.search);
    // const brandParams = queryParams.getAll("brand");

    // if (brandParams.length > 0) {
    //   // Remove all existing brand parameters
    //   brandParams.forEach((param) => {
    //     queryParams.delete("brand");
    //   });
    // }

    // queryParams.append("brand", brandNamee4); // Add brand parameter with new value

    // let newUrl;
    // if (testingUrl.includes("?")) {
    //   newUrl = `${window.location.href}&${queryParams.toString()}`;
    // } else {
    //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    // }

    // const queryParams = new URLSearchParams(window.location.search);
    // const brandParams = queryParams.getAll("brand");

    // if (brandParams.length > 0) {
    //   // Remove all existing brand parameters
    //   brandParams.forEach((param) => {
    //     queryParams.delete("brand");
    //   });
    // }

    queryParams.append("brand", brandNamee4); // Add brand parameter with new value

    // const currentUrl = window.location.href;
    // const separator = currentUrl.includes("?") ? "&" : "?"; // Use & if there are existing query parameters, otherwise use ?
    // const newUrl = `${currentUrl}${separator}${queryParams.toString()}`;

    // window.history.pushState({ path: newUrl }, "", newUrl);

    let newUrl;
    const baseUrl = window.location.href.split("?")[0];

    if (testingUrl.includes("?")) {
      let existingParams = window.location.search.substr(1); // Get the existing query parameters
      const paramsArray = existingParams.split("&");
      const updatedParams = paramsArray.filter(
        (param) => !param.startsWith("brand=")
      ); // Remove existing "brand" parameter

      if (baseUrl.includes("?")) {
        newUrl = `${baseUrl}?${updatedParams.join(
          "&"
        )}&${queryParams.toString()}`;
      } else {
        newUrl = `${baseUrl}?${updatedParams.join("&")}`;
        if (updatedParams.length > 0) {
          newUrl += "&";
        }
        newUrl += queryParams.toString();
      }
    } else {
      newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    }

    window.history.pushState({ path: newUrl }, "", newUrl);

    fetchCarItems(1, filters);
  };

  const handlePriceKeyDown = () => {
    if (Number(toCarPrice) < Number(fromCarPrice)) {
      // Swap the values\
      // console.log("check runs");
      const temp = fromCarPrice;
      setFromCarPrice(toCarPrice);
      setToCarPrice(temp);

      // setMinPrice(toCarPrice);
      // setMaxPrice(temp);
    }
  };

  const applyPriceFilter = () => {
    // reset price filter
    setDropdown3(false);
    localStorage.removeItem("fromPrice");
    localStorage.removeItem("toPrice");
    localStorage.removeItem("to_price");
    localStorage.removeItem("from_price");
    setPriceRange("Select");
    setToCarPrice("");
    setFromCarPrice("");
    // const newUrl = `${window.location.pathname}`;
    // window.history.pushState({ path: newUrl }, "", newUrl);
    // fetchCarItems(1);
    // end reset price filter

    // console.log(toCarPrice, fromCarPrice, "pricong");
    if (Number(toCarPrice) < Number(fromCarPrice)) {
      setDropdown3(false);
      filters.to_price = String(toCarPrice);
      to_price = String(toCarPrice);
      localStorage.setItem("toPrice", String(toCarPrice));
      queryParams.append("toPrice", String(toCarPrice));
      filters.from_price = String(fromCarPrice);
      setPriceRange(`${filters.to_price} to ${filters.from_price}`);
      // console.log(priceRange, "filters.to_price");
      from_price = String(fromCarPrice);
      localStorage.setItem("fromPrice", String(fromCarPrice));
      queryParams.append("fromPrice", String(fromCarPrice));

      // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      setFromCarPrice(toCarPrice);
      setToCarPrice(fromCarPrice);
      console.log(testingUrl, "testingUrl");
      // let newUrl;
      // if (testingUrl.includes("?")) {
      //   newUrl = `${window.location.href}&${queryParams.toString()}`;
      // } else {
      //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // }

      let newUrl;
      const baseUrl = window.location.href.split("?")[0];

      if (testingUrl.includes("?")) {
        let existingParams = window.location.search.substr(1); // Get the existing query parameters
        const paramsArray = existingParams.split("&");
        const updatedParams = paramsArray.filter(
          (param) =>
            !param.startsWith("fromPrice=") && !param.startsWith("toPrice=")
        ); // Remove existing "brand" parameter

        if (baseUrl.includes("?")) {
          newUrl = `${baseUrl}?${updatedParams.join(
            "&"
          )}&${queryParams.toString()}`;
        } else {
          newUrl = `${baseUrl}?${updatedParams.join("&")}`;
          if (updatedParams.length > 0) {
            newUrl += "&";
          }
          newUrl += queryParams.toString();
        }
      } else {
        newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      }

      window.history.pushState({ path: newUrl }, "", newUrl);
      fetchCarItems(1, filters);
    } else {
      console.log(testingUrl, "testingUrl");
      setDropdown3(false);
      filters.from_price = String(fromCarPrice);
      from_price = String(fromCarPrice);
      localStorage.setItem("fromPrice", String(fromCarPrice));
      queryParams.append("fromPrice", String(fromCarPrice));
      filters.to_price = String(toCarPrice);
      setPriceRange(`${filters.from_price} to ${filters.to_price}`);
      // console.log(priceRange, "filters.to_price");
      to_price = String(toCarPrice);
      localStorage.setItem("toPrice", String(toCarPrice));
      queryParams.append("toPrice", String(toCarPrice));

      // let newUrl;
      // if (testingUrl.includes("?")) {
      //   newUrl = `${window.location.href}&${queryParams.toString()}`;
      // } else {
      //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // }
      let newUrl;
      const baseUrl = window.location.href.split("?")[0];

      if (testingUrl.includes("?")) {
        let existingParams = window.location.search.substr(1); // Get the existing query parameters
        const paramsArray = existingParams.split("&");
        const updatedParams = paramsArray.filter(
          (param) =>
            !param.startsWith("fromPrice=") && !param.startsWith("toPrice=")
        ); // Remove existing "brand" parameter

        if (baseUrl.includes("?")) {
          newUrl = `${baseUrl}?${updatedParams.join(
            "&"
          )}&${queryParams.toString()}`;
        } else {
          newUrl = `${baseUrl}?${updatedParams.join("&")}`;
          if (updatedParams.length > 0) {
            newUrl += "&";
          }
          newUrl += queryParams.toString();
        }
      } else {
        newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      }

      // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
      fetchCarItems(1, filters);
    }
  };

  const applyYearFilter = () => {
    setDropdown4(false);
    filters.from_year = String(fromCarYear);
    from_year = String(fromCarYear);
    localStorage.setItem("fromYear", String(fromCarYear));
    queryParams.append("fromYear", String(fromCarYear));
    // testingUrl.searchParams?.append("fromYear", String(fromCarYear));

    filters.to_year = String(toCarYear);
    to_year = String(toCarYear);
    setFilterYear(`${filters.from_year} to ${filters.to_year}`);
    localStorage.setItem("toYear", String(toCarYear));
    queryParams.append("toYear", String(toCarYear));
    // testingUrl?.searchParams.append("toYear", String(toCarYear));
    // Parse existing query parameters
    // const queryParams = new URLSearchParams(window.location.search);

    // Add the new query parameters to the parsed object
    // queryParams.set("fromYear", String(fromCarYear));
    // queryParams.set("toYear", String(toCarYear));
    console.log(window.location.pathname);
    // let newUrl;
    // if (testingUrl.includes("?")) {
    //   newUrl = `${window.location.href}&${queryParams.toString()}`;
    // } else {
    //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    // }

    let newUrl;
    const baseUrl = window.location.href.split("?")[0];

    if (testingUrl.includes("?")) {
      let existingParams = window.location.search.substr(1); // Get the existing query parameters
      const paramsArray = existingParams.split("&");
      const updatedParams = paramsArray.filter(
        (param) =>
          !param.startsWith("fromYear=") && !param.startsWith("toYear=")
      ); // Remove existing "brand" parameter

      if (baseUrl.includes("?")) {
        newUrl = `${baseUrl}?${updatedParams.join(
          "&"
        )}&${queryParams.toString()}`;
      } else {
        newUrl = `${baseUrl}?${updatedParams.join("&")}`;
        if (updatedParams.length > 0) {
          newUrl += "&";
        }
        newUrl += queryParams.toString();
      }
    } else {
      newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    }

    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchCarItems(1, filters);
  };

  const applyMeterCounterFilter = () => {
    setDropdown5(false);
    filters.from_meter_counter = String(fromMeterCounter);
    from_meter_counter = String(fromMeterCounter);
    localStorage.setItem("fromMeterCounter", String(fromMeterCounter));
    // queryParams.set("fromMeterCounter", String(fromMeterCounter));
    queryParams.append("fromMeterCounter", String(fromMeterCounter));
    filters.to_meter_counter = String(toMeterCounter);
    to_meter_counter = String(toMeterCounter);

    setFilterKilometer(
      `${filters.from_meter_counter} to ${filters.to_meter_counter}`
    );
    console.log(filters, "weroiqwer");
    localStorage.setItem("toMeterCounter", String(toMeterCounter));
    // queryParams.set("toMeterCounter", String(toMeterCounter));
    queryParams.append("toMeterCounter", String(toMeterCounter));
    // let newUrl;
    // if (testingUrl.includes("?")) {
    //   newUrl = `${window.location.href}&${queryParams.toString()}`;
    // } else {
    //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    // }
    let newUrl;
    const baseUrl = window.location.href.split("?")[0];

    if (testingUrl.includes("?")) {
      let existingParams = window.location.search.substr(1); // Get the existing query parameters
      const paramsArray = existingParams.split("&");
      const updatedParams = paramsArray.filter(
        (param) =>
          !param.startsWith("fromMeterCounter=") &&
          !param.startsWith("toMeterCounter=")
      ); // Remove existing "brand" parameter

      if (baseUrl.includes("?")) {
        newUrl = `${baseUrl}?${updatedParams.join(
          "&"
        )}&${queryParams.toString()}`;
      } else {
        newUrl = `${baseUrl}?${updatedParams.join("&")}`;
        if (updatedParams.length > 0) {
          newUrl += "&";
        }
        newUrl += queryParams.toString();
      }
    } else {
      newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    }

    // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchCarItems(1, filters);
  };
  const applyCityFilter = () => {
    setDropdown1(false);
    setCities(citiesDataa);
    filters.city_id = String(cityID);
    city_id = String(cityID);
    localStorage.setItem("cityId", String(cityID));
    localStorage.setItem("cityName", String(cityName));
    // queryParams.set("cityId", String(cityID));
    queryParams.append(
      "city",
      String(cityName)
        .replace(/\+/g, " ")
        .replace(/\s+/g, "")
        .toLowerCase()
        .trim()
    );
    console.log(filters, "filters");

    // let newUrl;
    // if (testingUrl.includes("?")) {
    //   newUrl = `${window.location.href}&${queryParams.toString()}`;
    // } else {
    //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    // }
    let newUrl;
    const baseUrl = window.location.href.split("?")[0];

    if (testingUrl.includes("?")) {
      let existingParams = window.location.search.substr(1); // Get the existing query parameters
      const paramsArray = existingParams.split("&");
      const updatedParams = paramsArray.filter(
        (param) => !param.startsWith("city=")
      ); // Remove existing "brand" parameter

      if (baseUrl.includes("?")) {
        newUrl = `${baseUrl}?${updatedParams.join(
          "&"
        )}&${queryParams.toString()}`;
      } else {
        newUrl = `${baseUrl}?${updatedParams.join("&")}`;
        if (updatedParams.length > 0) {
          newUrl += "&";
        }
        newUrl += queryParams.toString();
      }
    } else {
      newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    }

    // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchCarItems(1, filters);
  };

  // banner calls end
  const [name1, setName1] = useState();
  const [email1, setEmail1] = useState();
  const [message1, setMessage1] = useState();
  const [contant1, setContant1] = useState();
  const [bannerId, setBannerId] = useState();
  const [filteredCities, setFilteredCities] = useState(cities);
  const [modalOpen, setModalOpen] = useState(false);

  const handlemidBannerRef = (e, id) => {
    setBannerId(id);
    setModalOpen(true);
  };
  const handlemidBannerRefClose = (e) => {
    // console.log(e);
    setModalOpen(false);
  };
  let token;

  const [activeTab, setActiveTab] = useState("all");
  const handleChangetabs = (e) => {
    // console.log(e.target.name);
    if (e.target.name === "all") {
      setActiveTab("all");
    } else if (e.target.name === "new") {
      setActiveTab("new");
    } else if (e.target.name === "old") {
      setActiveTab("old");
    }
  };

  useEffect(() => {
    if (activeTab === "all") {
      fetchCarItems(1, filters, "");
    } else if (activeTab === "new") {
      fetchCarItems(1, filters, "new");
    } else if (activeTab === "old") {
      fetchCarItems(1, filters, "used");
    }
  }, [activeTab]);

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // console.log(name1, email1, message1, contant1);

    if (
      name1 != "" &&
      name1 != undefined &&
      name1 != null &&
      email1 != "" &&
      email1 != undefined &&
      email1 != null &&
      message1 != "" &&
      message1 != undefined &&
      message1 != null &&
      contant1 != "" &&
      contant1 != undefined &&
      contant1 != null
    ) {
      // bannner data
      // IF INPUTS EMPTY OR NULL OR UNDEFINED
      const data = new FormData();
      data.append("name", name1);
      data.append("email", email1);
      data.append("message", message1);
      data.append("contact", contant1);
      data.append("banner_id", bannerId);
      fetch(process.env.REACT_APP_URL + "/create-banner-complaint", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);

          toast.success(data.message);
          setModalOpen(false);
          setName1();
          setEmail1();
          setMessage1();
          setContant1();
        });
      // .catch((e) => console.log(e));
    } else {
      toast.error("Invalid details");
    }
  };

  // useEffect(() => {
  //   if (fromCarPrice > toCarPrice) {
  //     // setA(a + b);
  //     // setB(a - b);
  //     // setA(a - b);

  //     setFromCarPrice(fromCarPrice + toCarPrice);
  //     setToCarPrice(fromCarPrice - toCarPrice);
  //     setFromCarPrice(fromCarPrice - toCarPrice);
  //   }
  // }, [fromCarPrice, toCarPrice]);

  // const handleUserKeyPress = (event) => {
  //   console.log(
  //     event.target.classList.contains("dropdown5Closee"),
  //     "Listner Works"
  //   );
  // };
  function containsNorNor(event) {
    const clickedElement = event.target;
    let currentElement = clickedElement;

    while (currentElement) {
      if (currentElement.classList.contains("dropdown5Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      if (currentElement.classList.contains("dropdown4Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      if (currentElement.classList.contains("dropdown3Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      if (currentElement.classList.contains("dropdown2Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      if (currentElement.classList.contains("dropdown1Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      currentElement = currentElement.parentElement;
    }

    // console.log(
    //   "The element and its ancestors do not contain 'nor nor' in their class name."
    // );
    setDropdown5(false);
    setDropdown4(false);
    setDropdown3(false);
    setDropdown2(false);
    setDropdown1(false);
  }

  useEffect(() => {
    // window.addEventListener("click", handleUserKeyPress);
    window.addEventListener("click", containsNorNor);
    return () => {
      window.removeEventListener("click", containsNorNor);
    };
  });

  return (
    <Helmet title="Cars">
      <BootstrapModals
        isOpen={modalOpen}
        onRequestClose={handlemidBannerRefClose}
        name={name1}
        setName={setName1}
        email={email1}
        setEmail={setEmail1}
        message={message1}
        setMessage={setMessage1}
        contact={contant1}
        setContact={setContant1}
        handleSubmit={handleSubmit1}
      />
      <CommonSection
        title="Car Listing"
        handlemidBannerRef={handlemidBannerRef}
        bannerIDD
      />

      <section>
        <Container className="responsivecontainer">
          <Row>
            <Col lg="12" className="main-colom-css-scroll">
              <div className=" d-flex align-items-center gap-3 mb-5 mainfilter-div">
                {/* <i className="ri-sort-asc"></i> */}

                <div
                  onClick={dropdown1fun}
                  className="filter-sub-box dropdown1Closee"
                >
                  <span className="sub-div-span1">City</span>
                  <span
                    style={{
                      fontSize: "15px",
                      width: "150px",
                      overflow: "hidden",
                    }}
                  >
                    {cityName}
                  </span>
                  <i className="ri-arrow-down-s-line sub-div-icon"></i>
                </div>
                {dropdown1 && (
                  <>
                    <div onClick={dropdownclose} className="box-div"></div>

                    <div className="div-drop-down-box dropdown1Closee">
                      <div className="custom-search-car">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(event) => {
                            // console.log(cities);
                            const searchString =
                              event.target.value.toLowerCase();
                            const filteredCities = citiesDataa?.filter((item) =>
                              item?.name.toLowerCase().includes(searchString)
                            );
                            setCities(filteredCities);
                          }}
                        />
                      </div>

                      <div className="drop-down-box-1">
                        {!cities && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "138px",
                            }}
                          >
                            <div class="spinner-border" role="status">
                              {/* <span class="sr-only">Loading...</span> */}
                            </div>
                          </div>
                        )}
                        {/* {true && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "55vh",
                            }}
                          >
                            <div class="spinner-border" role="status"></div>
                          </div>
                        )} */}
                        {/* {cities?.map && ( */}
                        {cities && (
                          <button
                            onClick={() => {
                              setDropdown1(false);
                              setCityName("All Cities");
                              localStorage.removeItem("cityId");
                              localStorage.removeItem("cityName");

                              const queryParams = new URLSearchParams(
                                window.location.search
                              );

                              // Remove the "fromYear" query parameter
                              queryParams.delete("city");

                              // Reconstruct the URL with the updated query parameters
                              const newUrl = `${
                                window.location.pathname
                              }?${queryParams.toString()}`;
                              window.history.pushState(
                                { path: newUrl },
                                "",
                                newUrl
                              );

                              // const newUrl = `${window.location.pathname}`;
                              // window.history.pushState(
                              //   { path: newUrl },
                              //   "",
                              //   newUrl
                              // );
                              fetchCarItems(1);
                            }}
                            style={{
                              all: "reset",
                              display: "inline-block",
                              padding: "4px 12px",
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "1px solid rgba(0, 0, 0, 0.12)",
                              transition: "0.15s all ease-in-out",
                              background: "white",
                              border: "solid 1px rgb(224, 225, 227)",
                              padding: "10px 16px",
                              fontSize: "13px",
                              borderRadius: "50px",
                              width: "100%",
                            }}
                          >
                            All Cities
                          </button>
                        )}
                        {cities?.map((city, index) => {
                          return (
                            <React.Fragment key={index}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCityName(city?.name);
                                  setCityID(city?.id);
                                }}
                                key={city?.id}
                                value={city?.id}
                              >
                                {city?.name}
                              </span>
                            </React.Fragment>
                          );
                        })}
                      </div>

                      <div className="footer-box-1-droup-down">
                        <button onClick={applyCityFilter}>Apply Filters</button>
                      </div>
                    </div>
                  </>
                )}

                <div
                  onClick={dropdown2fun}
                  className="filter-sub-box dropdown2Closee"
                >
                  <span className="sub-div-span1">Make</span>
                  {/* <span style={{ fontSize: "15px" }}>Select</span> */}
                  <span style={{ fontSize: "15px" }}>{make}</span>
                  <i className="ri-arrow-down-s-line sub-div-icon"></i>
                </div>
                {dropdown2 && (
                  <>
                    <div onClick={dropdownclose} className="box-div"></div>{" "}
                    <div
                      className="div-drop-down-box1 dropdown2Closee"
                      style={{ zIndex: "1" }}
                    >
                      <ul style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                        {brands && (
                          <>
                            <li
                              onClick={() => {
                                // applyBrandFilter(brand?.id);
                                // setMake(brand?.name);
                                setMake("Select");
                                setDropdown2(false);
                                setSelectimg1("");
                                localStorage.removeItem("brand");

                                const queryParams = new URLSearchParams(
                                  window.location.search
                                );

                                // Remove the "fromYear" query parameter
                                queryParams.delete("brand");

                                // Reconstruct the URL with the updated query parameters
                                const newUrl = `${
                                  window.location.pathname
                                }?${queryParams.toString()}`;
                                window.history.pushState(
                                  { path: newUrl },
                                  "",
                                  newUrl
                                );

                                // const newUrl = `${window.location.pathname}`;
                                // window.history.pushState(
                                //   { path: newUrl },
                                //   "",
                                //   newUrl
                                // );
                                fetchCarItems(1);
                              }}
                              className="listli-dropdown"
                            >
                              <span>Reset All</span>
                              {/* {selectimg1 !== brand?.id && (
                                <img className="checkimg" src={check} />
                              )} */}
                            </li>
                          </>
                        )}
                        {!brands && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "300px",
                            }}
                          >
                            <div class="spinner-border" role="status">
                              {/* <span class="sr-only">Loading...</span> */}
                            </div>
                          </div>
                        )}
                        {brands?.map((brand, index) => {
                          return (
                            <React.Fragment key={index}>
                              <li
                                onClick={() => {
                                  applyBrandFilter(brand?.id, brand?.name);
                                  setMake(brand?.name);
                                }}
                                className="listli-dropdown"
                              >
                                <span>{brand?.name}</span>
                                {selectimg1 == brand?.id && (
                                  <img className="checkimg" src={check} />
                                )}
                              </li>
                            </React.Fragment>

                            // <option key={brand.id} value={brand.id}>
                            //   {brand.name}
                            // </option>
                          );
                        })}
                        {/* <li onClick={() => { setSelectimg1("1") }} className="listli-dropdown">
                      <span>All in Used Cars for Sale</span>
                      {selectimg1 == "1" && <img className="checkimg" src={check} />}
                    </li>
                    <li onClick={() => { setSelectimg1("2") }} className="listli-dropdown">
                      <span>Abarth</span>
                      {selectimg1 == "2" && <img className="checkimg" src={check} />}
                    </li>
                    <li onClick={() => { setSelectimg1("3") }} className="listli-dropdown">
                      <span>Acura</span>
                      {selectimg1 == "3" && <img className="checkimg" src={check} />}
                    </li>
                    <li onClick={() => { setSelectimg1("4") }} className="listli-dropdown">
                      <span>Alfa Romeo</span>
                      {selectimg1 == "4" && <img className="checkimg" src={check} />}
                    </li>
                    <li onClick={() => { setSelectimg1("5") }} className="listli-dropdown">
                      <span>Ariel</span>
                      {selectimg1 == "5" && <img className="checkimg" src={check} />}
                    </li>
                    <li onClick={() => { setSelectimg1("6") }} className="listli-dropdown">
                      <span>Aston Martin</span>
                      {selectimg1 == "6" && <img className="checkimg" src={check} />}
                    </li>
                    <li onClick={() => { setSelectimg1("7") }} className="listli-dropdown">
                      <span>Audi</span>
                      {selectimg1 == "7" && <img className="checkimg" src={check} />}
                    </li> */}
                      </ul>
                    </div>
                  </>
                )}
                <div
                  onClick={dropdown3fun}
                  className="filter-sub-box dropdown3Closee"
                >
                  <span className="sub-div-span1">Price (AED)</span>
                  {/* <span style={{ fontSize: "15px" }}>Select</span> */}
                  <span style={{ fontSize: "15px" }}>{priceRange}</span>
                  <i className="ri-arrow-down-s-line sub-div-icon"></i>
                </div>
                {dropdown3 && (
                  <>
                    <div onClick={dropdownclose} className="box-div"></div>
                    <div
                      className="div-drop-down-box3 dropdown3Closee"
                      style={{ zIndex: "1" }}
                    >
                      <div className="drop-down-box-1">
                        <div style={{ display: "flex" }}>
                          <div>
                            <p style={{ marginBottom: "0" }}>From</p>
                            <input
                              className="input-drop-down"
                              type="number"
                              name="fromCarPrice"
                              value={fromCarPrice}
                              onChange={(e) => setFromCarPrice(e.target.value)}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                            />
                          </div>
                          <div>
                            <p style={{ marginBottom: "0" }}>To</p>
                            <input
                              className="input-drop-down"
                              type="number"
                              name="toCarPrice"
                              value={toCarPrice}
                              onChange={(e) => setToCarPrice(e.target.value)}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="footer-box-1-droup-down d-flex gap-2">
                        <button
                          onClick={() => {
                            setDropdown3(false);
                            localStorage.removeItem("fromPrice");
                            localStorage.removeItem("toPrice");
                            filters.from_price = "";
                            filters.to_price = "";
                            setPriceRange("Select");
                            setToCarPrice("");
                            setFromCarPrice("");

                            const queryParams = new URLSearchParams(
                              window.location.search
                            );

                            // Remove the "fromYear" query parameter
                            queryParams.delete("fromPrice");
                            queryParams.delete("toPrice");

                            // Reconstruct the URL with the updated query parameters
                            const newUrl = `${
                              window.location.pathname
                            }?${queryParams.toString()}`;
                            window.history.pushState(
                              { path: newUrl },
                              "",
                              newUrl
                            );

                            // const newUrl = `${window.location.pathname}`;
                            // window.history.pushState(
                            //   { path: newUrl },
                            //   "",
                            //   newUrl
                            // );
                            fetchCarItems(1);
                          }}
                        >
                          Reset
                        </button>
                        <button
                          onClick={applyPriceFilter}
                          // onKeyDown={handlePriceKeyDown}
                        >
                          Apply Filters
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div
                  onClick={dropdown4fun}
                  className="filter-sub-box dropdown4Closee"
                >
                  <span className="sub-div-span1">Year</span>
                  <span style={{ fontSize: "15px" }}>{filterYear}</span>
                  <i className="ri-arrow-down-s-line sub-div-icon"></i>
                </div>
                {dropdown4 && (
                  <>
                    <div onClick={dropdownclose} className="box-div"></div>
                    <div
                      className="div-drop-down-box4 dropdown4Closee"
                      style={{ zIndex: "1" }}
                    >
                      <div className="drop-down-box-1">
                        <div style={{ display: "flex" }}>
                          <div>
                            <p style={{ marginBottom: "0" }}>From</p>
                            <input
                              className="input-drop-down"
                              type="number"
                              name="fromCarYear"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              value={fromCarYear}
                              onChange={(e) => setFromCarYear(e.target.value)}
                            />
                          </div>
                          <div>
                            <p style={{ marginBottom: "0" }}>Upto</p>
                            <input
                              className="input-drop-down"
                              type="number"
                              name="toCarYear"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              value={toCarYear}
                              onChange={(e) => setToCarYear(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="footer-box-1-droup-down d-flex gap-2">
                        <button
                          onClick={() => {
                            setDropdown4(false);
                            localStorage.removeItem("fromYear");
                            localStorage.removeItem("toYear");
                            setFilterYear("Select");
                            setToCarYear("");
                            setFromCarYear("");
                            filters.from_year = "";
                            filters.toYear = "";

                            const queryParams = new URLSearchParams(
                              window.location.search
                            );

                            // Remove the "fromYear" query parameter
                            queryParams.delete("fromYear");
                            queryParams.delete("toYear");

                            // Reconstruct the URL with the updated query parameters
                            const newUrl = `${
                              window.location.pathname
                            }?${queryParams.toString()}`;
                            window.history.pushState(
                              { path: newUrl },
                              "",
                              newUrl
                            );
                            // 3450235039485098534
                            // const newUrl = `${window.location.pathname}`;
                            // window.history.pushState(
                            //   { path: newUrl },
                            //   "",
                            //   newUrl
                            // );
                            fetchCarItems(1);
                          }}
                        >
                          Reset
                        </button>
                        <button onClick={applyYearFilter}>Apply Filters</button>
                      </div>
                    </div>
                  </>
                )}

                <>
                  <div
                    onClick={dropdown5fun}
                    className="filter-sub-box unset-border-last dropdown5Closee"
                    // style={{ zIndex: "10 !important", backgroundColor: "red" }}
                  >
                    <span className="sub-div-span1">Kilometers</span>
                    {/* <span style={{ fontSize: "15px" }}>Select</span> */}
                    <span style={{ fontSize: "15px" }}>{filterKilometer}</span>
                    <i className="ri-arrow-down-s-line sub-div-icon"></i>
                  </div>

                  {dropdown5 && (
                    <>
                      <div onClick={dropdownclose} className="box-div"></div>
                      <div
                        className="div-drop-down-box5 dropdown5Closee"
                        style={{
                          zIndex: "1",
                        }}
                      >
                        {" "}
                        {/* <ClickAwayListener
                          mouseEvent="onMouseDown"
                          touchEvent="onTouchStart"
                          // onClickAway={}
                        > */}
                        <div className="drop-down-box-1">
                          <div style={{ display: "flex" }}>
                            <div>
                              <p style={{ marginBottom: "0" }}>From</p>
                              <input
                                className="input-drop-down"
                                type="number"
                                name="fromMeterCounter"
                                value={fromMeterCounter}
                                onChange={(e) =>
                                  setFromMeterCounter(e.target.value)
                                }
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                            </div>
                            <div>
                              <p style={{ marginBottom: "0" }}>Upto</p>
                              <input
                                className="input-drop-down"
                                type="number"
                                name="toMeterCounter"
                                value={toMeterCounter}
                                onChange={(e) =>
                                  setToMeterCounter(e.target.value)
                                }
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* </ClickAwayListener> */}
                        <div className="footer-box-1-droup-down d-flex gap-2">
                          <button
                            onClick={() => {
                              setDropdown5(false);
                              localStorage.removeItem("fromMeterCounter");
                              localStorage.removeItem("toMeterCounter");
                              setFilterKilometer("Select");
                              setToMeterCounter("");
                              setFromMeterCounter("");

                              filters.from_meter_counter = "";
                              filters.to_meter_counter = "";

                              const queryParams = new URLSearchParams(
                                window.location.search
                              );

                              // Remove the "fromYear" query parameter
                              queryParams.delete("fromMeterCounter");
                              queryParams.delete("toMeterCounter");

                              // Reconstruct the URL with the updated query parameters
                              const newUrl = `${
                                window.location.pathname
                              }?${queryParams.toString()}`;
                              window.history.pushState(
                                { path: newUrl },
                                "",
                                newUrl
                              );

                              // const newUrl = `${window.location.pathname}`;
                              // window.history.pushState(
                              //   { path: newUrl },
                              //   "",
                              //   newUrl
                              // );
                              fetchCarItems(1);
                            }}
                          >
                            Reset
                          </button>
                          <button onClick={applyMeterCounterFilter}>
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {/* </ClickAwayListener> */}
                </>
              </div>
            </Col>
            {}
            <Col lg="8" md="8" sm="6" id="colom-8">
              <div className="container d-flex m-0" style={{ padding: 0 }}>
                <div
                  className="react-switch mb-3 p-0"
                  style={{ gridTemplateColumns: "repeat(3,1fr)" }}
                >
                  {/* {console.log(activeTab, "activeTab")} */}
                  <button
                    onClick={handleChangetabs}
                    name="all"
                    style={{
                      backgroundColor: activeTab === "all" ? "#27aae1" : "",
                      color: activeTab === "all" ? "#fff" : "",
                    }}
                  >
                    All Car
                  </button>
                  <button
                    onClick={handleChangetabs}
                    // onClick={handleChange2}
                    // onClick={handleChangetabs}
                    name="new"
                    style={{
                      backgroundColor: activeTab === "new" ? "#27aae1" : "",
                      color: activeTab === "new" ? "#fff" : "",
                    }}
                  >
                    New Car
                  </button>
                  <button
                    name="old"
                    onClick={handleChangetabs}
                    style={{
                      backgroundColor: activeTab === "old" ? "#27aae1" : "",
                      color: activeTab === "old" ? "#fff" : "",
                    }}
                  >
                    Used Car
                  </button>
                </div>
              </div>
              {/* {console.log(carItems?.data?.length === 0, "carItems?.data ")} */}
              {carItems?.data?.length === 0 && <h2>No cars available</h2>}
              {carItems?.data ? (
                carItems?.data?.slice(0, 4).map((car, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Cardsitemlista
                        img={car.featured_image}
                        key={car.id}
                        data={car}
                        activeTab={activeTab}
                      />
                    </React.Fragment>
                  );
                })
              ) : (
                <>
                  {Array.from(new Array(4)).map((_, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="container bcontent dealer-card-wrapper res-dealer-trans">
                          <div className="card-feature-img">
                            <div className="row no-gutters">
                              <div
                                className="col-sm-5 placeholder-glow"
                                style={{ position: "relative" }}
                              >
                                <svg
                                  className="bd-placeholder-img card-img-top placeholder"
                                  width="100%"
                                  height="180"
                                  xmlns="http://www.w3.org/2000/svg"
                                  role="img"
                                  aria-label="Placeholder"
                                  preserveAspectRatio="xMidYMid slice"
                                  focusable="false"
                                >
                                  <title>Placeholder</title>
                                  <rect
                                    width="100%"
                                    height="100%"
                                    fill="#2e3192"
                                  ></rect>
                                </svg>
                              </div>

                              <div className="col-sm-7 mt-2">
                                <div className="dealer-card-body">
                                  <p
                                    className="card-title card-title placeholder-glow"
                                    style={{ margin: "0 0 5px" }}
                                  >
                                    <span
                                      className="placeholder col-9"
                                      style={{
                                        backgroundColor: "#2e3192",
                                      }}
                                    ></span>
                                  </p>
                                  <p
                                    style={{ margin: "0 0 5px" }}
                                    className="placeholder-glow"
                                  >
                                    <span
                                      className="placeholder col-9"
                                      style={{
                                        backgroundColor: "#2e3192",
                                      }}
                                    ></span>
                                  </p>
                                  <p
                                    style={{ margin: "0 0 5px" }}
                                    className="company-description placeholder-glow"
                                  >
                                    <span
                                      className="placeholder col-9"
                                      style={{
                                        backgroundColor: "#2e3192",
                                      }}
                                    ></span>
                                  </p>
                                  <p
                                    className="contact-no placeholder-glow"
                                    style={{ margin: "0 0 5px" }}
                                  >
                                    <span
                                      className="placeholder col-9"
                                      style={{
                                        backgroundColor: "#2e3192",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
              {/* <Cardsitemlista img={carimg2} />
              <Cardsitemlista img={carimg3} />
              <Cardsitemlista img={carimg4} /> */}
              <div ref={midBannerRef} style={{ position: "relative" }}>
                <Slider {...settings} afterChange={afterMidBannerChangeHandler}>
                  {carMidBanner?.data?.length == 0 && (
                    <img className="banner-btm" src={banner2} />
                  )}
                  {carMidBanner?.data?.length > 0 &&
                    carMidBanner?.data?.map((midBanner, index) => {
                      return (
                        <React.Fragment key={index}>
                          {midBanner?.image && (
                            <>
                              <img
                                src={midBanner?.image}
                                key={index}
                                className="banner-btm"
                                onClick={() =>
                                  openBannerLinkPage(
                                    midBanner?.link,
                                    midBanner?.target,
                                    midBanner?.id,
                                    "Yes",
                                    "No"
                                  )
                                }
                                data-mid-banner-id={midBanner?.id}
                              />
                              <img
                                src={Info}
                                alt=""
                                style={{
                                  position: "absolute",
                                  top: 10,
                                  right: 10,
                                  backgroundColor: "#fff",
                                  borderRadius: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  handlemidBannerRef(e, midBanner?.id)
                                }
                              />
                            </>
                          )}
                          {midBanner?.script && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: midBanner.script,
                              }}
                              key={index}
                            />
                          )}
                        </React.Fragment>
                      );
                      // }
                    })}
                </Slider>
              </div>
              {carItems?.data?.slice(4, 7).map((car, index) => {
                return (
                  <React.Fragment key={index}>
                    <Cardsitemlista
                      img={car.featured_image}
                      key={car.id}
                      data={car}
                      activeTab={activeTab}
                    />
                  </React.Fragment>
                );
              })}
              {/* <Cardsitemlista img={carimg5} />
              <Cardsitemlista img={carimg6} />
              <Cardsitemlista img={carimg7} /> */}
              <div ref={bottomBannerRef} style={{ position: "relative" }}>
                <Slider
                  {...settings}
                  afterChange={afterBottomBannerChangeHandler}
                >
                  {carBottomBanner?.data?.length == 0 && (
                    <img className="banner-btm" src={banner1} />
                  )}
                  {carBottomBanner?.data?.length > 0 &&
                    carBottomBanner?.data?.map((bottomBanner, index) => {
                      return (
                        <React.Fragment key={index}>
                          {bottomBanner.length}
                          {bottomBanner?.image && (
                            <>
                              <img
                                src={bottomBanner?.image}
                                key={index}
                                className="banner-btm"
                                onClick={() =>
                                  openBannerLinkPage(
                                    bottomBanner?.link,
                                    bottomBanner?.target,
                                    bottomBanner?.id,
                                    "Yes",
                                    "No"
                                  )
                                }
                                data-bottom-banner-id={bottomBanner?.id}
                              />
                              <img
                                src={Info}
                                alt=""
                                style={{
                                  position: "absolute",
                                  top: 10,
                                  right: 10,
                                  backgroundColor: "#fff",
                                  borderRadius: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  handlemidBannerRef(e, bottomBanner?.id)
                                }
                              />
                            </>
                          )}

                          {bottomBanner?.script && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: bottomBanner.script,
                              }}
                              key={index}
                            />
                          )}
                        </React.Fragment>
                      );
                      // }
                    })}
                </Slider>
              </div>
              {carItems?.data?.slice(7, 10).map((car, index) => {
                return (
                  <React.Fragment key={index}>
                    <Cardsitemlista
                      img={car.featured_image}
                      key={car.id}
                      data={car}
                      activeTab={activeTab}
                    />
                  </React.Fragment>
                );
              })}
              {/* <Cardsitemlista img={carimg8} />
              <Cardsitemlista img={carimg9} />
              <Cardsitemlista img={carimg9} /> */}
              <Pagination
                activePage={carItems?.current_page}
                totalItemsCount={carItems?.total ?? 0}
                itemsCountPerPage={parseInt(carItems?.per_page)}
                onChange={(pageNumber) => getFilters(pageNumber, filters)}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First"
                lastPageText="Last"
                prevPageText="Previous"
                nextPageText="Next"
                activeClass="active"
                activeLinkClass="active-link"
                getPageUrl={(pageNumber) => {
                  // Construct the URL with the filter values
                  const url = new URL("/cars", window.location.href);
                  url.searchParams.set("page", pageNumber);
                  url.searchParams.set("fromPrice", filters.from_price);
                  url.searchParams.set("toPrice", filters.to_price);
                  url.searchParams.set("countryId", filters.country_id);
                  url.searchParams.set("brand", filters.brand_id);
                  url.searchParams.set("transmission", filters.transmission);
                  url.searchParams.set("fromYear", filters.from_year);
                  url.searchParams.set("toYear", filters.to_year);
                  url.searchParams.set(
                    "fromMeterCounter",
                    filters.from_meter_counter
                  );
                  url.searchParams.set(
                    "toMeterCounter",
                    filters.to_meter_counter
                  );
                  url.searchParams.set("modelId", filters.model_id);
                  url.searchParams.set("cityId", filters.city_id);
                  return url.toString();
                }}
              />
            </Col>
            <Col id="colom-4" lg="4" md="4" sm="6">
              <div ref={topSidebarBannerRef} style={{ position: "relative" }}>
                <Slider
                  // style={{ zIndex: "-1" }}
                  {...settings}
                  afterChange={afterTopSidebarBannerChangeHandler}
                >
                  {carTopSidebarBanner?.data?.length == 0 && (
                    <img className="sidebanner-slider-img" src={banner3} />
                  )}
                  {carTopSidebarBanner?.data == undefined && (
                    // <img className="sidebanner-slider-img" src={banner3} />
                    <>
                      <div
                        // className="slider-car-responsive col-sm-12 placeholder-glow sidebanner-slider-img"
                        className="col-sm-12 placeholder-glow sidebanner-slider-img"
                        style={{
                          position: "relative",
                          // width: "730px",
                          // height: "570px",
                        }}
                      >
                        <svg
                          className="bd-placeholder-img card-img-top placeholder"
                          width="100%"
                          height="1370"
                          xmlns="http://www.w3.org/2000/svg"
                          role="img"
                          aria-label="Placeholder"
                          preserveAspectRatio="xMidYMid slice"
                          focusable="false"
                        >
                          <title>Placeholder</title>
                          <rect
                            width="100%"
                            height="100%"
                            fill="#2e3192"
                          ></rect>
                        </svg>
                      </div>
                    </>
                  )}
                  {carTopSidebarBanner?.data?.length > 0 &&
                    carTopSidebarBanner?.data?.map(
                      (topSidebarBanner, index) => {
                        return (
                          <React.Fragment key={index}>
                            {topSidebarBanner.length}
                            {topSidebarBanner?.image && (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    margin: "40px auto 0 43px",
                                    width: "270px",
                                  }}
                                >
                                  <img
                                    src={topSidebarBanner?.image}
                                    key={index}
                                    className="sidebanner-slider-img"
                                    onClick={() =>
                                      openBannerLinkPage(
                                        topSidebarBanner?.link,
                                        topSidebarBanner?.target,
                                        topSidebarBanner?.id,
                                        "Yes",
                                        "No"
                                      )
                                    }
                                    data-top-sidebar-banner-id={
                                      topSidebarBanner?.id
                                    }
                                  />
                                  <img
                                    src={Info}
                                    alt=""
                                    style={{
                                      // position: "absolute",
                                      // top: 53,
                                      // right: 55,
                                      // cursor: "pointer",
                                      // backgroundColor: "#fff",
                                      // borderRadius: "16px",
                                      // cursor: "pointer",
                                      position: "absolute",
                                      top: "15px",
                                      right: "15px",
                                      cursor: "pointer",
                                      backgroundColor: "rgb(255, 255, 255)",
                                      borderRadius: "16px",
                                    }}
                                    onClick={(e) =>
                                      handlemidBannerRef(
                                        e,
                                        topSidebarBanner?.id
                                      )
                                    }
                                  />
                                </div>
                              </>
                            )}
                            {topSidebarBanner?.script && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: topSidebarBanner.script,
                                }}
                                key={index}
                              />
                            )}
                          </React.Fragment>
                        );
                        // }
                      }
                    )}
                </Slider>
              </div>

              <div
                ref={bottomSiderbarBannerRef}
                style={{ position: "relative" }}
              >
                <Slider
                  {...settings}
                  afterChange={afterBottomSidebarBannerChangeHandler}
                >
                  {carBottomSidebarBanner?.data?.length == 0 && (
                    <img className="sidebanner-slider-img" src={banner4} />
                  )}

                  {carBottomSidebarBanner?.data == undefined && (
                    // <img className="sidebanner-slider-img" src={banner3} />
                    <>
                      <div
                        // className="slider-car-responsive col-sm-12 placeholder-glow sidebanner-slider-img"
                        className="col-sm-12 placeholder-glow sidebanner-slider-img mt-5"
                        style={{
                          position: "relative",
                          // width: "730px",
                          // height: "570px",
                        }}
                      >
                        <svg
                          className="bd-placeholder-img card-img-top placeholder"
                          width="100%"
                          height="1370"
                          xmlns="http://www.w3.org/2000/svg"
                          role="img"
                          aria-label="Placeholder"
                          preserveAspectRatio="xMidYMid slice"
                          focusable="false"
                        >
                          <title>Placeholder</title>
                          <rect
                            width="100%"
                            height="100%"
                            fill="#2e3192"
                          ></rect>
                        </svg>
                      </div>
                    </>
                  )}

                  {carBottomSidebarBanner?.data?.length > 0 &&
                    carBottomSidebarBanner?.data?.map(
                      (bottomSidebarBanner, index) => {
                        return (
                          <React.Fragment key={index}>
                            {bottomSidebarBanner.length}
                            {bottomSidebarBanner?.image && (
                              <React.Fragment key={index}>
                                <div
                                  style={{
                                    position: "relative",
                                    margin: "40px auto 0 43px",
                                    width: "270px",
                                  }}
                                >
                                  <img
                                    src={bottomSidebarBanner?.image}
                                    key={index}
                                    className="sidebanner-slider-img"
                                    onClick={() =>
                                      openBannerLinkPage(
                                        bottomSidebarBanner?.link,
                                        bottomSidebarBanner?.target,
                                        bottomSidebarBanner?.id,
                                        "Yes",
                                        "No"
                                      )
                                    }
                                    data-bottom-sidebar-banner-id={
                                      bottomSidebarBanner?.id
                                    }
                                  />
                                  <img
                                    src={Info}
                                    alt=""
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      right: "15px",
                                      cursor: "pointer",
                                      backgroundColor: "rgb(255, 255, 255)",
                                      borderRadius: "16px",
                                    }}
                                    onClick={(e) =>
                                      handlemidBannerRef(
                                        e,
                                        bottomSidebarBanner?.id
                                      )
                                    }
                                  />
                                </div>
                              </React.Fragment>
                            )}
                            {bottomSidebarBanner?.script && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: bottomSidebarBanner.script,
                                }}
                                key={index}
                              />
                            )}
                          </React.Fragment>
                        );
                        // }
                      }
                    )}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarListing;
