import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";

import { useParams } from "react-router-dom";
import blogData from "../assets/data/blogData.js";
import Helmet from "../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import SeeLess from "../components/SeeLess/SeeLess.js";

import commentImg from "../assets/all-images/ava-1.jpg";

import "../styles/blog-details.css";

const BlogDetails = () => {
  const params = useParams();

  const [blog, setBlog] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [blog]);

  useEffect(() => {
    fetchBlogDetails();
  }, []);

  const fetchBlogDetails = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-blog-by-slug?slug=" + params.slug,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data);
        setBlog(data.data);
        if (data?.data === undefined) {
          // ("/destination-route");
          window.location.href = "/notfound";
        }
      });
  };

  return (
    <Helmet title={blog?.name || ""}>
      <section>
        <Container>
          <Row>
            <Col lg="8" md="8">
              <div className="blog__details">
                {/* <img
                  src={blog?.thumbnail}
                  alt=""
                  style={{
                    maxWidth: "855px",
                    maxHeight: "580px",
                    width: "100%",
                  }}
                /> */}
                {blog?.thumbnail ? (
                  <div className="blog-detail-image">
                    <img
                      src={blog.thumbnail}
                      alt=""
                      // style={{
                      //   maxWidth: "855px",
                      //   maxHeight: "580px",
                      //   width: "100%",
                      // }}
                    />
                  </div>
                ) : (
                  <>
                    {" "}
                    <svg
                      className="bd-placeholder-img card-img-top placeholder"
                      width="100%"
                      height="570"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#2e3192"></rect>
                    </svg>
                  </>
                )}
                <h2 className="section__title mt-4">
                  {blog?.name ? (
                    blog?.name
                  ) : (
                    <>
                      <div className="placeholder-glow">
                        <span className="blog__author placeholder w-50"></span>
                      </div>
                    </>
                  )}
                </h2>
                <div className="blog__publisher d-flex align-items-center gap-4 mb-4">
                  {/* {blog?.category ? ( */}
                  {blog?.category ? (
                    <>
                      {" "}
                      <span className="blog__author">
                        <i className="ri-user-line"></i> {blog?.category}
                      </span>
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-calendar-line"></i> {blog?.created_at}
                      </span>
                      <span className="d-flex align-items-center gap-1 section__description">
                        <i className="ri-time-line"></i> {blog?.time}
                      </span>
                    </>
                  ) : (
                    <>
                      {/* <div className="placeholder-glow">
                        <span className="blog__author placeholder w-50"></span>
                        <span className="d-flex align-items-center gap-1 section__description placeholder w-50">
                          {blog?.created_at}
                        </span>
                        <span className="d-flex align-items-center gap-1 section__description placeholder w-50"></span>
                      </div> */}
                      <div className="placeholder-glow  w-50">
                        <span className="blog__author placeholder w-50"></span>
                      </div>
                    </>
                  )}
                </div>
                {/* <p
                  className="section__description"
                  dangerouslySetInnerHTML={{
                    __html: blog?.description.replace(/\n/g, "<br>"),
                  }}
                ></p> */}
                {blog?.description ? (
                  <>
                    {" "}
                    <SeeLess content={blog?.description} />
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="placeholder-glow  w-50">
                      <span className="blog__author placeholder w-50"></span>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default BlogDetails;
