import React, { useState } from "react";

const SeeLess = ({ content }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const handleSeeMoreClick = () => {
    setShowFullContent(true);
  };

  const handleSeeLessClick = () => {
    setShowFullContent(false);
  };

  return (
    <div>
      {showFullContent ? (
        <div>
          {/* {content} */}
          <p
            className="section__description"
            dangerouslySetInnerHTML={{
              __html: content?.replace(/\n/g, "<br>"),
            }}
          ></p>
        </div>
      ) : (
        <div>
          {/* {content?.slice(0, 200)}...             */}
          <p
            className="section__description"
            dangerouslySetInnerHTML={{
              __html: content?.slice(0, 200)?.replace(/\n/g, "<br>"),
            }}
          ></p>
        </div>
      )}

      {content?.length > 200 && !showFullContent && (
        <button className="contact__btn" onClick={handleSeeMoreClick}>
          See More
        </button>
      )}

      {showFullContent && (
        <button className="contact__btn" onClick={handleSeeLessClick}>
          See Less
        </button>
      )}
    </div>
  );
};

export default SeeLess;
