import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";

import { useParams } from "react-router-dom";
import blogData from "../assets/data/blogData.js";
import Helmet from "../components/Helmet/Helmet";
import { Link } from "react-router-dom";

import commentImg from "../assets/all-images/ava-1.jpg";

import "../styles/blog-details.css";
import { toast } from "react-toastify";
import { Formik, useFormik, ErrorMessage } from "formik";

import * as Yup from "yup";

// const ContactFormSchema = Yup.object({
//   // nameregix: Yup.string().required("Name is required"),
//   name: Yup.string()
//     .min(3, "Too short!")
//     .max(30, "Too long!")
//     .trim()
//     .matches(/^[A-Za-z\s]*$/, "Name must contain only letters")
//     .required("Name is required"),
//   email: Yup.string().email("Invalid email").required("Email is required"),
//   number: Yup.string().required("subject is required"),
//   uploadfile: Yup.string().required("Message is required"),
//   message: Yup.string().required("Message is required"),
// });

const MAX_FILE_SIZE = 5120; //100KB

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};

const ContactFormSchema = Yup.object({
  name: Yup.string()
    .min(3, "Too short!")
    .max(30, "Too long!")
    .trim()
    .matches(/^[A-Za-z\s]*$/, "Name must contain only letters")
    .required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  number: Yup.string()
    .min(11, "contact number must be at least 11 characters")
    .max(13, "contact number can be up to 13 characters")
    .required("contact number is required"),
  // uploadfile: Yup.string().required("Upload file is required"),
  uploadfile: Yup.mixed().required("file is required"),
  // .test(
  //   "is-valid-size",
  //   "Max allowed size is 100KB",
  //   (value) => value && value.size <= MAX_FILE_SIZE
  // ),
  message: Yup.string().required("Message is required"),
});

const Careerdetail = () => {
  const params = useParams();

  const [job, setJob] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [resume, setResume] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fileRef = useRef("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [job]);

  useEffect(() => {
    fetchBlogDetails();
  }, []);

  const fetchBlogDetails = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-career-by-slug?slug=" + params.id,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setJob(data.data);
        if (data?.data === undefined) {
          // ("/destination-route");
          window.location.href = "/notfound";
        }
      });
  };

  const addCareerPostApplication = () => {
    setIsLoading(true);
    // e.preventDefault();
    // IF INPUTS EMPTY OR NULL OR UNDEFINED
    if (
      name != "" &&
      name != undefined &&
      name != null &&
      message != "" &&
      message != undefined &&
      message != null &&
      email != "" &&
      email != undefined &&
      email != null &&
      resume != "" &&
      resume != undefined &&
      resume != null &&
      contact != "" &&
      contact != undefined &&
      contact != null
    ) {
      const data = new FormData();
      data.append("name", name);
      data.append("message", message);
      if (resume) {
        data.append("resume", resume);
      }
      data.append("email", email);
      data.append("contact", contact);
      data.append("career_post_id", params.id);
      fetch(process.env.REACT_APP_URL + "/create-career-application", {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            toast.success(data.message);
            setName("");
            setEmail("");
            setContact("");
            setMessage("");
            // setResume((e.target.value = null));
            // setResume(() => null);
            fileRef.current.value = "";
            // console.log("success", fileRef.current.value);
            setResume(null); // Set resume state to null
            setIsLoading(false);
          } else {
            toast.error("Cannot submit your the application");
            setIsLoading(false);
          }
        });
    } else {
      toast.error("Fill the details");
      setIsLoading(false);
    }
  };

  const handleResume = (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();

      if (extension === "doc" || extension === "docx" || extension === "pdf") {
        setResume(file);
      } else {
        toast.error("File must be in word document or pdf format");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      // name: "",
      // email: "",
      // subject: "",
      // message: "",
      name: "",
      email: "",
      number: "",
      uploadfile: "",
      message: "",
    },
    validationSchema: ContactFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      // seterror("");
      // fetchUserDetail(values);
      if (formik.isValid) {
        addCareerPostApplication()
      }
      setSubmitting(false);
      // dispatch(setLoading(true));
    },
  });

  return (
    <section>
      <Formik>
        <Container>
          <Row>
            <Col lg="12" md="12">
              <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
                {job?.name}
              </h1>
              <div style={{ width: "80%", marginLeft: "10%" }}>
                <p style={{ fontSize: "12px" }}>{job?.time_ago}</p>
                <h2>About the Job</h2>
                <p>{job?.description}</p>
              </div>
              <div style={{ width: "80%", marginLeft: "10%" }}>
                <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
                  Apply Now
                </h1>
                <div style={{ position: "relative" }}>
                  <input
                    style={{
                      width: "80%",
                      padding: "10px",
                      display: "block",
                      margin: "0 auto",
                    }}
                    placeholder="Name"
                    type="text"
                    onBlur={formik.handleBlur}
                    value={name}
                    // onChange={(e) => setName(e.target.value)}
                    onChange={(e) => {
                      setName(e.target.value);
                      formik.setFieldValue("name", e.target.value);
                    }}
                    name="name"
                  />
                  {/* {console.log(
                    formik.touched.name,
                    formik?.errors?.name,
                    "data visible"
                  )} */}
                  {Boolean(formik?.errors?.name) ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-18px",
                        left: "1px",
                        fontSize: "12px",
                        color: "red",
                        width: "80%",
                        transform: "translateX(13%)",
                      }}
                    >
                      {formik?.errors.name}
                    </div>
                  ) : null}
                </div>
                <br />
                <div style={{ position: "relative" }}>
                  <input
                    style={{
                      width: "80%",
                      padding: "10px",
                      display: "block",
                      margin: "0 auto",
                    }}
                    placeholder="Email"
                    type="email"
                    value={email}
                    name="email"
                    onBlur={formik.handleBlur}
                    // onChange={(e) => setEmail(e.target.value)}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      formik.setFieldValue("email", e.target.value);
                    }}
                  />{" "}
                  { Boolean(formik?.errors?.email) ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-18px",
                        left: "1px",
                        fontSize: "12px",
                        color: "red",
                        width: "80%",
                        transform: "translateX(13%)",
                      }}
                    >
                      { formik?.errors.email}
                    </div>
                  ) : null}
                </div>
                <br />
                <div style={{ position: "relative" }}>
                  <input
                    style={{
                      width: "80%",
                      padding: "10px",
                      display: "block",
                      margin: "0 auto",
                    }}
                    placeholder="Contact"
                    type="number"
                    value={contact}
                    // onChange={(e) => setContact(e.target.value)}
                    onChange={(e) => {
                      setContact(e.target.value);
                      formik.setFieldValue("number", e.target.value);
                    }}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    name="number"
                    onBlur={formik.handleBlur}
                  />{" "}
                  { Boolean(formik?.errors?.number) ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-18px",
                        left: "1px",
                        fontSize: "12px",
                        color: "red",
                        width: "80%",
                        transform: "translateX(13%)",
                      }}
                    >
                      { formik?.errors.number}
                    </div>
                  ) : null}
                </div>
                <br />
                <p
                  style={{
                    width: "80%",
                    display: "block",
                    margin: "0 auto",
                  }}
                >
                  Upload Resume
                </p>
                <div style={{ position: "relative" }}>
                  {/* {console.log("fileRef", fileRef.current.value)} */}
                  <input
                    style={{
                      width: "80%",
                      padding: "10px",
                      display: "block",
                      margin: "0 auto",
                      border: "1px solid #000",
                    }}
                    placeholder="Upload Resume"
                    type="file"
                    ref={fileRef}
                    // onChange={(e) => handleResume(e)}
                    onChange={(e) => {
                      handleResume(e);
                      formik.setFieldValue("uploadfile", e.target.files[0]);
                      // console.log(
                      //   e.target.files[0],
                      //   "jwoqiejfio",
                      //   "uploadfile"
                      // );
                    }}
                    name="uploadfile"
                    onBlur={formik.handleBlur}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf"
                  />{" "}
                  {/* {console.log(formik.values, "formik.values")} */}
                  {
                  Boolean(formik?.errors?.uploadfile) ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-18px",
                        left: "1px",
                        fontSize: "12px",
                        color: "red",
                        width: "80%",
                        transform: "translateX(13%)",
                      }}
                    >
                      { formik?.errors.uploadfile}
                    </div>
                  ) : null}
                </div>
                <br />
                <div style={{ position: "relative" }}>
                  <textarea
                    style={{
                      width: "80%",
                      padding: "10px",
                      display: "block",
                      margin: "0 auto",
                    }}
                    placeholder="Message"
                    name="message"
                    rows="10"
                    value={message}
                    // onChange={(e) => setMessage(e.target.value)}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      formik.setFieldValue("message", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  ></textarea>{" "}
                  {
                  Boolean(formik?.errors?.message) ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-18px",
                        left: "1px",
                        fontSize: "12px",
                        color: "red",
                        width: "80%",
                        transform: "translateX(13%)",
                      }}
                    >
                      { formik?.errors.message}
                    </div>
                  ) : null}
                </div>
                <br />
                {/* <button
                  style={{
                    backgroundColor: "#2a68b6",
                    border: "none",
                    color: "#fff",
                    padding: "10px",
                    width: "200px",
                    borderRadius: "5px",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "20px",
                  }}
                  disabled={!formik.isValid || !formik.dirty}
                  onClick={addCareerPostApplication}
                >
                  Apply
                </button> */}
                {!isLoading ? (
                  <button
                    style={{
                      backgroundColor: "#2a68b6",
                      border: "none",
                      color: "#fff",
                      padding: "10px",
                      width: "200px",
                      borderRadius: "5px",
                      display: "block",
                      margin: "0 auto",
                    }}
                    // disabled={!formik.isValid || !formik.dirty}
                    onClick={formik.handleSubmit}
                  >
                    Apply
                  </button>
                ) : (
                  <button
                    type="button"
                    // onClick={registerUser}
                    style={{
                      backgroundColor: "#2a68b6",
                      border: "none",
                      color: "#fff",
                      padding: "10px",
                      width: "200px",
                      borderRadius: "5px",
                      display: "block",
                      margin: "0 auto",
                      marginTop: "20px",
                    }}
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
                {(!formik.isValid || !formik.dirty) && (
                  <h6 style={{ textAlign: "center", marginTop: "4px" }}>
                    Kindly fill all the fields above
                  </h6>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Formik>
    </section>
  );
};

export default Careerdetail;
