import React from "react";
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";

const BookingForm = ({ data }) => {
  // console.log(data);
  const submitHandler = (event) => {
    event.preventDefault();
  };
  return (
    <Form onSubmit={submitHandler} style={{ paddingBottom: "1.3rem" }}>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">Exterior Color</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">
          {data?.details?.color ? (
            data?.details?.color
          ) : (
            <b-card>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
            </b-card>
          )}
        </p>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">Doors</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">{data?.details?.door} door</p>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">Body condition</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">{data?.details?.body_condition}</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">Mechanical condition</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">{data?.details?.mechanical_condition}</p>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">Interior Color</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">{data?.details?.interior_color}</p>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">Seller type</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">{data?.role}</p>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">Body Type</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">{data?.details?.body_type}</p>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">No. of Cylinders</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">{data?.details?.no_of_cylinders}</p>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp">Transmission Type</p>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block  mb-2">
        <p className="aditionalp1">{data?.transmission} Transmission</p>
      </FormGroup>
    </Form>
  );
};

export default BookingForm;
