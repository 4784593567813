import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import blogData from "../assets/data/blogData.js";
import Helmet from "../components/Helmet/Helmet";
import Pagination from "react-js-pagination";
import commentImg from "../assets/all-images/ava-1.jpg";

import "../styles/blog-details.css";

const Career = () => {
  const [jobs, setJobs] = useState();

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchJobs = async (pageNumber = 1) => {
    await fetch(
      process.env.REACT_APP_URL + "/get-front-careers?page=" + pageNumber,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setJobs(data.data);
      });
  };
  return (
    <section>
      <Container>
        <Row>
          <Col lg="12" md="12">
            <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
              Available Jobs
            </h1>
            <div
              style={{
                width: "80%",
                marginLeft: "10%",
                marginTop: "80px",
              }}
            >
              {jobs?.data?.map((job) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "14px",
                      paddingBottom: "0px",
                      borderBottom: "solid 1px",
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                    key={job.id}
                  >
                    <Link
                      to={`/careerdetail/${job.slug}`}
                      style={{ textDecoration: "none", fontSize: "20px" }}
                    >
                      {job.name}
                    </Link>
                    <p>{job.time_ago}</p>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <div style={{ marginTop: "4%", marginLeft: "10%" }}>
          <Pagination
            activePage={jobs?.current_page}
            totalItemsCount={jobs?.total ?? 0}
            itemsCountPerPage={parseInt(jobs?.per_page)}
            onChange={(pageNumber) => fetchJobs(pageNumber)}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First"
            lastPageText="Last"
          />
        </div>
      </Container>
    </section>
  );
};

export default Career;
