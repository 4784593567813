import React, { Fragment, useState, useEffect } from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../../routers/Routers";
// import { useLocation } from "react-router-dom";

const Layout = () => {
  // const [isNotFound, setIsNotFound] = useState(false);
  // const location = useLocation();
  //
  // useEffect(() => {
  //   if (localStorage?.getItem("currentRoute") === "notfound") {
  //     setIsNotFound(true);
  //   } else {
  //     setIsNotFound(false);
  //   }
  // }, [location]);
  // setIsNotFound(localStorage?.getItem("currentRoute"));
  // console.log(localStorage?.getItem("currentRoute"), "oqruoweir");
  // console.log(isNotFound, "isNotFound");

  return (
    <Fragment>
      {/* {!isNotFound && <Header />} */}
      {<Header />}
      <div>
        <Routers />
      </div>
      {/* <Footer /> */}
      {/* {!isNotFound && <Footer />} */}
      {<Footer />}
    </Fragment>
  );
};

export default Layout;
