import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const settings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  let token;
  if (loginInfo) {
    token = loginInfo["token"];
  }
  const Role = localStorage.getItem("Role");
  const signUpUrl = "https://cars.me/web-app/sign-up";
  const adminUrl = "https://cars.me/web-app/admin";
  const userUrl = "https://cars.me/web-app/user";
  return (
    <>
      <Slider {...settings} className="hero__slider">
        <div className="slider__item slider__item-01 mt0">
          <Container>
            <div className="slider__content ">
              <h4 className="text-light mb-3">
                Life is a trip. Enjoy the ride!
              </h4>
              <h1 className="text-light mb-4 paddingimp">
                Buy & Sell car for FREE
              </h1>

              <button className="btn reserve__btn mt-4">
                <Link
                  // to={Role ? (Role == "Admin" ? adminUrl : userUrl) : signUpUrl}
                  onClick={() => {
                    if (Role === "Admin") {
                      window.location.href = adminUrl;
                    } else if (Role === "User") {
                      window.location.href = userUrl;
                    } else {
                      window.location.href = signUpUrl;
                    }
                  }}
                >
                  Sell Your Car
                </Link>
              </button>
            </div>
          </Container>
        </div>

        <div className="slider__item slider__item-02 mt0">
          <Container>
            <div className="slider__content ">
              <h4 className="text-light mb-3">
                Life is a trip. Enjoy the ride!
              </h4>
              <h1 className="text-light mb-4 paddingimp">
                Buy & Sell car for FREE
              </h1>

              <button className="btn reserve__btn mt-4">
                <Link
                  // to={Role ? (Role == "Admin" ? adminUrl : userUrl) : signUpUrl}
                  onClick={() => {
                    if (Role === "Admin") {
                      window.location.href = adminUrl;
                    } else if (Role === "User") {
                      window.location.href = userUrl;
                    } else {
                      window.location.href = signUpUrl;
                    }
                  }}
                >
                  Sell Your Car
                </Link>
              </button>
            </div>
          </Container>
        </div>

        <div className="slider__item slider__item-03 mt0">
          <Container>
            <div className="slider__content ">
              <h4 className="text-light mb-3">
                Life is a trip. Enjoy the ride!
              </h4>
              <h1 className="text-light mb-4 paddingimp">
                Buy & Sell car for FREE
              </h1>

              <button className="btn reserve__btn mt-4">
                <Link
                  // to={Role ? (Role == "Admin" ? adminUrl : userUrl) : signUpUrl}
                  onClick={() => {
                    if (Role === "Admin") {
                      window.location.href = adminUrl;
                    } else if (Role === "User") {
                      window.location.href = userUrl;
                    } else {
                      window.location.href = signUpUrl;
                    }
                  }}
                >
                  Sell Your Car
                </Link>
              </button>
            </div>
          </Container>
        </div>
      </Slider>
    </>
  );
};

export default HeroSlider;
