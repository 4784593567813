import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "../styles/dealer.css";
import { Container, Row, Col } from "reactstrap";
import { FaPhone, FaLocationArrow } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import check from "../assets/all-images/tick.svg";
import Helmet from "../components/Helmet/Helmet";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import favicon from "../assets/all-images/white-heart.png";
import favicon1 from "../assets/all-images/red-heart.png";
import banner3 from "../assets/all-images/197687-OYP0MC-7101.jpg";
import banner4 from "../assets/all-images/197687-OYP0MC-710.jpg";
import CommonSection from "../components/UI/CommonSection";
import banner2 from "../assets/all-images/691.jpg";
import banner1 from "../assets/all-images/690.jpg";
import BootstrapModals from "../components/Modal/Modal";
import Info from "../assets/all-images/infoIcon.svg";

const Dealer = () => {
  const navigate = useNavigate();
  const testingUrl = window?.location?.href;
  const [favouriteIcons, setFavouriteIcons] = useState({});
  const [dropdown1, setDropdown1] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [brandCityName, setBrandCityName] = useState({
    brandName: "",
    CityName: "",
  });
  const [dropdown2, setDropdown2] = useState(false);
  const [cityName, setCityName] = useState("All Cities");
  const [makeFilter, setMakeFilter] = useState("Select");
  const [cities, setCities] = useState();
  const [city, setcity] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [brands, setBrands] = useState();
  const [selectimg1, setSelectimg1] = useState("");
  const [company, setCompany] = useState("");
  const [citiesDataa, setCitiesDataa] = useState();
  const [
    bottomSiderbarBannerSliderCondition,
    setBottomSiderbarBannerSliderCondition,
  ] = useState(null);
  const [carTopSidebarBanner, setCarTopSidebarBanner] = useState();
  const [topSiderbarSliderCondition, setTopSiderbarBannerSliderCondition] =
    useState(null);
  const [carBottomSidebarBanner, setCarBottomSidebarBanner] = useState();
  const bottomSiderbarBannerRef = useRef();
  const [carMidBanner, setCarMidBanner] = useState();
  let pageNumber = 1;
  const topSidebarBannerRef = useRef();
  const [carBottomBanner, setCarBottomBanner] = useState();
  let topSiderbarBannerSliderCondition2;
  let bottomSiderbarBannerSliderCondition2;
  const [midBannerSliderCondition, setMidBannerSliderCondition] =
    useState(null);
  let bottomBannerSliderCondition2;
  let midBannerSliderCondition2;

  const observer = useRef(null);
  const observer2 = useRef(null);
  // start setting
  const settings = {
    dots: false,
    // infinite: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 587,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  const afterMidBannerChangeHandler = (currentSlide) => {
    const activeSlide = carMidBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      midBannerSliderCondition2 = "xyz";
      if (
        midBannerSliderCondition === "abc" &&
        midBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };
  const afterBottomBannerChangeHandler = (currentSlide) => {
    const activeSlide = carBottomBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomBannerSliderCondition2 = "xyz";
      if (
        bottomBannerSliderCondition === "abc" &&
        bottomBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };

  const fetchCarBottomSidebarBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=company&position=Bottom Sidebar",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarBottomSidebarBanner(data.data); //get data in data
      });
  };
  const fetchCarTopSidebarBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=company&position=Top Sidebar",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarTopSidebarBanner(data.data); //get data in data
      });
  };
  const fetchCarMidBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-banner?page_slug=company&position=Mid",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarMidBanner(data.data); //get data in data
      });
  };
  const openBannerLinkPage = async (link, target, id, click, impression) => {
    await fetch(
      process.env.REACT_APP_URL +
        "/update-banner-clicks-and-impressions?id=" +
        id +
        "&click=" +
        click +
        "&impression=" +
        impression,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {});
    if (link) {
      if (target == 1) {
        window.open(link, "_blank");
      } else {
        window.location.href = link;
      }
    }
  };
  const afterBottomSidebarBannerChangeHandler = (currentSlide) => {
    const activeSlide = carBottomSidebarBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomSiderbarBannerSliderCondition2 = "xyz";
      if (
        bottomSiderbarBannerSliderCondition === "abc" &&
        bottomSiderbarBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };
  const afterTopSidebarBannerChangeHandler = (currentSlide) => {
    const activeSlide = carTopSidebarBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      topSiderbarBannerSliderCondition2 = "xyz";
      if (
        topSiderbarSliderCondition === "abc" &&
        topSiderbarBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };
  // end setting

  const location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  const getFilters = (pageNumber, filters) => {
    filters.brand_id = localStorage.getItem("brand");
    filters.city_id = localStorage.getItem("city");
    // console.log(localStorage.getItem("cityName"));
    fetchCarItems(pageNumber, filters);
  };

  // Extract the filter values from the query parameters
  let brand_id = queryParams.get("brand");
  let city_id = queryParams.get("city");
  let isBrandName = queryParams.get("brand");
  let isCityName = queryParams.get("city");

  console.log(isBrandName, isCityName, "isCityName");

  useEffect(() => {
    if (isCityName !== null) {
    }
    if (isBrandName !== null) {
      setMakeFilter(isBrandName);
    }
  }, [isBrandName, isCityName]);

  let filters = {
    brand_id,
    city_id,
  };

  // console.log(company, "alclompany");

  // fetch compnay details
  const fetchAllComanyDetails = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-user-by-role?role_id=3", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCompany(data?.data);
      });
  };

  const fetchAllCitiesDetails = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-uae-cities", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCities(data?.data); //get data in data
        setCitiesDataa(data?.data); //get data in data
      });
  };

  const fetchAllBrands = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-brands", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setBrands(data); //get data in data
      });
  };
  // fetch item
  const fetchCarItems = async (pageNumber = 1, filters = {}) => {
    const url = new URL(
      // process.env.REACT_APP_URL + "/get-dealer-or-company-cars?role=3"
      process.env.REACT_APP_URL + "/get-user-by-role?role_id=3"
    );
    url.searchParams.append("page", pageNumber);

    let isBrandName = queryParams.get("brand");
    let isCityName = queryParams.get("city");
    console.log(isCityName !== null, isCityName, "isCityName");
    // if (isCityName !== null) {
    if (
      window?.location?.href.includes("city") &&
      localStorage?.getItem("city") !== null
    ) {
      url.searchParams.append("city_id", localStorage?.getItem("city"));
    }
    if (
      window?.location?.href.includes("brand") &&
      localStorage?.getItem("brand") !== null
    ) {
      // setMakeFilter(isBrandName);
      url.searchParams.append("brand_id", localStorage?.getItem("brand"));
    }

    // Add filter parameters to the request
    // Object.keys(filters).forEach((key) => {
    //   if (filters[key]) {
    //     url.searchParams.append(key, filters[key]);
    //   }
    // });

    await fetch(url, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // setCarItems(data.data); //get data in data
        // setCarItems(data.data);
        // console.log(data?.data?.data, "data");
        setCompany(data?.data);
        // console.log(data?.data, "dataaaaa");
        //get data in data
      });
  };
  const midBannerRef = useRef();
  const bottomBannerRef = useRef();
  const [favouriteIcon, setFavouriteIcon] = useState(false);
  const [bottomBannerSliderCondition, setBottomBannerSliderCondition] =
    useState(null);
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo")); //GET LOGIN INFO
  let token;
  let userId;
  if (loginInfo) {
    token = loginInfo["token"]; //GET TOKEN
    userId = loginInfo["user"]["id"];
  }

  const favouriteCarList = async (id) => {
    if (userId) {
      const data = new FormData();
      data.append("user_id", userId);
      data.append("subject_id", id?.id);
      await fetch(process.env.REACT_APP_URL + "/favorite", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          const updatedIcons = { ...favouriteIcons };
          if (data.message == "Added To Favourites") {
            // setFavouriteIcon(true);
            updatedIcons[id.id] = true;
            toast.success(data.message);
          } else {
            // setFavouriteIcon(false);
            delete updatedIcons[id.id];
            toast.success(data.message);
          }
          setFavouriteIcons(updatedIcons);
          // console.log(favouriteIcons);
        });
    } else {
      toast.error("Please Login");
      setTimeout(() => {
        window.location.href = "https://cars.me/web-app/";
      }, 1000);
    }
  };

  const checkFavoruiteCar = async (id) => {
    if (userId) {
      await fetch(
        process.env.REACT_APP_URL +
          `/check-favourite-car?user_id=${userId}&subject_id=${id}`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.data == "true") {
            setFavouriteIcon(true);
          } else {
            setFavouriteIcon(false);
          }
        });
    }
  };
  // apply filter
  const applyCityFilter = () => {
    setDropdown1(false);
    setCities(citiesDataa);
    console.log(selectedCity, "selectedCity");
    console.log(String(city?.name).trim(), "testing");
    // filters.city_id = String(city);
    filters.city_id = localStorage?.getItem("city");
    city_id = String(city);
    console.log(city?.name, "city?.name");
    console.log(filters, "filters");
    // localStorage.setItem("city", String(city?.name));
    localStorage.setItem("cityName", String(cityName));
    // queryParams.set("city", String(city));
    queryParams.append(
      "city",
      String(selectedCity)
        .replace(/\+/g, " ")
        .replace(/\s+/g, "")
        .toLowerCase()
        .trim()
    );
    // const currentCItyName = localStorage.getItem("cityName");
    // console.log(currentCItyName, "currentCItyName");

    // let newUrl;
    // if (testingUrl.includes("?")) {
    //   newUrl = `${window.location.href}&${queryParams.toString()}`;
    // } else {
    //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    // }
    let newUrl;
    const baseUrl = window.location.href.split("?")[0];

    if (testingUrl.includes("?")) {
      let existingParams = window.location.search.substr(1); // Get the existing query parameters
      const paramsArray = existingParams.split("&");
      const updatedParams = paramsArray.filter(
        (param) => !param.startsWith("city=")
      ); // Remove existing "brand" parameter

      if (baseUrl.includes("?")) {
        newUrl = `${baseUrl}?${updatedParams.join(
          "&"
        )}&${queryParams.toString()}`;
      } else {
        newUrl = `${baseUrl}?${updatedParams.join("&")}`;
        if (updatedParams.length > 0) {
          newUrl += "&";
        }
        newUrl += queryParams.toString();
      }
    } else {
      newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    }

    // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchCarItems(1, filters);
  };

  // apply brand filter
  const applyBrandFilter = (id, brandsName) => {
    setDropdown2(false);
    setSelectimg1(`${id}`);
    filters.brand_id = String(id);
    console.log(filters, " filters.brand_id ");
    brand_id = String(id);
    localStorage.setItem("brand", String(id));
    // queryParams.set("brand", String(id));
    queryParams.append(
      "brand",
      String(brandsName)
        .replace(/\+/g, " ")
        .replace(/\s+/g, "")
        .toLowerCase()
        .trim()
    );

    let newUrl;
    const baseUrl = window.location.href.split("?")[0];

    if (testingUrl.includes("?")) {
      let existingParams = window.location.search.substr(1); // Get the existing query parameters
      const paramsArray = existingParams.split("&");
      const updatedParams = paramsArray.filter(
        (param) => !param.startsWith("brand=")
      ); // Remove existing "brand" parameter

      if (baseUrl.includes("?")) {
        newUrl = `${baseUrl}?${updatedParams.join(
          "&"
        )}&${queryParams.toString()}`;
      } else {
        newUrl = `${baseUrl}?${updatedParams.join("&")}`;
        if (updatedParams.length > 0) {
          newUrl += "&";
        }
        newUrl += queryParams.toString();
      }
    } else {
      newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    }
    // let newUrl;
    // if (testingUrl.includes("?")) {
    //   const baseUrl = window.location.href.split("?")[0];
    //   if (baseUrl.includes("brand=")) {
    //     baseUrl = baseUrl.replace(/[\?&]brand=[^&]*(&|$)/, "?");
    //   }
    //   if (baseUrl.includes("?")) {
    //     newUrl = `${baseUrl}&${queryParams.toString()}`;
    //   } else {
    //     newUrl = `${baseUrl}?${queryParams.toString()}`;
    //   }
    // } else {
    //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    // }

    // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchCarItems(1, filters);
  };
  const observer3 = useRef(null);
  const observer4 = useRef(null);
  const dropdown1fun = () => {
    if (dropdown1) {
      setDropdown1(false);
      setCities(citiesDataa);
    } else {
      setDropdown1(true);
      setDropdown2(false);
    }
  };
  const dropdown2fun = () => {
    if (dropdown2) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
      setDropdown1(false);
    }
  };

  const dropdownclose = () => {
    setDropdown1(false);
    setDropdown2(false);
  };
  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      fetchAllCitiesDetails();
      fetchAllBrands();
      // fetchAllComanyDetails();
      fetchCarItems(pageNumber, filters);
    }, 5000);
    return () => {
      controller.abort();
    };
  }, []);

  // banner calls start

  const fetchCarBottomBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=company&position=Bottom",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarBottomBanner(data.data); //get data in data
      });
  };
  useEffect(() => {
    setTimeout(() => {
      const controller = new AbortController();
      // Fetch data based on the filter values
      console.log(localStorage?.getItem("cityName"), "cityName2outside");
      if (queryParams?.get("city") !== null) {
        console.log(localStorage?.getItem("cityName"), "cityName2inside");
        setCityName(localStorage?.getItem("cityName") ?? "All Cities");
      }
      fetchCarBottomBanner();
      fetchCarMidBanner();
      fetchCarTopSidebarBanner();
      fetchCarBottomSidebarBanner();
      fetchAllCitiesDetails();
      fetchAllBrands();
      // fetchCarItems(pageNumber, filters);
      observer.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-mid-banner-id]"
            );
            setMidBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-mid-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setMidBannerSliderCondition("");
          }
        });
      });
      observer2.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-bottom-banner-id]"
            );
            setBottomBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-bottom-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setBottomBannerSliderCondition("");
          }
        });
      });
      observer3.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-top=sidebar-banner-id]"
            );
            setTopSiderbarBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-top-sidebar-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setTopSiderbarBannerSliderCondition("");
          }
        });
      });
      observer4.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-bottom-sidebar-banner-id]"
            );
            setBottomSiderbarBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-bottom-sidebar-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setBottomSiderbarBannerSliderCondition("");
          }
        });
      });

      if (midBannerRef.current) {
        observer.current.observe(midBannerRef.current);
      }

      if (bottomBannerRef.current) {
        observer2.current.observe(bottomBannerRef.current);
      }

      if (topSidebarBannerRef.current) {
        observer.current.observe(topSidebarBannerRef.current);
      }

      if (bottomSiderbarBannerRef.current) {
        observer2.current.observe(bottomSiderbarBannerRef.current);
      }

      return () => {
        observer.current.disconnect();
        observer2.current.disconnect();
        observer3.current.disconnect();
        observer4.current.disconnect();
        controller.abort();
      };
    }, 5000);
  }, []);
  const [bannerId, setBannerId] = useState();

  const handlemidBannerRef = (e, id) => {
    setBannerId(id);
    setModalOpen(true);
  };
  const handlemidBannerRefClose = (e) => {
    // console.log(e);
    setModalOpen(false);
  };
  // const handlemidBannerRef = (e) => {
  //   // console.log(e);
  //   return (
  //     <>
  //       {/* <div data-toggle="modal">eqr</div> */}
  //       <BootstrapModals isOpen={modalOpen} onRequestClose={handleModalCLose} />;
  //     </>
  //   );
  // };

  // banner calls end
  const [name1, setName1] = useState();
  const [email1, setEmail1] = useState();
  const [message1, setMessage1] = useState();
  const [contant1, setContant1] = useState();

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // console.log(name1, email1, message1, contant1);

    if (
      name1 != "" &&
      name1 != undefined &&
      name1 != null &&
      email1 != "" &&
      email1 != undefined &&
      email1 != null &&
      message1 != "" &&
      message1 != undefined &&
      message1 != null &&
      contant1 != "" &&
      contant1 != undefined &&
      contant1 != null
    ) {
      // bannner data
      // IF INPUTS EMPTY OR NULL OR UNDEFINED
      const data = new FormData();
      data.append("name", name1);
      data.append("email", email1);
      data.append("message", message1);
      data.append("contact", contant1);
      data.append("banner_id", bannerId);
      fetch(process.env.REACT_APP_URL + "/create-banner-complaint", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);

          toast.success(data.message);
          setModalOpen(false);
          setName1();
          setEmail1();
          setMessage1();
          setContant1();
        });
      // .catch((e) => console.log(e));
    } else {
      toast.error("Invalid details");
    }
  };

  const checkFavouriteCar = async (item) => {
    if (userId) {
      await fetch(
        process.env.REACT_APP_URL +
          `/check-favourite-car?user_id=${userId}&subject_id=${item?.id}`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setFavouriteIcons((prevIcons) => {
            const updatedIcons = { ...prevIcons };
            if (data.data === "true") {
              updatedIcons[item.id] = true;
            } else {
              delete updatedIcons[item.id];
            }
            return updatedIcons;
          });
        });
    }
  };

  useEffect(() => {
    if (company) {
      company?.data.forEach((item) => {
        checkFavouriteCar(item);
      });
    }
  }, [company]);

  function containsNorNor(event) {
    const clickedElement = event.target;
    let currentElement = clickedElement;

    while (currentElement) {
      if (currentElement.classList.contains("dropdown2Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      if (currentElement.classList.contains("dropdown1Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      currentElement = currentElement.parentElement;
    }

    // console.log(
    //   "The element and its ancestors do not contain 'nor nor' in their class name."
    // );

    setDropdown2(false);
    setDropdown1(false);
  }

  useEffect(() => {
    // window.addEventListener("click", handleUserKeyPress);
    window.addEventListener("click", containsNorNor);
    return () => {
      window.removeEventListener("click", containsNorNor);
    };
  });

  return (
    <>
      <Helmet title="Company" />
      <BootstrapModals
        isOpen={modalOpen}
        onRequestClose={handlemidBannerRefClose}
        name={name1}
        setName={setName1}
        email={email1}
        setEmail={setEmail1}
        message={message1}
        setMessage={setMessage1}
        contact={contant1}
        setContact={setContant1}
        handleSubmit={handleSubmit1}
      />
      <div style={{ position: "relative" }}>
        <CommonSection
          title="Car Listing"
          slug="company"
          handlemidBannerRef={handlemidBannerRef}
          bannerIDD
        />
      </div>
      <Container className="responsivecontainer">
        <Row>
          <Col lg="12" className="main-colom-css-scroll">
            <div className=" d-flex align-items-center gap-3 mt-5 mb-5 mainfilter-div">
              <div
                onClick={dropdown1fun}
                className="filter-sub-box dropdown1Closee"
                style={{ flex: 1 }}
              >
                <span className="sub-div-span1">City</span>
                <span
                  style={{
                    fontSize: "15px",
                    // width: "150px",
                    width: "auto",
                    overflow: "hidden",
                  }}
                >
                  {cityName}
                  {/* {selectedCity} */}
                </span>
                <i className="ri-arrow-down-s-line sub-div-icon"></i>
              </div>
              {dropdown1 && (
                <>
                  <div onClick={dropdownclose} className="box-div"></div>

                  <div
                    className="div-drop-down-box dropdown1Closee"
                    style={{ maxWidth: "none", width: "40%" }}
                  >
                    <div className="custom-search-car">
                      <input
                        type="text"
                        placeholder="Search"
                        style={{ width: "95%" }}
                        onChange={(event) => {
                          const searchString = event.target.value.toLowerCase();
                          const filteredCities = citiesDataa?.filter((item) =>
                            item?.name.toLowerCase().includes(searchString)
                          );
                          setCities(filteredCities);
                        }}
                      />
                    </div>
                    <div className="drop-down-box-1">
                      {cities?.map && (
                        <button
                          onClick={() => {
                            setDropdown1(false);
                            setCityName("All Cities");
                            localStorage.removeItem("city");
                            localStorage.removeItem("cityName");

                            const queryParams = new URLSearchParams(
                              window.location.search
                            );

                            // Remove the "fromYear" query parameter
                            queryParams.delete("city");

                            // Reconstruct the URL with the updated query parameters
                            const newUrl = `${
                              window.location.pathname
                            }?${queryParams.toString()}`;
                            window.history.pushState(
                              { path: newUrl },
                              "",
                              newUrl
                            );

                            // const newUrl = `${window.location.pathname}`;
                            // window.history.pushState(
                            //   { path: newUrl },
                            //   "",
                            //   newUrl
                            // );
                            fetchCarItems(1);
                          }}
                          style={{
                            all: "reset",
                            display: "inline-block",
                            padding: "4px 12px",
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            transition: "0.15s all ease-in-out",
                            background: "white",
                            border: "solid 1px rgb(224, 225, 227)",
                            padding: "10px 16px",
                            fontSize: "13px",
                            borderRadius: "50px",
                            width: "100%",
                          }}
                        >
                          All Cities
                        </button>
                      )}
                      {!cities && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "138px",
                          }}
                        >
                          <div class="spinner-border" role="status">
                            {/* <span class="sr-only">Loading...</span> */}
                          </div>
                        </div>
                      )}
                      {cities?.map((city, i) => {
                        return (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCityName(city?.name);
                              setcity(city?.id);
                              setSelectedCity(city?.name);
                              localStorage.setItem("city", String(city?.id));
                              // console.log(city?.id,"city?.id");
                            }}
                            key={i}
                            value={city?.id}
                          >
                            {city?.name}
                          </span>
                        );
                      })}
                    </div>
                    <div className="footer-box-1-droup-down">
                      <button onClick={applyCityFilter}>Apply Filters</button>
                    </div>
                  </div>
                </>
              )}

              <div
                onClick={dropdown2fun}
                className="filter-sub-box dropdown2Closee"
                style={{ flex: 1, borderRight: "none" }}
              >
                <span className="sub-div-span1">Make</span>
                <span style={{ fontSize: "15px" }}>{makeFilter}</span>
                <i className="ri-arrow-down-s-line sub-div-icon"></i>
              </div>
              {dropdown2 && (
                <>
                  <div onClick={dropdownclose} className="box-div"></div>{" "}
                  <div
                    className="div-drop-down-box1 div-drop-down-box01 dropdown2Closee"
                    style={{
                      maxWidth: "none",
                      width: "41%",
                      marginLeft: "535px",
                      zIndex: "1",
                    }}
                  >
                    <ul style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      {brands && (
                        <>
                          <li
                            onClick={() => {
                              // applyBrandFilter(brand?.id);
                              // setMake(brand?.name);
                              setMakeFilter("Select");
                              setDropdown2(false);
                              setSelectimg1("");
                              localStorage.removeItem("brand");

                              const queryParams = new URLSearchParams(
                                window.location.search
                              );

                              // Remove the "fromYear" query parameter
                              queryParams.delete("brand");

                              // Reconstruct the URL with the updated query parameters
                              const newUrl = `${
                                window.location.pathname
                              }?${queryParams.toString()}`;
                              window.history.pushState(
                                { path: newUrl },
                                "",
                                newUrl
                              );

                              // const newUrl = `${window.location.pathname}`;
                              // window.history.pushState(
                              //   { path: newUrl },
                              //   "",
                              //   newUrl
                              // );
                              fetchCarItems(1);
                            }}
                            className="listli-dropdown"
                          >
                            <span>Reset All</span>
                            {/* {selectimg1 !== brand?.id && (
                                <img className="checkimg" src={check} />
                              )} */}
                          </li>
                        </>
                      )}
                      {!brands && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "300px",
                          }}
                        >
                          <div class="spinner-border" role="status">
                            {/* <span class="sr-only">Loading...</span> */}
                          </div>
                        </div>
                      )}
                      {brands?.map((brand, i) => {
                        return (
                          <li
                            key={i}
                            onClick={() => {
                              applyBrandFilter(brand?.id, brand?.name);
                              setMakeFilter(brand?.name);
                              // console.log(brand?.name, "brand?.name");
                              setSelectedBrand(brand?.name);
                            }}
                            className="listli-dropdown"
                          >
                            <span>{brand?.name}</span>
                            {selectimg1 == brand?.id && (
                              <img className="checkimg" src={check} />
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8" md="12" sm="12">
            {company?.data?.length === 0 && <h2>No Company Found</h2>}
            {company?.data ? (
              company?.data?.slice(0, 4)?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="container bcontent dealer-card-wrapper res-dealer-trans">
                      <div className="card-feature-img">
                        <div className="row no-gutters">
                          <div
                            className="col-sm-5 testingforImage"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="card-img"
                              style={{ objectFit: "contain" }}
                              // src={item?.featured_image}
                              src={
                                item?.featured_image
                                  ? item?.featured_image
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                              }
                              alt={item?.name}
                              onClick={() => {
                                navigate(`${item?.slug}`);
                                localStorage.setItem(
                                  "companyID",
                                  `${item?.id}`
                                );
                              }}
                            />
                            <img
                              style={{
                                width: "20px",
                                position: "absolute",
                                right: "20px",
                                top: "15px",
                                cursor: "pointer",
                              }}
                              // onClick={() => favouriteCarList(item?.id)}
                              // src={favouriteIcon ? favicon1 : favicon}
                              onClick={() => favouriteCarList(item)}
                              src={
                                favouriteIcons[item?.id] ? favicon1 : favicon
                              }
                            />
                          </div>

                          <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* {item.brand} */}
                                {item?.name}
                              </p>
                              <p
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {" "}
                                <FaLocationArrow
                                  size={11}
                                  className="d-inline-block mr-2"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "6px",
                                  }}
                                />
                                {/* {item.address} */}
                                {item?.address
                                  ? item?.address
                                  : "Address is not available"}
                              </p>
                              <p
                                // className="last-time-updated"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                className="company-description"
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* Updated {item.time_ago} */}
                                {item?.description
                                  ? item?.description
                                  : "Description is not available"}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "6px" }}
                                  // style={{
                                  //   display: "inline-block",
                                  //   marginRight: "6px",
                                  // }}
                                />
                                {/* {item.contact} */}
                                {/* {item.phone_no} */}
                                {item.phone_no
                                  ? item.phone_no
                                  : "Not available"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <>
                {Array.from(new Array(4)).map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="container bcontent dealer-card-wrapper res-dealer-trans">
                        <div className="card-feature-img">
                          <div className="row no-gutters">
                            <div
                              className="col-sm-5 placeholder-glow"
                              style={{ position: "relative" }}
                            >
                              <svg
                                className="bd-placeholder-img card-img-top placeholder"
                                width="100%"
                                height="180"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-label="Placeholder"
                                preserveAspectRatio="xMidYMid slice"
                                focusable="false"
                              >
                                <title>Placeholder</title>
                                <rect
                                  width="100%"
                                  height="100%"
                                  fill="#2e3192"
                                ></rect>
                              </svg>
                            </div>

                            <div className="col-sm-7 mt-2">
                              <div className="dealer-card-body">
                                <p
                                  className="card-title card-title placeholder-glow"
                                  style={{ margin: "0 0 5px" }}
                                >
                                  <span
                                    className="placeholder col-9"
                                    // style={{
                                    //   backgroundColor:
                                    //     "linear-gradient(45deg, #27aae1, #2e3192)",
                                    // }}
                                    style={{
                                      backgroundColor: "#2e3192",
                                    }}
                                  ></span>
                                </p>
                                <p
                                  style={{ margin: "0 0 5px" }}
                                  className="placeholder-glow"
                                >
                                  <span
                                    className="placeholder col-9"
                                    style={{
                                      backgroundColor: "#2e3192",
                                    }}
                                  ></span>
                                </p>
                                <p
                                  style={{ margin: "0 0 5px" }}
                                  className="company-description placeholder-glow"
                                >
                                  <span
                                    className="placeholder col-9"
                                    style={{
                                      backgroundColor: "#2e3192",
                                    }}
                                  ></span>
                                </p>
                                <p
                                  className="contact-no placeholder-glow"
                                  style={{ margin: "0 0 5px" }}
                                >
                                  <span
                                    className="placeholder col-9"
                                    style={{
                                      backgroundColor: "#2e3192",
                                    }}
                                  ></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </>
            )}
            <div
              ref={midBannerRef}
              className="mt-4"
              style={{ position: "relative" }}
            >
              <Slider {...settings} afterChange={afterMidBannerChangeHandler}>
                {carMidBanner?.data?.length == 0 && (
                  <>
                    <img className="banner-btm" src={banner2} />
                  </>
                )}
                {carMidBanner?.data?.length > 0 &&
                  carMidBanner?.data?.map((midBanner, index) => {
                    return (
                      <React.Fragment key={index}>
                        {midBanner?.image && (
                          <>
                            <img
                              src={midBanner?.image}
                              key={index}
                              className="banner-btm"
                              onClick={() =>
                                openBannerLinkPage(
                                  midBanner?.link,
                                  midBanner?.target,
                                  midBanner?.id,
                                  "Yes",
                                  "No"
                                )
                              }
                              data-mid-banner-id={midBanner?.id}
                            />
                            <img
                              src={Info}
                              alt=""
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                backgroundColor: "#fff",
                                borderRadius: "16px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                handlemidBannerRef(e, midBanner?.id)
                              }
                            />
                          </>
                        )}

                        {midBanner?.script && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: midBanner.script,
                            }}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    );
                    // }
                  })}
              </Slider>
            </div>
            {/* {console.log(company?.data, "company?.data")} */}
            {company?.data &&
              company?.data?.slice(4, 7)?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {/* <Link
                to={`${item?.slug}`}
                style={{ color: "inherit", textDecoration: "inherit" }}
                key={i}
                onClick={() => {
                  localStorage.setItem("companyID", `${item?.user_id}`);
                }}
              > */}
                    {/* <div style={{ position: "relative" }} className="imageBox">
                <img
                  style={{
                    width: "20px",
                    position: "absolute",
                    right: "10px",
                    top: "9px",
                    cursor: "pointer",
                  }}
                  onClick={() => favouriteCarList(item?.id)}
                  src={favouriteIcon ? favicon1 : favicon}
                />
              </div> */}

                    <div className="container bcontent dealer-card-wrapper res-dealer-trans">
                      <div className="card-feature-img">
                        <div className="row no-gutters">
                          <div
                            className="col-sm-5 testingforImage"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="card-img"
                              style={{ objectFit: "contain" }}
                              src={
                                item?.featured_image
                                  ? item?.featured_image
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                              }
                              // src={item?.featured_image}/
                              alt={item?.name}
                              onClick={() => {
                                navigate(`${item?.slug}`);
                                localStorage.setItem(
                                  "companyID",
                                  `${item?.id}`
                                );
                              }}
                            />
                            <img
                              style={{
                                width: "20px",
                                position: "absolute",
                                right: "20px",
                                top: "15px",
                                cursor: "pointer",
                              }}
                              // onClick={() => favouriteCarList(item?.id)}
                              // src={favouriteIcon ? favicon1 : favicon}
                              onClick={() => favouriteCarList(item)}
                              src={
                                favouriteIcons[item?.id] ? favicon1 : favicon
                              }
                            />
                          </div>
                          <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* {item.brand} */}
                                {item?.name}
                              </p>
                              <p
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {" "}
                                <FaLocationArrow
                                  size={11}
                                  className="d-inline-block mr-2"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "6px",
                                  }}
                                />
                                {/* {item.address} */}
                                {item?.address
                                  ? item?.address
                                  : "Address is not available"}
                              </p>
                              <p
                                // className="last-time-updated"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                className="company-description"
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* Updated {item.time_ago} */}
                                {item?.description
                                  ? item?.description
                                  : "Description is not available"}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "6px" }}
                                  // style={{
                                  //   display: "inline-block",
                                  //   marginRight: "6px",
                                  // }}
                                />
                                {/* {item.contact} */}
                                {/* {item.phone_no} */}
                                {item.phone_no
                                  ? item.phone_no
                                  : "Not available"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </React.Fragment>
                );
              })}

            <div
              ref={bottomBannerRef}
              className="mt-4"
              style={{ position: "relative" }}
            >
              <Slider
                {...settings}
                afterChange={afterBottomBannerChangeHandler}
              >
                {carBottomBanner?.data?.length == 0 && (
                  <img className="banner-btm" src={banner1} />
                )}
                {carBottomBanner?.data?.length > 0 &&
                  carBottomBanner?.data?.map((bottomBanner, index) => {
                    return (
                      <React.Fragment key={index}>
                        {bottomBanner.length}
                        {bottomBanner?.image && (
                          <>
                            <img
                              src={bottomBanner?.image}
                              key={index}
                              className="banner-btm"
                              onClick={() =>
                                openBannerLinkPage(
                                  bottomBanner?.link,
                                  bottomBanner?.target,
                                  bottomBanner?.id,
                                  "Yes",
                                  "No"
                                )
                              }
                              data-bottom-banner-id={bottomBanner?.id}
                            />
                            <img
                              src={Info}
                              alt=""
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                backgroundColor: "#fff",
                                borderRadius: "16px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                handlemidBannerRef(e, bottomBanner?.id)
                              }
                            />
                          </>
                        )}
                        {bottomBanner?.script && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: bottomBanner.script,
                            }}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    );
                    // }
                  })}
              </Slider>
            </div>
            {company?.data &&
              company?.data?.slice(7, 10)?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="container bcontent dealer-card-wrapper res-dealer-trans">
                      <div className="card-feature-img">
                        <div className="row no-gutters">
                          <div
                            className="col-sm-5 testingforImage"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="card-img"
                              // src={item?.featured_image}
                              style={{ objectFit: "contain" }}
                              src={
                                item?.featured_image
                                  ? item?.featured_image
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                              }
                              alt={item?.name}
                              onClick={() => {
                                navigate(`${item?.slug}`);
                                localStorage.setItem(
                                  "companyID",
                                  `${item?.id}`
                                );
                              }}
                            />
                            <img
                              style={{
                                width: "20px",
                                position: "absolute",
                                right: "20px",
                                top: "15px",
                                cursor: "pointer",
                              }}
                              // onClick={() => favouriteCarList(item?.id)}
                              // src={favouriteIcon ? favicon1 : favicon}
                              onClick={() => favouriteCarList(item)}
                              src={
                                favouriteIcons[item?.id] ? favicon1 : favicon
                              }
                            />
                          </div>

                          <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* {item.brand} */}
                                {item?.name}
                              </p>
                              <p
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {" "}
                                <FaLocationArrow
                                  size={11}
                                  className="d-inline-block mr-2"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "6px",
                                  }}
                                />
                                {/* {item.address} */}
                                {item?.address
                                  ? item?.address
                                  : "Address is not available"}
                              </p>
                              <p
                                // className="last-time-updated"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                className="company-description"
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* Updated {item.time_ago} */}
                                {item?.description
                                  ? item?.description
                                  : "Description is not available"}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "6px" }}
                                  // style={{
                                  //   display: "inline-block",
                                  //   marginRight: "6px",
                                  // }}
                                />
                                {/* {item.contact} */}
                                {/* {item.phone_no} */}
                                {item.phone_no
                                  ? item.phone_no
                                  : "Not available"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}

            <div className="container mt-4">
              <Pagination
                activePage={company?.current_page || 1}
                totalItemsCount={company?.total ?? 0}
                itemsCountPerPage={parseInt(company?.per_page)}
                onChange={(pageNumber) => getFilters(pageNumber, filters)}
                pageRangeDisplayed={5}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First"
                lastPageText="Last"
                prevPageText="Previous"
                nextPageText="Next"
                activeClass="active"
                activeLinkClass="active-link"
              />
            </div>
          </Col>
          <Col id="colom-4" lg="4" md="0" sm="0">
            <div ref={topSidebarBannerRef} style={{ position: "relative" }}>
              <Slider
                style={{ zIndex: "-1" }}
                {...settings}
                afterChange={afterTopSidebarBannerChangeHandler}
              >
                {carTopSidebarBanner?.data?.length == 0 && (
                  <img className="sidebanner-slider-img" src={banner3} />
                )}

                {carTopSidebarBanner?.data == undefined && (
                  // <img className="sidebanner-slider-img" src={banner3} />
                  <>
                    <div
                      // className="slider-car-responsive col-sm-12 placeholder-glow sidebanner-slider-img"
                      className="col-sm-12 placeholder-glow sidebanner-slider-img"
                      style={{
                        position: "relative",
                        // width: "730px",
                        // height: "570px",
                      }}
                    >
                      <svg
                        className="bd-placeholder-img card-img-top placeholder"
                        width="100%"
                        height="1370"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        aria-label="Placeholder"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                      >
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#2e3192"></rect>
                      </svg>
                    </div>
                  </>
                )}

                {carTopSidebarBanner?.data?.length > 0 &&
                  carTopSidebarBanner?.data?.map((topSidebarBanner, index) => {
                    return (
                      <React.Fragment key={index}>
                        {topSidebarBanner.length}
                        {topSidebarBanner?.image && (
                          <>
                            <div
                              style={{
                                position: "relative",
                                margin: "40px auto 0 43px",
                                width: "270px",
                              }}
                            >
                              <img
                                src={topSidebarBanner?.image}
                                key={index}
                                className="sidebanner-slider-img"
                                onClick={() =>
                                  openBannerLinkPage(
                                    topSidebarBanner?.link,
                                    topSidebarBanner?.target,
                                    topSidebarBanner?.id,
                                    "Yes",
                                    "No"
                                  )
                                }
                                data-top-sidebar-banner-id={
                                  topSidebarBanner?.id
                                }
                              />
                              <img
                                src={Info}
                                alt=""
                                style={{
                                  // position: "absolute",
                                  // top: 53,
                                  // right: 115,
                                  // backgroundColor: "#fff",
                                  // borderRadius: "16px",
                                  // cursor: "pointer",
                                  position: "absolute",
                                  top: "15px",
                                  right: "15px",
                                  cursor: "pointer",
                                  backgroundColor: "rgb(255, 255, 255)",
                                  borderRadius: "16px",
                                }}
                                onClick={(e) =>
                                  handlemidBannerRef(e, topSidebarBanner?.id)
                                }
                              />
                            </div>
                          </>
                        )}
                        {topSidebarBanner?.script && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: topSidebarBanner.script,
                            }}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    );
                    // }
                  })}
              </Slider>
            </div>
            {/* <div ref={bottomSiderbarBannerRef}>
              <Slider
                {...settings}
                afterChange={afterBottomSidebarBannerChangeHandler}
              >
                {carBottomSidebarBanner?.data?.length == 0 && (
                  <img className="sidebanner-slider-img" src={banner4} />
                )}

                {carBottomSidebarBanner?.data?.length > 0 &&
                  carBottomSidebarBanner?.data?.map(
                    (bottomSidebarBanner, index) => {
                      return (
                        <>
                          {bottomSidebarBanner.length}
                          {bottomSidebarBanner?.image && (
                            <img
                              src={bottomSidebarBanner?.image}
                              key={index}
                              className="sidebanner-slider-img"
                              onClick={() =>
                                openBannerLinkPage(
                                  bottomSidebarBanner?.link,
                                  bottomSidebarBanner?.target,
                                  bottomSidebarBanner?.id,
                                  "Yes",
                                  "No"
                                )
                              }
                              data-bottom-sidebar-banner-id={
                                bottomSidebarBanner?.id
                              }
                            />
                          )}
                          {bottomSidebarBanner?.script && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: bottomSidebarBanner.script,
                              }}
                              key={index}
                            />
                          )}
                        </>
                      );
                      // }
                    }
                  )}
              </Slider>
            </div> */}
            <div ref={bottomSiderbarBannerRef} style={{ position: "relative" }}>
              <Slider
                {...settings}
                afterChange={afterBottomSidebarBannerChangeHandler}
              >
                {carBottomSidebarBanner?.data?.length == 0 && (
                  <img className="sidebanner-slider-img" src={banner4} />
                )}

                {carBottomSidebarBanner?.data == undefined && (
                  <>
                    <div
                      // className="slider-car-responsive col-sm-12 placeholder-glow sidebanner-slider-img"
                      className="col-sm-12 placeholder-glow sidebanner-slider-img mt-5"
                      style={{
                        position: "relative",
                        // width: "730px",
                        // height: "570px",
                      }}
                    >
                      <svg
                        className="bd-placeholder-img card-img-top placeholder"
                        width="100%"
                        height="1370"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        aria-label="Placeholder"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                      >
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#2e3192"></rect>
                      </svg>
                    </div>
                  </>
                )}

                {carBottomSidebarBanner?.data?.length > 0 &&
                  carBottomSidebarBanner?.data?.map(
                    (bottomSidebarBanner, index) => {
                      return (
                        <React.Fragment key={index}>
                          {bottomSidebarBanner.length}
                          {bottomSidebarBanner?.image && (
                            <>
                              <div
                                style={{
                                  position: "relative",
                                  margin: "40px auto 0 43px",
                                  width: "270px",
                                }}
                              >
                                <img
                                  src={bottomSidebarBanner?.image}
                                  key={index}
                                  className="sidebanner-slider-img"
                                  onClick={() =>
                                    openBannerLinkPage(
                                      bottomSidebarBanner?.link,
                                      bottomSidebarBanner?.target,
                                      bottomSidebarBanner?.id,
                                      "Yes",
                                      "No"
                                    )
                                  }
                                  data-bottom-sidebar-banner-id={
                                    bottomSidebarBanner?.id
                                  }
                                />

                                <img
                                  src={Info}
                                  alt=""
                                  style={{
                                    // position: "absolute",
                                    // top: 50,
                                    // // right: 53,
                                    // right: 115,
                                    // cursor: "pointer",
                                    // backgroundColor: "#fff",
                                    // borderRadius: "16px",
                                    position: "absolute",
                                    top: "15px",
                                    right: "15px",
                                    cursor: "pointer",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    borderRadius: "16px",
                                  }}
                                  onClick={(e) =>
                                    handlemidBannerRef(
                                      e,
                                      bottomSidebarBanner?.id
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}
                          {bottomSidebarBanner?.script && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: bottomSidebarBanner.script,
                              }}
                              key={index}
                            />
                          )}
                        </React.Fragment>
                      );
                      // }
                    }
                  )}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="container mt-4">
        {/* <Pagination
          activePage={company?.current_page || 1}
          totalItemsCount={company?.total ?? 0}
          itemsCountPerPage={parseInt(company?.per_page)}
          onChange={(pageNumber) => getFilters(pageNumber, filters)}
          pageRangeDisplayed={5}
          itemClass="page-item"
          linkClass="page-link"
          firstPageText="First"
          lastPageText="Last"
          prevPageText="Previous"
          nextPageText="Next"
          activeClass="active"
          activeLinkClass="active-link"
        /> */}
      </div>
    </>
  );
};

export default Dealer;
