import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Formik, useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";

const ModalFormSchema = Yup.object({
  // nameregix: Yup.string().required("Name is required"),
  name: Yup.string()
    .min(3, "Too short!")
    .max(30, "Too long!")
    .trim()
    .matches(/^[A-Za-z\s]*$/, "name must contain only letters")
    .required("name is required"),
  email: Yup.string().email("Invalid email").required("email is required"),
  message: Yup.string().required("message is required"),
  number: Yup.string()
    .min(11, "number must be at least 11 characters")
    .max(13, "number can be up to 13 characters")
    .required("number is required"),
});
var eventgeter;  
const BootstrapModal = ({
  isOpen,
  onRequestClose,
  name,
  setName,
  message,
  setMessage,
  email,
  setEmail,
  contact,
  setContact,
  handleSubmit,
  title,
  children,
}) => {
  // console.log(isOpen, "isOpen");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "320px",
      display: isOpen ? "block" : "none",
    },
  };

  const eventget = (e)=>{
    eventgeter = e;
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      number: "",
    },
    validationSchema: ModalFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      // seterror("");
      // fetchUserDetail(values);
      if (formik.isValid) {
        handleSubmit(eventgeter);
      }
      console.log(formik);
      console.log(formik.errors);
      setSubmitting(false);
      // dispatch(setLoading(true));
    },
  });
  // console.log(isOpen, "formik.values");
  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      {/* Modal content */}
      <div class="modal-header">
        <h6 class="modal-title">Complain</h6>
        <button
          type="button"
          class="close border-0 bg-transparent h-30"
          data-dismiss="modal"
          onClick={onRequestClose}
          style={{ fontSize: "22px" }}
        >
          &times;
        </button>
      </div>
      <Formik>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ position: "relative" }}>
            <div class="form-group mb-3">
              <label for="exampleFormControlInput1">Name</label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your name"
                name="name"
                value={name}
                onBlur={formik.handleBlur}

                // onChange={(e) => setName(e.target.value)}
                onChange={(e) => {
                  setName(e.target.value);
                  formik.setFieldValue("name", e.target.value);
                }}
              />
            </div>
            {/* {console.log(formik?.errors?.name, "formik?.errors?.name")} */}
            {Boolean(formik?.errors?.name) ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "-18px",
                  left: "1px",
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {formik?.errors.name}
              </div>
            ) : null}
          </div>

          <div style={{ position: "relative" }}>
            <div class="form-group mb-3">
              <label for="exampleFormControlInput1">Email </label>
              <input
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
                name="email"
                placeholder="name@example.com"
                onBlur={formik.handleBlur}
                value={email}
                // onChange={(e) => setEmail(e.target.value)}
                onChange={(e) => {
                  setEmail(e.target.value);
                  formik.setFieldValue("email", e.target.value);
                }}
                
              />
            </div>
            { Boolean(formik?.errors?.email) ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "-18px",
                  left: "1px",
                  fontSize: "12px",
                  color: "red",
                }}
              >
                { formik?.errors.email}
              </div>
            ) : null}
          </div>
          <div style={{ position: "relative" }}>
            <div class="form-group mb-3">
              <label for="exampleFormControlTextarea1">Message</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                name="message"
                rows="3"
                onBlur={formik.handleBlur}
                value={message}
                // onChange={(e) => setMessage(e.target.value)}
                onChange={(e) => {
                  setMessage(e.target.value);
                  formik.setFieldValue("message", e.target.value);
                }}
                
              ></textarea>
            </div>
            { Boolean(formik?.errors?.message) ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "-18px",
                  left: "1px",
                  fontSize: "12px",
                  color: "red",
                }}
              >
                { formik?.errors.message}
              </div>
            ) : null}
          </div>
          <div style={{ position: "relative" }}>
            <div class="form-group mb-3">
              <label for="exampleFormControlInput1">Contact</label>
              <input
                name="number"
                type="number"
                class="form-control"
                id="exampleFormControlInput1"
                onBlur={formik.handleBlur}
                placeholder="Enter Contact"
                value={contact}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                // onChange={(e) => setContact(e.target.value)}
                onChange={(e) => {
                  setContact(e.target.value);
                  formik.setFieldValue("number", e.target.value);
                }}
                
              />
            </div>
            { Boolean(formik?.errors?.number) ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "-18px",
                  left: "1px",
                  fontSize: "12px",
                  color: "red",
                }}
              >
                { formik?.errors.number}
              </div>
            ) : null}
          </div>
          <button
            type="submit"
            onClick={(e)=>eventget(e)}
            // disabled={!formik.isValid || !formik.dirty}
            class="w-100 btn btn-primary mt-1"
          >
            Submit
          </button>
          {(!formik.isValid || !formik.dirty) && (
            <p style={{    marginTop: "12px",
              fontSize: "14px",
              textAlign: "center",}}>Kindly fill all the fields above</p>
          )}
        </form>
      </Formik>
    </Modal>
  );
};

BootstrapModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default BootstrapModal;
