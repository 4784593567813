import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import "../../styles/blog-item.css";
import { Link } from "react-router-dom";
import blogData from "../../assets/data/blogData";
import Pagination from "react-js-pagination";

const BlogList = () => {
  const [blogs, setBlogs] = useState();

  useEffect(() => {
    fetchBlogDetails();
  }, []);

  const fetchBlogDetails = async (pageNumber = 1) => {
    await fetch(
      process.env.REACT_APP_URL + "/get-front-blogs?page=" + pageNumber,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data.data);
      });
  };
  return (
    <>
      {/* {blogs?.data ? {blogs?.data?.map((item) => (
        <>
          <BlogItem blog={item} key={item.id} />
        </>
      ))} : ""} */}
      {blogs?.data ? (
        blogs?.data?.map((item, index) => (
          <React.Fragment key={index}>
            <BlogItem blog={item} />
          </React.Fragment>
        ))
      ) : (
        <>
          {Array.from(new Array(6)).map((_, index) => {
            return (
              <Col lg="4" md="6" sm="6" className="mb-5" key={index}>
                <div className="blog__item placeholder-glow">
                  {/* <img
                    src={blog.thumbnail}
                    alt=""
                    className="w-100"
                    style={{ height: "250px", objectFit: "contain" }}
                  /> */}
                  <svg
                    className="bd-placeholder-img card-img-top placeholder"
                    width="100%"
                    height="180"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    aria-label="Placeholder"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                  >
                    <title>Placeholder</title>
                    <rect width="100%" height="100%" fill="#afafaf"></rect>
                  </svg>
                  <div className="blog__info p-3 ">
                    <Link className="blog__title placeholder">
                      {/* {blog.name} */}
                    </Link>
                    <p className="section__description mt-3 placeholder w-50">
                      {/* {blog.description.length > 100
                        ? blog.description.substr(0, 100)
                        : blog.description} */}
                      <span className="blog__author placeholder">
                        <i className="ri-user-line placeholder"></i>{" "}
                        {/* {blog.category} */}
                      </span>
                    </p>

                    {/* <Link className="read__more placeholder w-50">
                  
                      <span className="blog__author ">
                        <i className="ri-user-line "></i>{" "}
              
                      </span>
                    </Link> */}

                    <div className="blog__time pt-3 mt-3 d-flex align-items-center justify-content-between">
                      <span className="blog__author placeholder w-25">
                        <i className="ri-user-line placeholder"></i>{" "}
                        {/* {blog.category} */}
                      </span>

                      <div className=" d-flex align-items-center gap-3 placeholder w-25">
                        <span className=" d-flex align-items-center gap-1 section__description placeholder">
                          <i className="ri-calendar-line placeholder"></i>
                          {/* {blog.created_at} */}
                        </span>

                        <span className=" d-flex align-items-center gap-1 section__description placeholder placeholder w-25">
                          <i className="ri-time-line placeholder"></i>
                          {/* {blog.time} */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </>
      )}
      <Pagination
        activePage={blogs?.current_page}
        totalItemsCount={blogs?.total ?? 0}
        itemsCountPerPage={parseInt(blogs?.per_page)}
        onChange={(pageNumber) => fetchBlogDetails(pageNumber)}
        itemClass="page-item"
        linkClass="page-link"
        firstPageText="First"
        lastPageText="Last"
      />
    </>
  );
};

const BlogItem = ({ blog }) => {
  return (
    <Col lg="4" md="6" sm="6" className="mb-5">
      <div className="blog__item">
        <div className="blogcard-mobile">
          <img src={blog.thumbnail} alt="" />
        </div>

        <div className="blog__info p-3">
          <Link to={`/blogs/${blog.slug}`} className="blog__title">
            {blog.name}
          </Link>
          <p className="section__description mt-3">
            {blog.description.length > 100
              ? blog.description.substr(0, 100)
              : blog.description}
          </p>

          <Link to={`/blogs/${blog.slug}`} className="read__more">
            Read More
          </Link>

          <div className="blog__time pt-3 mt-3 d-flex align-items-center justify-content-between">
            <span className="blog__author">
              <i className="ri-user-line"></i> {blog.category}
            </span>

            <div className=" d-flex align-items-center gap-3">
              <span className=" d-flex align-items-center gap-1 section__description">
                <i className="ri-calendar-line"></i> {blog.created_at}
              </span>

              <span className=" d-flex align-items-center gap-1 section__description">
                <i className="ri-time-line"></i> {blog.time}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default BlogList;
