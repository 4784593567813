import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/auto-store-car-parts-accessories.css";
// import MitsubishiIcon from "../../assets/all-images/mitsubishi-icon.png";
import NitcoIcon from "../../assets/all-images/make/1.jpg";
import MegurisIcon from "../../assets/all-images/make/580b57fcd9996e24bc43c498.png";
import TyroIcon from "../../assets/all-images/make/daihatsu.png";
import AromaIcon from "../../assets/all-images/make/Honda.png";
import AreonIcon from "../../assets/all-images/make/hyundai.png";
import BullsoneIcon from "../../assets/all-images/make/KIA.png";
import StringioIcon from "../../assets/all-images/make/Mitsubishi.png";
import ArmerIcon from "../../assets/all-images/make/Suzuki.png";
import VicIcon from "../../assets/all-images/make/Tyota.png";
import TurtleIcon from "../../assets/all-images/make/Audi.png";
const AutoStoreCarPartsAccessories = () => {
  const [brands, setBrands] = useState();
  const [models, setModels] = useState();
  const [activetag, setActivetag] = useState("Make");
  useEffect(() => {
    fetchAllBrands();
    fetchAllModels();
  }, []);
  const fetchAllBrands = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-front-brands", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setBrands(data.data);
      });
  };
  const fetchAllModels = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-front-models", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setModels(data.data);
      });
  };
  return (
    <>
      <div
        id="auto-store"
        className="auto-store row auto-store"
        // style={{ minHeight: activetag === "Make" ? "auto" : "" }}
        style={{ minHeight: "auto" }}
      >
        {/* <div
        id="auto-store"
        // className={`row ${activetag === "Make" ? "" : "auto-store"}`}
        style={}
      > */}
        <div className="col-10 offset-1">
          <ul className="nav nav-tabs row gy-4 d-flex" role="tablist">
            <li className="nav-item col" role="presentation">
              <a
                className={
                  activetag == "Make" ? "nav-link show active" : "nav-link"
                }
                data-bs-toggle="tab"
                data-bs-target="#tab-41"
                aria-selected="true"
                role="tab"
                onClick={() => setActivetag("Make")}
              >
                <h4 className="textNoWrap">Make</h4>
              </a>
            </li>
            {/* <li className="nav-item col" role="presentation">
              <a
                className={activetag == "Category" ? "nav-link show active" : "nav-link"}
                data-bs-toggle="tab"
                data-bs-target="#tab-42"
                aria-selected="false"
                role="tab"
                tabindex="-1"
                onClick={() => setActivetag("Category")}
              >
                <h4 className="textNoWrap">Category</h4>
              </a>
            </li> */}
            <li className="nav-item col" role="presentation">
              <a
                className={
                  activetag == "Model" ? "nav-link show active" : "nav-link"
                }
                data-bs-toggle="tab"
                data-bs-target="#tab-43"
                aria-selected="false"
                role="tab"
                tabIndex="-1"
                onClick={() => setActivetag("Model")}
              >
                <h4 className="textNoWrap">Model</h4>
              </a>
            </li>
            {/* <li className="nav-item col" role="presentation">
              <a
                className={activetag == "Brand" ? "nav-link show active" : "nav-link"}
                data-bs-toggle="tab"
                data-bs-target="#tab-46"
                aria-selected="false"
                role="tab"
                tabindex="-1"
                onClick={() => setActivetag("Brand")}
              >
                <h4 className="textNoWrap">Brand</h4>
              </a>
            </li> */}
          </ul>
          <div className="tab-content">
            {activetag == "Make" && (
              <div className="tab-pane active show" id="tab-41" role="tabpanel">
                <div className="row">
                  <div className="col-12 autoStoreWrapper">
                    <div className="autoStoreGridContainer">
                      {brands?.data?.map((brand, index) => (
                        <React.Fragment key={index}>
                          <div className="col mt-5">
                            <div className="content-box">
                              <div className="content-body text-center">
                                <img src={brand?.image} alt={`Car ${index}`} />
                              </div>
                              <div className="content-title mt-5 text-center">
                                <p style={{ fontSize: "16px" }}>
                                  {brand?.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activetag == "Model" && (
              <div className="tab-pane active show" id="tab-41" role="tabpanel">
                <div className="row">
                  <div className="col-12 autoStoreWrapper">
                    <div className="autoStoreGridContainer mt-5">
                      {models?.data?.map((model, index) => (
                        <React.Fragment key={index}>
                          <div className="col ">
                            <div className="content-box">
                              <div className="content-body text-center">
                                <img src={model?.image} alt={`Car ${index}`} />
                              </div>
                              <div className="content-title mt-5 text-center">
                                <p style={{ fontSize: "16px" }}>
                                  {model?.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="tab-pane " id="tab-42" role="tabpanel">
              <div className="row">
                <div className="col-12 autoStoreWrapper">
                  <div className="autoStoreGridContainer">
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img style={{ height: "63px", width: "144px" }} src={TurtleIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Turtle</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img style={{ width: "100px", height: "95px", marginTop: "0" }} src={VicIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Vic</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={StringioIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>ABRO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={ArmerIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Armor All</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={BullsoneIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Bullsone</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={AreonIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Areon</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={AromaIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Aroma</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={TyroIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>TYRO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={MegurisIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Meguris</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={NitcoIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Nitco</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane " id="tab-43" role="tabpanel">
              <div className="row">
                <div className="col-12 autoStoreWrapper">
                  <div className="autoStoreGridContainer">
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img style={{ height: "63px", width: "144px" }} src={TurtleIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Turtle</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img style={{ width: "100px", height: "95px", marginTop: "0" }} src={VicIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Vic</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={StringioIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>ABRO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={ArmerIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Armor All</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={BullsoneIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Bullsone</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={AreonIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Areon</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={AromaIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Aroma</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={TyroIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>TYRO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={MegurisIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Meguris</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={NitcoIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Nitco</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane " id="tab-46" role="tabpanel">
              <div className="row">
                <div className="col-12 autoStoreWrapper">
                  <div className="autoStoreGridContainer">
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img style={{ height: "63px", width: "144px" }} src={TurtleIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Turtle</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img style={{ width: "100px", height: "95px", marginTop: "0" }} src={VicIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Vic</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={StringioIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>ABRO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={ArmerIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Armor All</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={BullsoneIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Bullsone</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={AreonIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Areon</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={AromaIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Aroma</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={TyroIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>TYRO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={MegurisIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Meguris</p>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <div className="content-box">
                        <div className="content-body text-center">
                          <img src={NitcoIcon} />
                        </div>
                        <div className="content-title mt-5 text-center">
                          <p>Nitco</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoStoreCarPartsAccessories;
