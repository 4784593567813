import React from "react";
import "../../styles/become-driver.css";
import { Container, Row, Col } from "reactstrap";

import driverImg from "../../assets/all-images/toyota-offer-2.png";

const BecomeDriverSection = () => {
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  let token;
  if (loginInfo) {
    token = loginInfo["token"];
  }
  const Role = localStorage.getItem("Role");
  const signUpUrl = "https://cars.me/web-app/";
  const adminUrl = "https://cars.me/web-app/admin";
  const userUrl = "https://cars.me/cars";
  return (
    <section className="become__driver" style={{ marginBottom: "48px" }}>
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12" className="become__driver-img">
            <img src={driverImg} alt="" className="w-100" />
          </Col>

          <Col lg="6" md="6" sm="12">
            <h2 className="section__title become__driver-title">
              Sell Your Car and Get the Best Price
            </h2>

            <button
              className="btn become__driver-btn mt-4"
              onClick={() =>
                (window.location.href = Role
                  ? Role == "Admin"
                    ? adminUrl
                    : userUrl
                  : signUpUrl)
              }
            >
              Register Your Car
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BecomeDriverSection;
