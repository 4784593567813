import React, { useEffect, useRef, useState } from "react";

import { Container, Row, Col } from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../../styles/header.css";
import logoimg from "../../assets/all-images/cm loog-01.svg";

const navLinks = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/cars",
    display: "Cars",
  },
  // {
  //   path: "/",
  //   display: "Dealers",
  // },

  {
    path: "https://spareparts.me/store",
    display: "Auto Store",
  },
  {
    path: "/company",
    display: "Company",
  },
  {
    path: "/dealer",
    display: "Dealer",
  },
  {
    path: "/blogs",
    display: "Blog",
  },
  {
    path: "/about",
    display: "About Us",
  },
  {
    path: "/contact",
    display: "Contact Us",
  },
  {
    path: "/career",
    display: "Career",
  },
];

const Header = () => {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  let token;
  if (loginInfo) {
    token = loginInfo["token"];
  }
  const [loggedIn, setLoggedIn] = useState(false);
  const [name, setName] = useState("");
  const [menuShow, setShowMenu] = useState(false);
  const [menuShowMobile, setShowMenuMobile] = useState(false);
  const Role = localStorage.getItem("Role");
  const signUpUrl = "https://cars.me/web-app/sign-up";
  const loginUrl = "https://cars.me/web-app";
  const adminUrl = "https://cars.me/web-app/admin";
  const userUrl = "https://cars.me/web-app/user";
  const homeUrl = "https://cars.me/home";

  useEffect(() => {
    if (loginInfo) {
      setLoggedIn(true);
      getUserDetails();
      // setName(loginInfo["user"]["name"]);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const getUserDetails = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-user/" + loginInfo["user"]["id"],
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setName(data.data.name);
      });
  };

  const handleMenu = (e, item) => {
    e?.preventDefault();
    e?.stopPropagation();
    // console.log(item, "item");
    if (item === "signout") {
      localStorage.removeItem("login");
      localStorage.removeItem("loginInfo");
      localStorage.removeItem("Role");
      setShowMenu(false);
      window.location.href = loginUrl;
    } else if (item === "dasbhoard") {
      if (Role == "Admin") {
        window.location.href = adminUrl;
      } else {
        window.location.href = userUrl;
      }
      setShowMenu(false);
    }
  };
  const handleMenuMobile = (e, item) => {
    e?.preventDefault();
    e?.stopPropagation();
    // console.log(item, "item");
    if (item === "signout") {
      localStorage.removeItem("login");
      localStorage.removeItem("loginInfo");
      localStorage.removeItem("Role");
      setShowMenuMobile(false);
      window.location.href = loginUrl;
    } else if (item === "dasbhoard") {
      if (Role == "Admin") {
        window.location.href = adminUrl;
      } else {
        window.location.href = userUrl;
      }
      setShowMenuMobile(false);
    }
  };
  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  const handleSignupUrlCLick = () => {
    window.location.href = "https://cars.me/web-app/sign-up";
  };
  const handleLoginUrlCLick = () => {
    window.location.href = "https://cars.me/web-app";
  };
  const handleAdminUrlCLick = () => {
    window.location.href = "https://cars.me/web-app/admin";
  };
  const handleUserUrlCLick = () => {
    window.location.href = "https://cars.me/web-app/user";
  };
  const handleHomeUrlCLick = () => {
    window.location.href = "https://cars.me/home";
  };

  // const handleRoleClick = () => {};

  return (
    <header className="header">
      {/* ============ header top ============ */}
      <div className="header__top">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left">
                {/* <img src={logoimg} /> */}
                {/* <span>Need Help?</span>
                <span className="header__top__help">
                  <i className="ri-phone-fill"></i> +1-202-555-0149
                </span> */}
              </div>
            </Col>

            <Col lg="6" md="6" sm="6">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                {!loggedIn && (
                  <>
                    <Link
                      // to={loginUrl}
                      className=" d-flex align-items-center gap-1"
                      onClick={handleLoginUrlCLick}
                    >
                      <i className="ri-login-circle-line"></i> Login
                    </Link>

                    <Link
                      // to={signUpUrl}
                      className=" d-flex align-items-center gap-1"
                      onClick={handleSignupUrlCLick}
                    >
                      <i className="ri-user-line"></i> Register
                    </Link>
                  </>
                )}
                {loggedIn && (
                  <>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        gap: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowMenu(!menuShow);
                      }}
                    >
                      {/* <Link
                        to={
                          Role == "Admin"
                            ? "http://carme.instantsolutionslab.site/front/admin"
                            : "http://carme.instantsolutionslab.site/front/user"
                        }
                        className=" d-flex align-items-center gap-1"
                      > */}
                      <i className="ri-user-line"></i> {name}
                      <ul
                        style={{
                          position: "absolute",
                          bottom: "-68px",
                          left: 0,
                          background: "#2e3a98",
                          // zIndex: "2222",
                          zIndex: "0",
                          color: "#fff",
                          width: "100px",
                          padding: "2px 0 2px 17px",
                          cursor: "pointer",
                          display: menuShow ? "block" : "none",
                        }}
                      >
                        <li
                          onClick={(e) => handleMenu(e, "dasbhoard")}
                          style={{ cursor: "pointer" }}
                        >
                          Dashboard
                        </li>
                        <li
                          onClick={(e) => handleMenu(e, "signout")}
                          style={{ cursor: "pointer" }}
                        >
                          Sigout
                        </li>
                      </ul>
                      {/* </Link> */}
                    </div>

                    {/* <div style={{ position: "relative" }}>
                      <Link
                        // to="http://carme.instantsolutionslab.site/front/sign-up"
                        className="d-flex align-items-center gap-1"
                      >
                        <i className="ri-user-line"></i> Register2345
                        <ul
                          style={{
                            position: "absolute",
                            bottom: "-68px",
                            left: 0,
                            background: "#2e3a98",
                            zIndex: "2222",
                            color: "#fff",
                            width: "100px",
                            padding: "2px 0 2px 17px",
                          }}
                        >
                          <li onClick={(e) => handleMenu(e, "dasbhoard")}>
                            Dashboard
                          </li>
                          <li onClick={(e) => handleMenu(e, "signout")}>
                            Sigout
                          </li>
                        </ul>
                      </Link>
                    </div> */}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* =============== header middle =========== */}
      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <a href={homeUrl} onClick={(e) => e.preventDefault()}>
                <img
                  className="logoimg"
                  src={logoimg}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                />
              </a>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                {/* <span>
                  <i className="ri-earth-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Bangladesh</h4>
                  <h6>Sylhet City, Bangladesh</h6>
                </div> */}
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                {/* <span>
                  <i className="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Sunday to Friday</h4>
                  <h6>10am - 7pm</h6>
                </div> */}
              </div>
            </Col>

            <Col
              lg="2"
              md="3"
              sm="0"
              className=" d-flex align-items-center justify-content-end "
            >
              <button className="header__btn btn ">
                <Link
                  // to={Role ? (Role == "Admin" ? adminUrl : userUrl) : signUpUrl}
                  // onClick={() => window.location.reload()}
                  onClick={
                    // const Role = /* Your logic to determine the role */;
                    // const adminUrl = /* Your admin URL */;
                    // const userUrl = /* Your user URL */;
                    // const signUpUrl = /* Your sign-up URL */;
                    () => {
                      let urlToRedirect = signUpUrl;

                      if (Role) {
                        if (Role === "Admin") {
                          urlToRedirect = adminUrl;
                        } else {
                          urlToRedirect = userUrl;
                        }
                      }

                      window.location.href = urlToRedirect;
                    }
                  }
                >
                  Sell Your Car
                </Link>
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ========== main navigation =========== */}

      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i className="ri-menu-line" onClick={toggleMenu}></i>
            </span>
            <span className="small-logo-nav">
              <img src={logoimg} />
            </span>
            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <div className="menu">
                {!loggedIn && (
                  <>
                    <div>
                      <span
                        className="small-logo-nav"
                        style={{ position: "relative" }}
                      >
                        <img src={logoimg} />
                      </span>
                      <div className="jugaad" style={{ textAlign: "center" }}>
                        <i
                          style={{
                            position: "relative",
                            top: "3px",
                            right: "3px",
                          }}
                          className="ri-user-line"
                        ></i>

                        <NavLink
                          to={signUpUrl}
                          style={{ marginRight: "37px" }}
                          onClick={handleSignupUrlCLick}
                          className={(navClass) =>
                            navClass.isActive
                              ? "nav__active nav__item"
                              : "nav__item"
                          }
                        >
                          Register
                        </NavLink>
                        <i
                          style={{
                            position: "relative",
                            top: "3px",
                            right: "3px",
                          }}
                          className="ri-login-circle-line"
                        ></i>
                        <NavLink
                          onClick={handleLoginUrlCLick}
                          className={(navClass) =>
                            navClass.isActive
                              ? "nav__active nav__item"
                              : "nav__item"
                          }
                        >
                          Login
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
                {loggedIn && (
                  <>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => {
                        setShowMenuMobile(!menuShowMobile);
                      }}
                    >
                      {/* <Link
                       to={
                         Role == "Admin"
                           ? "http://carme.instantsolutionslab.site/front/admin"
                           : "http://carme.instantsolutionslab.site/front/user"
                       }
                       className=" d-flex align-items-center gap-1"
                     > */}
                      <span
                        className="small-logo-nav"
                        style={{ position: "relative" }}
                      >
                        <img src={logoimg} />
                      </span>
                      <div className="jugaad">
                        <i
                          style={{
                            position: "relative",
                            top: "3px",
                            right: "3px",
                          }}
                          className="ri-user-line"
                        ></i>

                        <img src="" alt="" />
                        <NavLink
                          onClick={(e) => handleMenuMobile(e, "dasbhoard")}
                          style={{ marginRight: "37px" }}
                          className={(navClass) =>
                            navClass.isActive
                              ? "nav__active nav__item"
                              : "nav__item"
                          }
                        >
                          Dashboard
                        </NavLink>
                        <i
                          style={{
                            position: "relative",
                            top: "3px",
                            right: "3px",
                          }}
                          className="ri-login-circle-line"
                        ></i>
                        <NavLink
                          onClick={(e) => handleMenuMobile(e, "signout")}
                          className={(navClass) =>
                            navClass.isActive
                              ? "nav__active nav__item"
                              : "nav__item"
                          }
                        >
                          Sign Out
                        </NavLink>
                      </div>
                      {/* </Link> */}
                    </div>
                  </>
                )}
                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) =>
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                  >
                    {item.display}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className="nav__right">
              {/* <div className="search__box">
                <input type="text" placeholder="Search" />
                <span>
                  <i className="ri-search-line"></i>
                </span>
              </div> */}
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
