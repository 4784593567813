import React, { useEffect, useState } from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../styles/footer.css";
import logoimg from "../../assets/all-images/cm loog-01.svg";

const quickLinks = [
  {
    path: "/about",
    display: "About",
  },

  // {
  //   path: "#",
  //   display: "Privacy Policy",
  // },

  {
    path: "/cars",
    display: "Car Listing",
  },
  {
    path: "/blogs",
    display: "Blog",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const navigate = useNavigate();

  const location = useLocation();
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Reset the email field whenever the route changes
    setEmail("");
  }, [location]);
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="1" md="4" sm="6">
            <img
              style={{ maxWidth: "100px", cursor: "pointer" }}
              className="logonimg"
              src={logoimg}
              onClick={() => navigate("/")}
            />
          </Col>
          <Col lg="3" md="4" sm="12">
            <div className="logo footer__logo">
              {/* <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <i className="ri-car-line"></i>
                  <span>
                    Rent Car <br /> Service
                  </span>
                </Link>
              </h1> */}
            </div>
            <p className="footer__logo-content">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequuntur, distinctio, itaque reiciendis ab cupiditate harum ex
              quam veniam, omnis expedita animi quibusdam obcaecati mollitia?
              Delectus et ad illo recusandae temporibus?
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">
                Instant Solutions Lab, Karachi, Pakistan
              </p>
              <p className="office__info">Phone: +0995345875365</p>

              <p className="office__info">Email: Instant@gmail.com</p>

              <p className="office__info">Office Time: 10am - 7pm</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description" style={{ color: "#fff" }}>
                Subscribe our newsletter
              </p>
              <div className="newsletter">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span style={{cursor:"pointer"}}>
                  <i className="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p
                className="section__description d-flex align-items-center justify-content-center gap-1 pt-4"
                style={{ color: "#fff" }}
              >
                <i className="ri-copyright-line"></i>Copyright {year}, Developed
                by Instant Solutions Lab
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
