import React, { useEffect, useState, useRef } from "react";
import { FaPhone, FaLocationArrow } from "react-icons/fa";
import Switcher from "../components/Switcher/Switcher";
import { Link } from "react-router-dom";
import "../styles/dealerDetails.css";
import { toast } from "react-toastify";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import banner2 from "../assets/all-images/691.jpg";
import banner1 from "../assets/all-images/20890892.jpg";
import banner from "../assets/all-images/3307710930933977424.png";
import { Container, Row, Col } from "reactstrap";

import favicon from "../assets/all-images/white-heart.png";
import favicon1 from "../assets/all-images/red-heart.png";
import BootstrapModals from "../components/Modal/Modal";
import Info from "../assets/all-images/infoIcon.svg";

const data = [
  {
    src: "https://cdn0.iconfinder.com/data/icons/car-brands/550/Toyota_logo-512.png",
    DealerName: "Merchants Automobiles",
    city: "karachi",
    avaiableCars: ["Toyota", "Mercedes-Benz", "Honda"],
    lastlyUpdate: "1 week",
    phoneNumber: "02134391110",
  },
  {
    src: "https://cdn0.iconfinder.com/data/icons/car-brands/550/Toyota_logo-512.png",
    DealerName: "Merchants Automobiles",
    city: "karachi",
    avaiableCars: ["Toyota", "Mercedes-Benz", "Honda"],
    lastlyUpdate: "1 week",
    phoneNumber: "02134391110",
  },
];

const data2 = [
  {
    src: "https://cdn0.iconfinder.com/data/icons/car-brands/550/Toyota_logo-512.png",
    DealerName: "Merchants Automobiles",
    city: "karachi",
    avaiableCars: ["Toyota", "Mercedes-Benz", "Honda"],
    lastlyUpdate: "1 week",
    phoneNumber: "02134391110",
  },
];

const details = [
  {
    address:
      "Plot 167 /C Shop#4 Parsa City luxuria PECHS Block 3 at Main khalid bin waleed road",
    phoneNumber: "Phone",
    Timings: "10:00 AM to 08:00 PM",
    website: "Websitehttp://www.merchantsautomobile.com",
  },
];

const ComplaintDetail = () => {
  const [checked, setChecked] = useState(true);
  const [carDetails, setCarDetails] = useState([]);
  const [carBottomBanner, setCarBottomBanner] = useState();
  const [rentCarDetail, setRentCarDetails] = useState();
  const [carMidBanner, setCarMidBanner] = useState();
  const [userInfo, setUserInfo] = useState("");
  const [carTopSidebarBanner, setCarTopSidebarBanner] = useState();
  const [midBannerSliderCondition, setMidBannerSliderCondition] =
    useState(null);
  let midBannerSliderCondition2;
  const [topSiderbarSliderCondition, setTopSiderbarBannerSliderCondition] =
    useState(null);
  let topSiderbarBannerSliderCondition2;
  const topSidebarBannerRef = useRef();
  const midBannerRef = useRef();
  const [favouriteIcons, setFavouriteIcons] = useState({});
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo")); //GET LOGIN INFO
  let bottomBannerSliderCondition2;
  const [bottomBannerSliderCondition, setBottomBannerSliderCondition] =
    useState(null);
  let token;
  let userId;
  if (loginInfo) {
    token = loginInfo["token"]; //GET TOKEN
    userId = loginInfo["user"]["id"];
  }
  const navigate = useNavigate();
  const bottomBannerRef = useRef();
  const settings1 = {
    dots: true,
    // infinite: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 587,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  const afterTopSidebarBannerChangeHandler = (currentSlide) => {
    const activeSlide = carTopSidebarBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      topSiderbarBannerSliderCondition2 = "xyz";
      if (
        topSiderbarSliderCondition === "abc" &&
        topSiderbarBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  const openBannerLinkPage = async (link, target, id, click, impression) => {
    await fetch(
      process.env.REACT_APP_URL +
        "/update-banner-clicks-and-impressions?id=" +
        id +
        "&click=" +
        click +
        "&impression=" +
        impression,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {});
    if (link) {
      if (target == 1) {
        window.open(link, "_blank");
      } else {
        window.location.href = link;
      }
    }
  };

  const afterMidBannerChangeHandler = (currentSlide) => {
    const activeSlide = carMidBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      midBannerSliderCondition2 = "xyz";
      if (
        midBannerSliderCondition === "abc" &&
        midBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  const afterBottomBannerChangeHandler = (currentSlide) => {
    const activeSlide = carBottomBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomBannerSliderCondition2 = "xyz";
      if (
        bottomBannerSliderCondition === "abc" &&
        bottomBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };

  const favouriteCarList = async (item) => {
    // console.log(item);
    if (userId) {
      const data = new FormData();
      data.append("user_id", userId);
      data.append("subject_id", item.id);
      await fetch(process.env.REACT_APP_URL + "/favorite", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          const updatedIcons = { ...favouriteIcons };
          if (data.message === "Added To Favourites") {
            updatedIcons[item.id] = true;
            toast.success(data.message);
          } else {
            delete updatedIcons[item.id];
            toast.success(data.message);
          }
          setFavouriteIcons(updatedIcons);
          // console.log(favouriteIcons);
        });
    } else {
      toast.error("Please Login");
      setTimeout(() => {
        window.location.href = "https://cars.me/web-app/";
      }, 1000);
    }
  };

  const checkFavouriteCar = async (item) => {
    // console.log(userId);
    if (userId) {
      await fetch(
        process.env.REACT_APP_URL +
          `/check-favourite-car?user_id=${userId}&subject_id=${item?.id}`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setFavouriteIcons((prevIcons) => {
            const updatedIcons = { ...prevIcons };
            if (data.data === "true") {
              updatedIcons[item.id] = true;
            } else {
              delete updatedIcons[item.id];
            }
            return updatedIcons;
          });
        });
    }
  };

  const fetchCarDetails = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        `/get-cars-by-user?user_id=${localStorage.getItem(
          "companyID"
        )}&seller_type=Sell`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarDetails(data?.data?.data);
      });
  };
  // fetch Rent details
  const fetchRentCarDetails = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        `/get-cars-by-user?user_id=${localStorage.getItem(
          "companyID"
        )}&seller_type=Rent`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setRentCarDetails(data?.data);
      });
  };

  // fetch clicked details
  const fetchUserInfo = async () => {
    const urlSlug = window.location.href.split("/dealer/");
    await fetch(
      process.env.REACT_APP_URL +
        // `/get-user/${localStorage.getItem("companyID")}`,
        `/get-user-by-slug?slug=${urlSlug[1]}`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setUserInfo(data?.data);
        if (data?.data === undefined) {
          // ("/destination-route");
          window.location.href = "/notfound";
        }
      });
  };
  const fetchCarDetailBottomBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=dealerdetail&position=Bottom",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarBottomBanner(data.data); //get data in data
      });
  };
  const fetchCarDetailMidBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=dealerdetail&position=Mid",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarMidBanner(data.data); //get data in data
      });
  };
  const fetchCarDetailTopSidebarBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=dealerdetail&position=Sidebar",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarTopSidebarBanner(data.data); //get data in data
      });
  };

  useEffect(() => {
    setTimeout(() => {
      fetchUserInfo();
      fetchCarDetails();
      fetchRentCarDetails();
      // banner
      fetchCarDetailBottomBanner();
      fetchCarDetailMidBanner();
      fetchCarDetailTopSidebarBanner();
    }, 2000);
  }, []);

  useEffect(() => {
    if (carDetails) {
      carDetails?.forEach((item) => {
        checkFavouriteCar(item);
      });
    }
  }, [carDetails]);
  // console.log(userInfo, "userinfo");
  const handleChange1 = (nextChecked) => {
    setChecked(true);
  };
  const handleChange2 = (nextChecked) => {
    setChecked(false);
  };

  const [name1, setName1] = useState();
  const [email1, setEmail1] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [message1, setMessage1] = useState();
  const [contant1, setContant1] = useState();
  const [bannerId, setBannerId] = useState();

  const handlemidBannerRef = (e, id) => {
    setBannerId(id);
    setModalOpen(true);
  };
  const handlemidBannerRefClose = (e) => {
    // console.log(e);
    setModalOpen(false);
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // console.log(name1, email1, message1, contant1);

    if (
      name1 != "" &&
      name1 != undefined &&
      name1 != null &&
      email1 != "" &&
      email1 != undefined &&
      email1 != null &&
      message1 != "" &&
      message1 != undefined &&
      message1 != null &&
      contant1 != "" &&
      contant1 != undefined &&
      contant1 != null
    ) {
      // bannner data
      // IF INPUTS EMPTY OR NULL OR UNDEFINED
      const data = new FormData();
      data.append("name", name1);
      data.append("email", email1);
      data.append("message", message1);
      data.append("contact", contant1);
      data.append("banner_id", bannerId);
      fetch(process.env.REACT_APP_URL + "/create-banner-complaint", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);

          toast.success(data.message);
          setModalOpen(false);
          setName1();
          setEmail1();
          setMessage1();
          setContant1();
        });
      // .catch((e) => console.log(e));
    } else {
      toast.error("Invalid details");
    }
  };

  return (
    <>
      <BootstrapModals
        isOpen={modalOpen}
        onRequestClose={handlemidBannerRefClose}
        name={name1}
        setName={setName1}
        email={email1}
        setEmail={setEmail1}
        message={message1}
        setMessage={setMessage1}
        contact={contant1}
        setContact={setContant1}
        handleSubmit={handleSubmit1}
      />
      <div className="container bcontent dealer-details-wrapper">
        <div className="icon icon-res pb-2">
          <img
            className="card-img"
            // src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
            // src={
            //   userInfo?.image
            //     ? userInfo?.image
            //     : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
            // }
            src={
              userInfo?.image
                ? userInfo?.image.split(".")[3].length === 0
                  ? "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                  : userInfo?.image
                : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
            }
            alt={userInfo?.name}
          />
        </div>
        <div
          className="dealer-details"
          // style={{
          //   display: "flex",
          //   gap: "2px",
          //   flexDirection: "column",
          // }}
        >
          <div style={{ marginBottom: 0 }} className="name-mb-view">
            {userInfo?.name ? (
              userInfo?.name
            ) : (
              <p className="card-text placeholder-glow">
                <span className="placeholder col-4"></span>
              </p>
            )}
          </div>
          {/* <p>
            Merchants Automobile believes in relationship than salesmanship. Our
            company deals in Japanese vehicles from Japanese auction only. Since
            inception Merchants Automobile has worked on the philosophy as car
            consultant rather than car dealer hence we get the maximum value of
            money for our clients.
          </p> */}
          <p style={{ marginBottom: 0 }}>
            {userInfo?.description ? `${userInfo?.description}` : ``}
          </p>
        </div>
      </div>
      <Container>
        <Row>
          <Col lg="12" className="mt-5">
            <div ref={midBannerRef} style={{ position: "relative" }}>
              <Slider {...settings1} afterChange={afterMidBannerChangeHandler}>
                {carMidBanner?.data?.length == 0 && (
                  <img style={{ width: "100%" }} src={banner1} />
                )}
                {carMidBanner?.data?.length > 0 &&
                  carMidBanner?.data?.map((midBanner, index) => {
                    return (
                      <React.Fragment key={index}>
                        {midBanner?.image && (
                          <>
                            <img
                              style={{ width: "100%" }}
                              src={midBanner?.image}
                              key={index}
                              onClick={() =>
                                openBannerLinkPage(
                                  midBanner?.link,
                                  midBanner?.target,
                                  midBanner?.id,
                                  "Yes",
                                  "No"
                                )
                              }
                              data-mid-banner-id={midBanner?.id}
                            />
                            <img
                              src={Info}
                              alt=""
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                cursor: "pointer",
                                backgroundColor: "#fff",
                                borderRadius: "16px",
                              }}
                              onClick={(e) =>
                                handlemidBannerRef(e, midBanner?.id)
                              }
                            />
                          </>
                        )}
                        {midBanner?.script && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: midBanner.script,
                            }}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    );
                    // }
                  })}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <h2 className="container mt-5 mb-3 desktop-view">Recent Cars</h2>
      <span className="desktop-view">
        <Switcher
          checked={checked}
          handleChange1={handleChange1}
          handleChange2={handleChange2}
        />
      </span> */}

      <div className="container bcontent toggle-wrapper mt-5 mb-5 detail-responsive-mb">
        <div className="left">
          <h2 style={{ padding: "0" }}>Dealer Cars</h2>
          {carDetails?.length
            ? carDetails?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {/* <Link
                      to={`/dealer/${item?.slug}`}
                      style={{
                        color: "inherit",
                        textDecoration: "inherit",
                      }}
                      key={i}
                      onClick={() => {
                        localStorage.setItem("companyID", `${item?.user_id}`);
                      }}
                    > */}
                    <div
                      className="container bcontent dealer-card-wrapper"
                      onClick={() => {
                        navigate(`/dealer/${item?.slug}`);
                        localStorage.setItem("companyID", `${item?.user_id}`);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="card-feature-img">
                        <div className="row no-gutters">
                          <div
                            className="col-sm-5"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="card-img"
                              src={item?.featured_image}
                              alt={item.name}
                              onClick={() => {
                                navigate(`/dealer/${item?.slug}`);
                                localStorage.setItem(
                                  "companyID",
                                  `${item?.user_id}`
                                );
                              }}
                            />
                            <img
                              style={{
                                width: "20px",
                                position: "absolute",
                                right: "10px",
                                top: "9px",
                                cursor: "pointer",
                              }}
                              onClick={() => favouriteCarList(item)}
                              src={
                                favouriteIcons[item?.id] ? favicon1 : favicon
                              }
                            />
                          </div>
                          <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px" }}
                              >
                                {item.name}
                              </p>
                              <p style={{ margin: "0 0 5px" }}>
                                <FaLocationArrow size={11} /> {item.city}
                              </p>

                              <p
                                className="last-time-updated"
                                style={{ margin: "0 0 5px" }}
                              >
                                Updated {item.time_ago}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px" }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "3px" }}
                                />
                                {item?.contact
                                  ? item?.contact
                                  : "not available"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </React.Fragment>
                );
              })
            : "Cars are Unavailable"}
          <Container>
            <Row>
              <Col lg="12" className="mt-5">
                <div ref={bottomBannerRef} style={{ position: "relative" }}>
                  <Slider
                    {...settings1}
                    afterChange={afterBottomBannerChangeHandler}
                    className="jjaaguaarr"
                  >
                    {carBottomBanner?.data?.length == 0 && (
                      <img style={{ width: "100%" }} src={banner2} />
                    )}
                    {carBottomBanner?.data?.length > 0 &&
                      carBottomBanner?.data?.map((midBanner, index) => {
                        return (
                          <React.Fragment key={index}>
                            {midBanner?.image && (
                              <>
                                <img
                                  style={{
                                    width: "100%",
                                    margin: "auto !important",
                                  }}
                                  src={midBanner?.image}
                                  key={index}
                                  onClick={() =>
                                    openBannerLinkPage(
                                      midBanner?.link,
                                      midBanner?.target,
                                      midBanner?.id,
                                      "Yes",
                                      "No"
                                    )
                                  }
                                  data-bottom-banner-id={midBanner?.id}
                                />
                                <img
                                  src={Info}
                                  alt=""
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10,
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    borderRadius: "16px",
                                  }}
                                  onClick={(e) =>
                                    handlemidBannerRef(e, midBanner?.id)
                                  }
                                />
                              </>
                            )}
                            {midBanner?.script && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: midBanner.script,
                                }}
                                key={index}
                              />
                            )}
                          </React.Fragment>
                        );
                        // }
                      })}
                  </Slider>
                </div>
              </Col>
            </Row>
            {/* <Row>
          <Col lg="8" className="mt-5">
            <div className="sliderabsolute" ref={topSidebarBannerRef}>
              <Slider
                {...settings1}
                afterChange={afterTopSidebarBannerChangeHandler}
              >
                {carTopSidebarBanner?.data?.length == 0 ? (
                  <img className="sidebar-car-detail" src={banner} />
                ) : (
                  <></>
                )}
                {carTopSidebarBanner?.data?.length > 0 &&
                  carTopSidebarBanner?.data?.map((midBanner, index) => {
                    return (
                      <>
                        {midBanner?.image && (
                          <img
                            className="sidebar-car-detail"
                            src={midBanner?.image}
                            key={index}
                            onClick={() =>
                              openBannerLinkPage(
                                midBanner?.link,
                                midBanner?.target,
                                midBanner?.id,
                                "Yes",
                                "No"
                              )
                            }
                            data-top-sidebar-banner-id={midBanner?.id}
                          />
                        )}
                        {midBanner?.script && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: midBanner.script,
                            }}
                            key={index}
                          />
                        )}
                      </>
                    );
                    // }
                  })}
              </Slider>
            </div>
          </Col>
        </Row> */}
          </Container>
        </div>
        <div className="right">
          <div className="dealers-details">
            <p>Name:</p>
            <div>
              {userInfo?.name ? (
                userInfo?.name
              ) : (
                <p className="card-text placeholder-glow">
                  <span className="placeholder col-4"></span>
                </p>
              )}
            </div>

            <p>address:</p>
            <p>
              {userInfo?.address
                ? userInfo?.address
                : "Address is not available"}
            </p>
            <p>Phone Number:</p>
            <p>{userInfo?.phone_no ? userInfo?.phone_no : "Not Available"}</p>
            <p>Timings: </p>
            <p>
              {" "}
              {userInfo?.timing?.trim() === "to"
                ? userInfo?.timing
                : "Not Available"}
            </p>
            <p>website: </p>
            <p>
              {" "}
              {userInfo?.website_url ? userInfo?.website_url : "Not Available"}
            </p>
          </div>
          <Container>
            <Row>
              <Col lg="12" className="">
                <div
                  className="sliderabsolute sidebar-car-detail-for-mb"
                  ref={topSidebarBannerRef}
                  style={{ position: "relative" }}
                >
                  <Slider
                    {...settings1}
                    afterChange={afterTopSidebarBannerChangeHandler}
                  >
                    {carTopSidebarBanner?.data?.length == 0 ? (
                      <img
                        className="sidebar-car-detail"
                        style={{
                          width: "100% !important",
                          margin: "0 !important",
                        }}
                        src={banner}
                      />
                    ) : (
                      <></>
                    )}
                    {carTopSidebarBanner?.data?.length > 0 &&
                      carTopSidebarBanner?.data?.map((midBanner, index) => {
                        return (
                          <React.Fragment key={index}>
                            {midBanner?.image && (
                              <>
                                <img
                                  className="sidebar-car-detail "
                                  src={midBanner?.image}
                                  style={{
                                    width: "100% !important",
                                    margin: "0 !important",
                                  }}
                                  key={index}
                                  onClick={() =>
                                    openBannerLinkPage(
                                      midBanner?.link,
                                      midBanner?.target,
                                      midBanner?.id,
                                      "Yes",
                                      "No"
                                    )
                                  }
                                  data-top-sidebar-banner-id={midBanner?.id}
                                />
                                <img
                                  src={Info}
                                  alt=""
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    handlemidBannerRef(e, midBanner?.id)
                                  }
                                />
                              </>
                            )}
                            {midBanner?.script && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: midBanner.script,
                                }}
                                key={index}
                              />
                            )}
                          </React.Fragment>
                        );
                        // }
                      })}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ComplaintDetail;
