import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import image from '../assets/all-images/slider-img/image_2023_03_29T19_55_03_939Z.png'
import BlogList from "../components/UI/BlogList";

const Blog = () => {
  return (
    <Helmet title="Blogs">
      {/* <CommonSection title="Blogs" /> */}
      {/* <div>
        <img src={image} />
      </div> */}
      <section>
        <Container>
          <Row>
          <BlogList />
            {/* {blogs?.data?.map((blog, index) => {
              return (
                <BlogList key={index} blog={blog} />
              )
            })} */}

            {/* <BlogList /> */}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Blog;
