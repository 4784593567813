import React, { useState, useEffect, useRef } from "react";
import "../styles/dealer.css";
import { FaPhone, FaLocationArrow } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import check from "../assets/all-images/tick.svg";
import Helmet from "../components/Helmet/Helmet";
import Pagination from "react-js-pagination";
import CommonSection from "../components/UI/CommonSection";
import Slider from "react-slick";
import banner3 from "../assets/all-images/197687-OYP0MC-7101.jpg";
import banner4 from "../assets/all-images/202014-OZCYW7-111.jpg";
import banner2 from "../assets/all-images/691.jpg";
import banner1 from "../assets/all-images/690.jpg";
import BootstrapModals from "../components/Modal/Modal";
import Info from "../assets/all-images/infoIcon.svg";

import { toast } from "react-toastify";

import favicon from "../assets/all-images/white-heart.png";
import favicon1 from "../assets/all-images/red-heart.png";

const Company = () => {
  const navigate = useNavigate();
  const testingUrl = window?.location?.href;
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [cityName, setCityName] = useState("All Cities");
  const [makeFilter, setMakeFilter] = useState("Select");
  const [cities, setCities] = useState();
  const [city, setcity] = useState("");
  const [brands, setBrands] = useState();
  const [selectimg1, setSelectimg1] = useState("");
  const [dealer, setDealer] = useState();
  const topSidebarBannerRef = useRef();
  const [carBottomBanner, setCarBottomBanner] = useState();
  const [selectedCity, setSelectedCity] = useState("");
  const bottomSiderbarBannerRef = useRef();
  let bottomBannerSliderCondition2;
  // const [bottomBannerSliderCondition, setBottomBannerSliderCondition] =
  //   useState(null);
  const [carMidBanner, setCarMidBanner] = useState();

  const [citiesDataa, setCitiesDataa] = useState();
  // sidebar banner
  const [carTopSidebarBanner, setCarTopSidebarBanner] = useState();
  const [carBottomSidebarBanner, setCarBottomSidebarBanner] = useState();
  let topSiderbarBannerSliderCondition2;
  const [topSiderbarSliderCondition, setTopSiderbarBannerSliderCondition] =
    useState(null);
  const [midBannerSliderCondition, setMidBannerSliderCondition] =
    useState(null);
  const [bottomBannerSliderCondition, setBottomBannerSliderCondition] =
    useState(null);

  let midBannerSliderCondition2;

  const [
    bottomSiderbarBannerSliderCondition,
    setBottomSiderbarBannerSliderCondition,
  ] = useState(null);
  const afterBottomSidebarBannerChangeHandler = (currentSlide) => {
    const activeSlide = carBottomSidebarBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomSiderbarBannerSliderCondition2 = "xyz";
      if (
        bottomSiderbarBannerSliderCondition === "abc" &&
        bottomSiderbarBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };
  let bottomSiderbarBannerSliderCondition2;
  const openBannerLinkPage = async (link, target, id, click, impression) => {
    await fetch(
      process.env.REACT_APP_URL +
        "/update-banner-clicks-and-impressions?id=" +
        id +
        "&click=" +
        click +
        "&impression=" +
        impression,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {});
    if (link) {
      if (target == 1) {
        window.open(link, "_blank");
      } else {
        window.location.href = link;
      }
    }
  };

  const [favouriteIcons, setFavouriteIcons] = useState({});
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo")); //GET LOGIN INFO
  let token;
  const observer = useRef(null);
  const observer2 = useRef(null);
  const observer3 = useRef(null);
  const observer4 = useRef(null);
  const midBannerRef = useRef();
  const bottomBannerRef = useRef();
  let userId;
  if (loginInfo) {
    token = loginInfo["token"]; //GET TOKEN
    userId = loginInfo["user"]["id"];
  }
  const settings = {
    dots: true,
    // infinite: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 587,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  const afterTopSidebarBannerChangeHandler = (currentSlide) => {
    const activeSlide = carTopSidebarBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      topSiderbarBannerSliderCondition2 = "xyz";
      if (
        topSiderbarSliderCondition === "abc" &&
        topSiderbarBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  const favouriteCarList = async (item) => {
    // console.log(item);
    if (userId) {
      const data = new FormData();
      data.append("user_id", userId);
      data.append("subject_id", item.id);
      await fetch(process.env.REACT_APP_URL + "/favorite", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          const updatedIcons = { ...favouriteIcons };
          if (data.message === "Added To Favourites") {
            updatedIcons[item.id] = true;
            toast.success(data.message);
          } else {
            delete updatedIcons[item.id];
            toast.success(data.message);
          }
          setFavouriteIcons(updatedIcons);
          // console.log(favouriteIcons);
        });
    } else {
      toast.error("Please Login");
      setTimeout(() => {
        window.location.href = "https://cars.me/web-app/";
      }, 1000);
    }
  };
  const checkFavouriteCar = async (item) => {
    if (userId) {
      await fetch(
        process.env.REACT_APP_URL +
          `/check-favourite-car?user_id=${userId}&subject_id=${item?.id}`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setFavouriteIcons((prevIcons) => {
            const updatedIcons = { ...prevIcons };
            if (data.data === "true") {
              updatedIcons[item.id] = true;
            } else {
              delete updatedIcons[item.id];
            }
            return updatedIcons;
          });
        });
    }
  };
  let pageNumber = 1;

  // fetch  sidebanner
  const fetchCarTopSidebarBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=dealer&position=Top Sidebar",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarTopSidebarBanner(data.data); //get data in data
      });
  };

  const fetchCarBottomSidebarBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=dealer&position=Bottom Sidebar",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarBottomSidebarBanner(data.data); //get data in data
      });
  };

  // fetch dealer details
  const fetchAllDealerDetails = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-user-by-role?role_id=2", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setDealer(data?.data);
      });
  };

  // fetch item
  const fetchCarItems = async (pageNumber, filters = {}) => {
    const url = new URL(
      process.env.REACT_APP_URL + "/get-user-by-role?role_id=2"
    );
    url.searchParams.append("page", pageNumber);

    console.log(filters, "filters");
    let isBrandName = queryParams.get("brand");
    let isCityName = queryParams.get("city");
    console.log(isCityName !== null, isCityName, "isCityName");
    // if (isCityName !== null) {
    if (
      window?.location?.href.includes("city") &&
      localStorage?.getItem("city") !== null
    ) {
      url.searchParams.append("city_id", localStorage?.getItem("city"));
      setCityName(localStorage?.getItem("cityName"));
    }
    // if (isBrandName !== null) {
    if (
      window?.location?.href.includes("brand") &&
      localStorage?.getItem("brand") !== null
    ) {
      // let isBrandName = queryParams.get("brand");
      const url2 = window?.location?.href;
      const urlObj = new URL(url2);
      const queryParams = new URLSearchParams(urlObj.search);
      const brandValue = queryParams.get("brand");
      setMakeFilter(brandValue);

      url.searchParams.append("brand_id", localStorage?.getItem("brand"));
    }

    // if (queryParams?.get("city") !== null) {
    console.log(localStorage?.getItem("brandId"), "cityName2inside");
    console.log(
      localStorage?.getItem("cityId") ?? "All Cities",
      "cityName2inside"
    );
    // }

    // console.log(localStorage)
    // Add filter parameters to the request
    // Object.keys(filters).forEach((key) => {
    //   if (filters[key]) {
    //     url.searchParams.append(key, filters[key]);
    //   }
    // });

    await fetch(url, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // setCarItems(data.data); //get data in data
        // setCarItems(data.data);
        setDealer(data?.data);
        //get data in data
      });
  };

  const location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  // Extract the filter values from the query parameters
  let from_price = queryParams.get("fromPrice");
  let to_price = queryParams.get("toPrice");
  let country_id = queryParams.get("countryId");
  let brand_id = queryParams.get("brand");
  let transmission = queryParams.get("transmission");
  let from_year = queryParams.get("fromYear");
  let to_year = queryParams.get("toYear");
  let from_meter_counter = queryParams.get("fromMeterCounter");
  let to_meter_counter = queryParams.get("toMeterCounter");
  let model_id = queryParams.get("modelId");
  let city_id = queryParams.get("city");

  let filters = {
    from_price,
    to_price,
    country_id,
    brand_id,
    model_id,
    city_id,
    transmission,
    from_year,
    to_year,
    from_meter_counter,
    to_meter_counter,
  };

  const getFilters = (pageNumber, filters) => {
    filters.brand_id = localStorage.getItem("brand");
    filters.city_id = localStorage.getItem("city");
    fetchCarItems(pageNumber, filters);
  };

  const fetchAllCitiesDetails = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-uae-cities", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCities(data?.data); //get data in data
        setCitiesDataa(data?.data);
      });
  };

  // apply filter
  const applyCityFilter = () => {
    setDropdown1(false);
    setCities(citiesDataa);
    filters.city_id = String(city);
    city_id = String(city);
    localStorage.setItem("city", String(city));
    localStorage.setItem("cityName", String(cityName));
    // queryParams.set("city", String(city));
    queryParams.append(
      "city",
      String(selectedCity)
        .replace(/\+/g, " ")
        .replace(/\s+/g, "")
        .toLowerCase()
        .trim()
    );
    // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

    // let newUrl;
    // if (testingUrl.includes("?")) {
    //   newUrl = `${window.location.href}&${queryParams.toString()}`;
    // } else {
    //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    // }
    let newUrl;
    const baseUrl = window.location.href.split("?")[0];

    if (testingUrl.includes("?")) {
      let existingParams = window.location.search.substr(1); // Get the existing query parameters
      const paramsArray = existingParams.split("&");
      const updatedParams = paramsArray.filter(
        (param) => !param.startsWith("city=")
      ); // Remove existing "brand" parameter

      if (baseUrl.includes("?")) {
        newUrl = `${baseUrl}?${updatedParams.join(
          "&"
        )}&${queryParams.toString()}`;
      } else {
        newUrl = `${baseUrl}?${updatedParams.join("&")}`;
        if (updatedParams.length > 0) {
          newUrl += "&";
        }
        newUrl += queryParams.toString();
      }
    } else {
      newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    }

    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchCarItems(1, filters);
  };

  // fetch all brands
  const fetchAllBrands = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-brands", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setBrands(data); //get data in data
      });
  };

  // apply brand filter
  const applyBrandFilter = (id, brandsName) => {
    setDropdown2(false);
    setSelectimg1(`${id}`);
    filters.brand_id = String(id);
    brand_id = String(id);
    localStorage.setItem("brand", String(id));
    // queryParams.set("brand", String(id));
    queryParams.append(
      "brand",
      String(brandsName)
        .replace(/\+/g, " ")
        .replace(/\s+/g, "")
        .toLowerCase()
        .trim()
    );
    // const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

    // let newUrl;
    // if (testingUrl.includes("?")) {
    //   newUrl = `${window.location.href}&${queryParams.toString()}`;
    // } else {
    //   newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    // }

    let newUrl;
    const baseUrl = window.location.href.split("?")[0];

    if (testingUrl.includes("?")) {
      let existingParams = window.location.search.substr(1); // Get the existing query parameters
      const paramsArray = existingParams.split("&");
      const updatedParams = paramsArray.filter(
        (param) => !param.startsWith("brand=")
      ); // Remove existing "brand" parameter

      if (baseUrl.includes("?")) {
        newUrl = `${baseUrl}?${updatedParams.join(
          "&"
        )}&${queryParams.toString()}`;
      } else {
        newUrl = `${baseUrl}?${updatedParams.join("&")}`;
        if (updatedParams.length > 0) {
          newUrl += "&";
        }
        newUrl += queryParams.toString();
      }
    } else {
      newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    }

    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchCarItems(1, filters);
  };

  const dropdown1fun = () => {
    if (dropdown1) {
      setDropdown1(false);
      setCities(citiesDataa);
    } else {
      setDropdown1(true);
      setDropdown2(false);
    }
  };
  const dropdown2fun = () => {
    if (dropdown2) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
      setDropdown1(false);
    }
  };

  const dropdownclose = () => {
    setDropdown1(false);
    setDropdown2(false);
  };

  const afterBottomBannerChangeHandler = (currentSlide) => {
    const activeSlide = carBottomBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomBannerSliderCondition2 = "xyz";
      if (
        bottomBannerSliderCondition === "abc" &&
        bottomBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };

  const fetchCarMidBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-banner?page_slug=dealer&position=Mid",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarMidBanner(data.data); //get data in data
      });
  };
  const fetchCarBottomBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL +
        "/get-banner?page_slug=dealer&position=Bottom",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCarBottomBanner(data.data); //get data in data
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fetchAllCitiesDetails();
      fetchAllBrands();
      // fetchAllDealerDetails();
      // fetchCarItems();
      fetchCarItems(pageNumber, filters);
      //
      fetchCarBottomBanner();
      fetchCarMidBanner();
      fetchCarTopSidebarBanner();
      fetchCarBottomSidebarBanner();

      //
      observer.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-mid-banner-id]"
            );
            setMidBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-mid-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setMidBannerSliderCondition("");
          }
        });
      });
      observer2.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-bottom-banner-id]"
            );
            setBottomBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-bottom-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setBottomBannerSliderCondition("");
          }
        });
      });
      observer3.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-top=sidebar-banner-id]"
            );
            setTopSiderbarBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-top-sidebar-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setTopSiderbarBannerSliderCondition("");
          }
        });
      });
      observer4.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeBannerId = document.querySelector(
              ".slick-active [data-bottom-sidebar-banner-id]"
            );
            setBottomSiderbarBannerSliderCondition("abc");
            openBannerLinkPage(
              null,
              "0",
              activeBannerId?.getAttribute("data-bottom-sidebar-banner-id"),
              "No",
              "Yes"
            );
          } else {
            setBottomSiderbarBannerSliderCondition("");
          }
        });
      });

      if (midBannerRef.current) {
        observer.current.observe(midBannerRef.current);
      }

      if (bottomBannerRef.current) {
        observer2.current.observe(bottomBannerRef.current);
      }

      if (topSidebarBannerRef.current) {
        observer.current.observe(topSidebarBannerRef.current);
      }

      if (bottomSiderbarBannerRef.current) {
        observer2.current.observe(bottomSiderbarBannerRef.current);
      }

      return () => {
        observer.current.disconnect();
        observer2.current.disconnect();
        observer3.current.disconnect();
        observer4.current.disconnect();
      };
    }, 2000);
  }, []);

  const afterMidBannerChangeHandler = (currentSlide) => {
    const activeSlide = carMidBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      midBannerSliderCondition2 = "xyz";
      if (
        midBannerSliderCondition === "abc" &&
        midBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };

  useEffect(() => {
    if (dealer) {
      dealer?.data.forEach((item) => {
        checkFavouriteCar(item);
      });
    }
  }, [dealer]);

  // banner calls end
  const [name1, setName1] = useState();
  const [email1, setEmail1] = useState();
  const [message1, setMessage1] = useState();
  const [contant1, setContant1] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [bannerId, setBannerId] = useState();

  const handlemidBannerRef = (e, id) => {
    setBannerId(id);
    setModalOpen(true);
  };
  const handlemidBannerRefClose = (e) => {
    // console.log(e);
    setModalOpen(false);
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // console.log(name1, email1, message1, contant1);

    if (
      name1 != "" &&
      name1 != undefined &&
      name1 != null &&
      email1 != "" &&
      email1 != undefined &&
      email1 != null &&
      message1 != "" &&
      message1 != undefined &&
      message1 != null &&
      contant1 != "" &&
      contant1 != undefined &&
      contant1 != null
    ) {
      // bannner data
      // IF INPUTS EMPTY OR NULL OR UNDEFINED
      const data = new FormData();
      data.append("name", name1);
      data.append("email", email1);
      data.append("message", message1);
      data.append("contact", contant1);
      data.append("banner_id", bannerId);
      fetch(process.env.REACT_APP_URL + "/create-banner-complaint", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);

          toast.success(data.message);
          setModalOpen(false);
          setName1();
          setEmail1();
          setMessage1();
          setContant1();
        });
      // .catch((e) => console.log(e));
    } else {
      toast.error("Invalid details");
    }
  };

  function containsNorNor(event) {
    const clickedElement = event.target;
    let currentElement = clickedElement;

    while (currentElement) {
      if (currentElement.classList.contains("dropdown2Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      if (currentElement.classList.contains("dropdown1Closee")) {
        // console.log(
        //   "The element or one of its ancestors contains 'nor nor' in its class name."
        // );
        return;
      }
      currentElement = currentElement.parentElement;
    }

    // console.log(
    //   "The element and its ancestors do not contain 'nor nor' in their class name."
    // );

    setDropdown2(false);
    setDropdown1(false);
  }

  useEffect(() => {
    // window.addEventListener("click", handleUserKeyPress);
    window.addEventListener("click", containsNorNor);
    return () => {
      window.removeEventListener("click", containsNorNor);
    };
  });

  return (
    <>
      <Helmet title="Dealer" slug="dealer" />
      <BootstrapModals
        isOpen={modalOpen}
        onRequestClose={handlemidBannerRefClose}
        name={name1}
        setName={setName1}
        email={email1}
        setEmail={setEmail1}
        message={message1}
        setMessage={setMessage1}
        contact={contant1}
        setContact={setContant1}
        handleSubmit={handleSubmit1}
      />
      <CommonSection
        title="Car Listing"
        slug="dealer"
        handlemidBannerRef={handlemidBannerRef}
        bannerIDD
      />
      <Container className="responsivecontainer">
        <Row>
          <Col lg="12" className="main-colom-css-scroll">
            <div className=" d-flex align-items-center gap-3 mt-5 mb-5 mainfilter-div">
              <div
                onClick={dropdown1fun}
                className="filter-sub-box dropdown1Closee"
                style={{ flex: 1 }}
              >
                <span className="sub-div-span1">City</span>
                <span
                  style={{
                    fontSize: "15px",
                    // width: "150px",
                    overflow: "hidden",
                  }}
                >
                  {cityName}
                </span>
                <i className="ri-arrow-down-s-line sub-div-icon"></i>
              </div>
              {dropdown1 && (
                <>
                  <div onClick={dropdownclose} className="box-div"></div>
                  <div
                    className="div-drop-down-box dropdown1Closee"
                    style={{ maxWidth: "none", width: "40%" }}
                  >
                    <div className="custom-search-car">
                      <input
                        type="text"
                        placeholder="Search"
                        style={{ width: "95%" }}
                        onChange={(event) => {
                          // console.log(cities);
                          const searchString = event.target.value.toLowerCase();
                          const filteredCities = citiesDataa.filter((item) =>
                            item?.name.toLowerCase().includes(searchString)
                          );
                          setCities(filteredCities);
                        }}
                      />
                    </div>
                    <div className="drop-down-box-1">
                      {cities?.map && (
                        <button
                          onClick={() => {
                            setDropdown1(false);
                            setCityName("All Cities");
                            localStorage.removeItem("city");
                            localStorage.removeItem("cityName");

                            const queryParams = new URLSearchParams(
                              window.location.search
                            );

                            // Remove the "fromYear" query parameter
                            queryParams.delete("city");

                            // Reconstruct the URL with the updated query parameters
                            const newUrl = `${
                              window.location.pathname
                            }?${queryParams.toString()}`;
                            window.history.pushState(
                              { path: newUrl },
                              "",
                              newUrl
                            );

                            // const newUrl = `${window.location.pathname}`;
                            // window.history.pushState(
                            //   { path: newUrl },
                            //   "",
                            //   newUrl
                            // );
                            fetchCarItems(1);
                          }}
                          style={{
                            all: "reset",
                            display: "inline-block",
                            padding: "4px 12px",
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            transition: "0.15s all ease-in-out",
                            background: "white",
                            border: "solid 1px rgb(224, 225, 227)",
                            padding: "10px 16px",
                            fontSize: "13px",
                            borderRadius: "50px",
                            width: "100%",
                          }}
                        >
                          All Cities
                        </button>
                      )}
                      {!cities && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "138px",
                          }}
                        >
                          <div class="spinner-border" role="status">
                            {/* <span class="sr-only">Loading...</span> */}
                          </div>
                        </div>
                      )}
                      {cities?.map((city, i) => {
                        return (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCityName(city?.name);
                              setcity(city?.id);
                              setSelectedCity(city?.name);
                            }}
                            key={i}
                            value={city?.id}
                          >
                            {city?.name}
                          </span>
                        );
                      })}
                    </div>
                    <div className="footer-box-1-droup-down">
                      <button onClick={applyCityFilter}>Apply Filters</button>
                    </div>
                  </div>
                </>
              )}

              <div
                onClick={dropdown2fun}
                className="filter-sub-box dropdown2Closee"
                style={{ flex: 1, borderRight: "none" }}
              >
                <span className="sub-div-span1">Make</span>
                {/* <span style={{ fontSize: "15px" }}>Select</span> */}
                <span style={{ fontSize: "15px" }}>{makeFilter}</span>
                <i className="ri-arrow-down-s-line sub-div-icon"></i>
              </div>
              {dropdown2 && (
                <>
                  <div onClick={dropdownclose} className="box-div"></div>
                  <div
                    className="div-drop-down-box1 div-drop-down-box01 dropdown2Closee"
                    style={{
                      maxWidth: "none",
                      width: "40%",
                      marginLeft: "535px",
                      zIndex: "1",
                    }}
                  >
                    <ul style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      {!brands && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "300px",
                          }}
                        >
                          <div class="spinner-border" role="status">
                            {/* <span class="sr-only">Loading...</span> */}
                          </div>
                        </div>
                      )}
                      {brands && (
                        <>
                          <li
                            onClick={() => {
                              // applyBrandFilter(brand?.id);
                              // setMake(brand?.name);
                              setMakeFilter("Select");
                              setDropdown2(false);
                              setSelectimg1("");
                              localStorage.removeItem("brand");

                              const queryParams = new URLSearchParams(
                                window.location.search
                              );

                              // Remove the "fromYear" query parameter
                              queryParams.delete("brand");

                              // Reconstruct the URL with the updated query parameters
                              const newUrl = `${
                                window.location.pathname
                              }?${queryParams.toString()}`;
                              window.history.pushState(
                                { path: newUrl },
                                "",
                                newUrl
                              );

                              // const newUrl = `${window.location.pathname}`;
                              // window.history.pushState(
                              //   { path: newUrl },
                              //   "",
                              //   newUrl
                              // );

                              fetchCarItems(1);
                            }}
                            className="listli-dropdown"
                          >
                            <span>Reset All</span>
                            {/* {selectimg1 !== brand?.id && (
                                <img className="checkimg" src={check} />
                              )} */}
                          </li>
                        </>
                      )}
                      {brands?.map((brand, i) => {
                        return (
                          <li
                            key={i}
                            onClick={() => {
                              applyBrandFilter(brand?.id, brand?.name);
                              setMakeFilter(brand?.name);
                            }}
                            className="listli-dropdown"
                          >
                            <span>{brand?.name}</span>
                            {selectimg1 == brand?.id && (
                              <img className="checkimg" src={check} />
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col lg="8" md="12" sm="12" id="colom-8">
            {dealer?.data?.length === 0 && <h2>No Dealer Found</h2>}
            {dealer?.data ? (
              dealer?.data?.slice(0, 4)?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {/* <Link
                key={i}
                // to={`${item?.id}`}
                to={`/dealer/${item?.slug}`}
                style={{ color: "inherit", textDecoration: "inherit" }}
                onClick={() => {
                  localStorage.setItem("companyID", `${item?.user_id}`);
                }}
              > */}
                    <div className="container bcontent dealer-card-wrapper respon-dealer-card-wrapper res-dealer-trans">
                      <div className="" style={{ width: "500px" }}>
                        <div
                          className="row no-gutters"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="col-sm-5 testingforImage"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="card-img"
                              style={{ objectFit: "contain" }}
                              // src={item?.featured_image}
                              src={
                                item?.image
                                  ? item?.image?.split(".")[3]?.length === 0
                                    ? "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                                    : item?.image
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                              }
                              alt={item.name}
                              onClick={() => {
                                navigate(`${item?.slug}`);
                                localStorage.setItem(
                                  "companyID",
                                  `${item?.id}`
                                );
                              }}
                            />
                            <img
                              style={{
                                width: "20px",
                                position: "absolute",
                                right: "10px",
                                top: "9px",
                                cursor: "pointer",
                              }}
                              onClick={() => favouriteCarList(item)}
                              src={
                                favouriteIcons[item?.id] ? favicon1 : favicon
                              }
                            />
                          </div>
                          {/* {console.log(item, "iiitteemm")} */}
                          {/* <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px" }}
                              >
                                {item?.name}
                              </p>
                              <p style={{ margin: "0 0 5px" }}>
                                {" "}
                                <FaLocationArrow size={11} /> {item.city}
                              </p>

                              <p
                                className="last-time-updated"
                                style={{ margin: "0 0 5px" }}
                              >
                                Updated {item?.time_ago}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px" }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "3px" }}
                                />
                                {item?.contact
                                  ? item?.contact
                                  : "not available"}
                              </p>
                            </div>
                          </div> */}
                          <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{
                                  margin: "0 0 5px",
                                  cursor: "pointer",
                                  marginBottom: "0.4rem",
                                }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* {item.brand} */}
                                {item.name}
                              </p>
                              <p
                                style={{
                                  margin: "0 0 5px",
                                  cursor: "pointer",
                                  marginBottom: "0.4rem",
                                }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* {item.brand} */}
                                {item.country}
                              </p>
                              <p
                                style={{
                                  margin: "0 0 5px",
                                  cursor: "pointer",
                                  marginBottom: "0.4rem",
                                }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {" "}
                                <FaLocationArrow
                                  size={11}
                                  className="d-inline-block mr-2"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "6px",
                                  }}
                                />
                                {/* {item.address} */}
                                {item?.address
                                  ? item?.address
                                  : "Address is not available"}
                              </p>
                              <p
                                // className="last-time-updated"
                                style={{
                                  margin: "0 0 5px",
                                  cursor: "pointer",
                                  marginBottom: "0.4rem",
                                }}
                                className="company-description"
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* Updated {item.time_ago} */}
                                {item?.description
                                  ? item?.description
                                  : "Description is not available"}
                              </p>
                              <p
                                className="contact-no"
                                style={{
                                  margin: "0 0 5px",
                                  cursor: "pointer",
                                  marginBottom: "0.4rem",
                                }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "6px" }}
                                  // style={{
                                  //   display: "inline-block",
                                  //   marginRight: "6px",
                                  // }}
                                />
                                {/* {item.contact} */}
                                {/* {item.phone_no} */}
                                {item.phone_no
                                  ? item.phone_no
                                  : "Not available"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </React.Fragment>
                );
              })
            ) : (
              <>
                {Array.from(new Array(4)).map((_, index) => {
                  return (
                    <div
                      className="container bcontent dealer-card-wrapper res-dealer-trans"
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <div className="card-feature-img">
                        <div className="row no-gutters">
                          <div
                            className="col-sm-5 placeholder-glow"
                            style={{ position: "relative" }}
                          >
                            <svg
                              className="bd-placeholder-img card-img-top placeholder"
                              width="100%"
                              height="180"
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              aria-label="Placeholder"
                              preserveAspectRatio="xMidYMid slice"
                              focusable="false"
                            >
                              <title>Placeholder</title>
                              <rect
                                width="100%"
                                height="100%"
                                fill="#2e3192"
                              ></rect>
                            </svg>
                          </div>

                          <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title card-title placeholder-glow"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span
                                  className="placeholder col-9"
                                  style={{
                                    backgroundColor: "#2e3192",
                                  }}
                                ></span>
                              </p>
                              <p
                                style={{ margin: "0 0 5px" }}
                                className="placeholder-glow"
                              >
                                <span
                                  className="placeholder col-9"
                                  style={{
                                    backgroundColor: "#2e3192",
                                  }}
                                ></span>
                              </p>
                              <p
                                style={{ margin: "0 0 5px" }}
                                className="company-description placeholder-glow"
                              >
                                <span
                                  className="placeholder col-9"
                                  style={{
                                    backgroundColor: "#2e3192",
                                  }}
                                ></span>
                              </p>
                              <p
                                className="contact-no placeholder-glow"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span
                                  className="placeholder col-9"
                                  style={{
                                    backgroundColor: "#2e3192",
                                  }}
                                ></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            <div
              ref={midBannerRef}
              className="mt-3"
              style={{ position: "relative" }}
            >
              <Slider {...settings} afterChange={afterMidBannerChangeHandler}>
                {carMidBanner?.data?.length == 0 && (
                  <>
                    <img className="banner-btm" src={banner2} />
                  </>
                )}
                {carMidBanner?.data?.length > 0 &&
                  carMidBanner?.data?.map((midBanner, index) => {
                    return (
                      <React.Fragment key={index}>
                        {midBanner?.image && (
                          <>
                            <img
                              src={midBanner?.image}
                              key={index}
                              className="banner-btm"
                              onClick={() =>
                                openBannerLinkPage(
                                  midBanner?.link,
                                  midBanner?.target,
                                  midBanner?.id,
                                  "Yes",
                                  "No"
                                )
                              }
                              data-mid-banner-id={midBanner?.id}
                            />
                            <img
                              src={Info}
                              alt=""
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                cursor: "pointer",
                                backgroundColor: "#fff",
                                borderRadius: "16px",
                              }}
                              onClick={(e) =>
                                handlemidBannerRef(e, midBanner?.id)
                              }
                            />
                          </>
                        )}
                        {midBanner?.script && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: midBanner.script,
                            }}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
              </Slider>
            </div>
            {dealer?.data?.length &&
              dealer?.data?.slice(4, 7)?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {/* <Link
                key={i}
                // to={`${item?.id}`}
                to={`/dealer/${item?.slug}`}
                style={{ color: "inherit", textDecoration: "inherit" }}
                onClick={() => {
                  localStorage.setItem("companyID", `${item?.user_id}`);
                }}
              > */}
                    <div className="container bcontent dealer-card-wrapper respon-dealer-card-wrapper res-dealer-trans">
                      <div className="" style={{ width: "500px" }}>
                        <div className="row no-gutters">
                          <div
                            className="col-sm-5 testingforImage"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="card-img"
                              style={{ objectFit: "contain" }}
                              // src={item?.featured_image}
                              // src={
                              //   item?.featured_image
                              //     ? item?.featured_image
                              //     : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                              // }
                              src={
                                item?.image
                                  ? // ? item?.image
                                    item?.image?.split(".")[3]?.length === 0
                                    ? "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                                    : item?.image
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                              }
                              alt={item.name}
                              onClick={() => {
                                navigate(`${item?.slug}`);
                                localStorage.setItem(
                                  "companyID",
                                  `${item?.id}`
                                );
                              }}
                            />
                            <img
                              style={{
                                width: "20px",
                                position: "absolute",
                                right: "10px",
                                top: "9px",
                                cursor: "pointer",
                              }}
                              onClick={() => favouriteCarList(item)}
                              src={
                                favouriteIcons[item?.id] ? favicon1 : favicon
                              }
                            />
                          </div>
                          {/* <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px" }}
                              >
                                {item?.name}
                              </p>
                              <p style={{ margin: "0 0 5px" }}>
                                {" "}
                                <FaLocationArrow size={11} /> {item.city}
                              </p>

                              <p
                                className="last-time-updated"
                                style={{ margin: "0 0 5px" }}
                              >
                                Updated {item?.time_ago}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px" }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "3px" }}
                                />
                                {item?.contact
                                  ? item?.contact
                                  : "not available"}
                              </p>
                            </div>
                          </div> */}

                          <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* {item.brand} */}
                                {item.name}
                              </p>
                              <p
                                style={{
                                  margin: "0 0 5px",
                                  cursor: "pointer",
                                  marginBottom: "0.4rem",
                                }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* {item.brand} */}
                                {item.country}
                              </p>
                              <p
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {" "}
                                <FaLocationArrow
                                  size={11}
                                  className="d-inline-block mr-2"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "6px",
                                  }}
                                />
                                {/* {item.address} */}
                                {item?.address
                                  ? item?.address
                                  : "Address is not available"}
                              </p>
                              <p
                                // className="last-time-updated"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                className="company-description"
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* Updated {item.time_ago} */}
                                {item?.description
                                  ? item?.description
                                  : "Description is not available"}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "6px" }}
                                  // style={{
                                  //   display: "inline-block",
                                  //   marginRight: "6px",
                                  // }}
                                />
                                {/* {item.contact} */}
                                {/* {item.phone_no} */}
                                {item.phone_no
                                  ? item.phone_no
                                  : "Not available"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </React.Fragment>
                );
              })}

            {/* bottom */}
            <div ref={bottomBannerRef} style={{ position: "relative" }}>
              <Slider
                {...settings}
                afterChange={afterBottomBannerChangeHandler}
              >
                {carBottomBanner?.data?.length === 0 && (
                  <>
                    <img className="banner-btm" src={banner1} />
                  </>
                )}
                {carBottomBanner?.data?.length > 0 &&
                  carBottomBanner?.data?.map((bottomBanner, index) => {
                    return (
                      <React.Fragment key={index}>
                        {bottomBanner?.image && (
                          <>
                            <img
                              src={bottomBanner?.image}
                              key={index}
                              className="banner-btm"
                              onClick={() =>
                                openBannerLinkPage(
                                  bottomBanner?.link,
                                  bottomBanner?.target,
                                  bottomBanner?.id,
                                  "Yes",
                                  "No"
                                )
                              }
                              data-bottom-banner-id={bottomBanner?.id}
                            />
                            <img
                              src={Info}
                              alt=""
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                cursor: "pointer",
                                backgroundColor: "#fff",
                                borderRadius: "16px",
                              }}
                              onClick={(e) =>
                                handlemidBannerRef(e, bottomBanner?.id)
                              }
                            />
                          </>
                        )}
                        {bottomBanner?.script && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: bottomBanner.script,
                            }}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    );
                    // }
                  })}
              </Slider>
            </div>
            {dealer?.data?.length &&
              dealer?.data?.slice(7, 10)?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {/* <Link
                key={i}
                // to={`${item?.id}`}
                to={`/dealer/${item?.slug}`}
                style={{ color: "inherit", textDecoration: "inherit" }}
                onClick={() => {
                  localStorage.setItem("companyID", `${item?.user_id}`);
                }}
              > */}
                    <div
                      className="container bcontent dealer-card-wrapper respon-dealer-card-wrapper res-dealer-trans"
                      onClick={() => {
                        navigate(`${item?.slug}`);
                        localStorage.setItem("companyID", `${item?.id}`);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="" style={{ width: "500px" }}>
                        <div className="row no-gutters">
                          <div
                            className="col-sm-5 testingforImage"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="card-img"
                              // src={item?.featured_image}
                              style={{ objectFit: "contain" }}
                              src={
                                item?.image
                                  ? // ? item?.image
                                    item?.image?.split(".")[3]?.length === 0
                                    ? "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                                    : item?.image
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                              }
                              // src={
                              //   item?.image !== null
                              //     ? item?.image
                              //     : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                              // }
                              alt={item.name}
                              onClick={() => {
                                navigate(`${item?.slug}`);
                                localStorage.setItem(
                                  "companyID",
                                  `${item?.id}`
                                );
                              }}
                            />
                            <img
                              style={{
                                width: "20px",
                                position: "absolute",
                                right: "10px",
                                top: "9px",
                                cursor: "pointer",
                              }}
                              onClick={() => favouriteCarList(item)}
                              src={
                                favouriteIcons[item?.id] ? favicon1 : favicon
                              }
                            />
                          </div>
                          {/* <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px" }}
                              >
                                {item?.name}
                              </p>
                              <p style={{ margin: "0 0 5px" }}>
                                {" "}
                                <FaLocationArrow size={11} /> {item.city}
                              </p>

                              <p
                                className="last-time-updated"
                                style={{ margin: "0 0 5px" }}
                              >
                                Updated {item?.time_ago}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px" }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "3px" }}
                                />
                                {item?.contact
                                  ? item?.contact
                                  : "not available"}
                              </p>
                            </div>
                          </div> */}
                          <div className="col-sm-7 mt-2">
                            <div className="dealer-card-body">
                              <p
                                className="card-title"
                                style={{ margin: "0 0 5px" }}
                              >
                                {/* {item.brand} */}
                                {item.name}
                              </p>
                              <p
                                style={{
                                  margin: "0 0 5px",
                                  cursor: "pointer",
                                  marginBottom: "0.4rem",
                                }}
                                onClick={() => {
                                  navigate(`${item?.slug}`);
                                  localStorage.setItem(
                                    "companyID",
                                    `${item?.id}`
                                  );
                                }}
                              >
                                {/* {item.brand} */}
                                {item.country}
                              </p>
                              <p style={{ margin: "0 0 5px" }}>
                                {" "}
                                <FaLocationArrow
                                  size={11}
                                  className="d-inline-block mr-2"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "6px",
                                  }}
                                />
                                {/* {item.address} */}
                                {item?.address !== "null"
                                  ? item?.address
                                  : "Address is not available"}
                              </p>
                              <p
                                // className="last-time-updated"
                                style={{ margin: "0 0 5px" }}
                                className="company-description"
                              >
                                {/* Updated {item.time_ago} */}
                                {item?.description !== "null"
                                  ? item?.description
                                  : "Description is not available"}
                              </p>
                              <p
                                className="contact-no"
                                style={{ margin: "0 0 5px" }}
                              >
                                <FaPhone
                                  size={11}
                                  style={{ marginRight: "6px" }}
                                  // style={{
                                  //   display: "inline-block",
                                  //   marginRight: "6px",
                                  // }}
                                />
                                {/* {item.contact} */}
                                {/* {item.phone_no} */}
                                {item.phone_no
                                  ? item.phone_no
                                  : "Not available"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </React.Fragment>
                );
              })}

            <div className="container mt-4">
              <Pagination
                activePage={dealer?.current_page || 1}
                totalItemsCount={dealer?.total ?? 0}
                itemsCountPerPage={parseInt(dealer?.per_page)}
                onChange={(pageNumber) => getFilters(pageNumber, filters)}
                pageRangeDisplayed={5}
                // activePage={10}
                // totalItemsCount={10}
                // itemsCountPerPage={parseInt(10)}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First"
                lastPageText="Last"
                prevPageText="Previous"
                nextPageText="Next"
                activeClass="active"
                activeLinkClass="active-link"
              />
            </div>
          </Col>
          <Col style={{ zIndex: "-1" }} id="colom-4" lg="4" md="4" sm="6">
            <div ref={topSidebarBannerRef} style={{ position: "relative" }}>
              <Slider
                style={{ zIndex: "-1" }}
                {...settings}
                afterChange={afterTopSidebarBannerChangeHandler}
              >
                {carTopSidebarBanner?.data?.length == 0 && (
                  <div
                    style={{
                      position: "relative",
                      margin: "40px auto 0 43px !important",
                      width: "270px",
                    }}
                    // className="mt-3"
                  >
                    <div
                      style={{
                        position: "relative",
                        margin: "40px auto 0 43px !important",
                        width: "270px",
                      }}
                      // className="mt-3"
                    >
                      <img
                        className="sidebanner-slider-img"
                        style={{
                          position: "relative",
                          margin: "40px auto 0 43px !important",
                          width: "270px !important",
                          display: "block",
                          margin: "0px auto 0 43px",
                        }}
                        src={banner3}
                      />
                    </div>
                  </div>
                )}

                {carTopSidebarBanner?.data == undefined && (
                  <>
                    <div
                      // className="slider-car-responsive col-sm-12 placeholder-glow sidebanner-slider-img"
                      className="col-sm-12 placeholder-glow sidebanner-slider-img"
                      style={{
                        position: "relative",
                        // width: "730px",
                        // height: "570px",
                      }}
                    >
                      <svg
                        className="bd-placeholder-img card-img-top placeholder"
                        width="100%"
                        height="1370"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        aria-label="Placeholder"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                      >
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#2e3192"></rect>
                      </svg>
                    </div>
                  </>
                )}

                {carTopSidebarBanner?.data?.length > 0 &&
                  carTopSidebarBanner?.data?.map((topSidebarBanner, index) => {
                    return (
                      <React.Fragment key={index}>
                        {topSidebarBanner?.image && (
                          <>
                            <div
                              style={{
                                position: "relative",
                                margin: "40px auto 0 43px",
                                width: "270px",
                              }}
                            >
                              <img
                                src={topSidebarBanner?.image}
                                key={index}
                                className="sidebanner-slider-img"
                                onClick={() =>
                                  openBannerLinkPage(
                                    topSidebarBanner?.link,
                                    topSidebarBanner?.target,
                                    topSidebarBanner?.id,
                                    "Yes",
                                    "No"
                                  )
                                }
                                data-top-sidebar-banner-id={
                                  topSidebarBanner?.id
                                }
                              />
                              <img
                                src={Info}
                                alt=""
                                style={{
                                  // position: "absolute",
                                  // top: 53,
                                  // // right: 55,
                                  // right: 115,
                                  // cursor: "pointer",
                                  // backgroundColor: "#fff",
                                  // borderRadius: "16px",
                                  position: "absolute",
                                  top: "15px",
                                  right: "15px",
                                  cursor: "pointer",
                                  backgroundColor: "rgb(255, 255, 255)",
                                  borderRadius: "16px",
                                }}
                                onClick={(e) =>
                                  handlemidBannerRef(e, topSidebarBanner?.id)
                                }
                              />
                            </div>
                          </>
                        )}
                        {topSidebarBanner?.script && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: topSidebarBanner.script,
                            }}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    );
                    // }
                  })}
              </Slider>
            </div>

            <div ref={bottomSiderbarBannerRef} style={{ position: "relative" }}>
              <Slider
                {...settings}
                afterChange={afterBottomSidebarBannerChangeHandler}
              >
                {carBottomSidebarBanner?.data?.length == 0 && (
                  <img className="sidebanner-slider-img" src={banner4} />
                )}

                {carBottomSidebarBanner?.data == undefined && (
                  <>
                    <div
                      // className="slider-car-responsive col-sm-12 placeholder-glow sidebanner-slider-img"
                      className="col-sm-12 placeholder-glow sidebanner-slider-img mt-5"
                      style={{
                        position: "relative",
                        // width: "730px",
                        // height: "570px",
                      }}
                    >
                      <svg
                        className="bd-placeholder-img card-img-top placeholder"
                        width="100%"
                        height="1370"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        aria-label="Placeholder"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                      >
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#2e3192"></rect>
                      </svg>
                    </div>
                  </>
                )}

                {carBottomSidebarBanner?.data?.length > 0 &&
                  carBottomSidebarBanner?.data?.map(
                    (bottomSidebarBanner, index) => {
                      return (
                        <React.Fragment key={index}>
                          {bottomSidebarBanner?.image && (
                            <>
                              <div
                                style={{
                                  position: "relative",
                                  margin: "40px auto 0 43px",
                                  width: "270px",
                                }}
                                className="mt-5"
                              >
                                <img
                                  src={bottomSidebarBanner?.image}
                                  key={index}
                                  className="sidebanner-slider-img"
                                  onClick={() =>
                                    openBannerLinkPage(
                                      bottomSidebarBanner?.link,
                                      bottomSidebarBanner?.target,
                                      bottomSidebarBanner?.id,
                                      "Yes",
                                      "No"
                                    )
                                  }
                                  data-bottom-sidebar-banner-id={
                                    bottomSidebarBanner?.id
                                  }
                                />
                                <img
                                  src={Info}
                                  alt=""
                                  style={{
                                    // position: "absolute",
                                    // top: 50,
                                    // // right: 53,
                                    // right: 115,
                                    // cursor: "pointer",
                                    position: "absolute",
                                    top: "15px",
                                    right: "15px",
                                    cursor: "pointer",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    borderRadius: "16px",
                                    zIndex: "999999 !important",
                                  }}
                                  onClick={(e) =>
                                    handlemidBannerRef(
                                      e,
                                      bottomSidebarBanner?.id
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}
                          {bottomSidebarBanner?.script && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: bottomSidebarBanner.script,
                              }}
                              key={index}
                            />
                          )}
                        </React.Fragment>
                      );
                      // }
                    }
                  )}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="container mt-4">
        {/* <Pagination
          activePage={dealer?.current_page || 1}
          totalItemsCount={dealer?.total ?? 0}
          itemsCountPerPage={parseInt(dealer?.per_page)}
          onChange={(pageNumber) => getFilters(pageNumber, filters)}
          pageRangeDisplayed={5}
          // activePage={10}
          // totalItemsCount={10}
          // itemsCountPerPage={parseInt(10)}
          itemClass="page-item"
          linkClass="page-link"
          firstPageText="First"
          lastPageText="Last"
          prevPageText="Previous"
          nextPageText="Next"
          activeClass="active"
          activeLinkClass="active-link"
        /> */}
      </div>

      {/* side bannner */}
    </>
  );
};

export default Company;
