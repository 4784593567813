import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/cardsitemlista.css";
import { useNavigate } from "react-router-dom";
import favicon from "../../assets/all-images/white-heart.png";
import favicon1 from "../../assets/all-images/red-heart.png";
import { toast } from "react-toastify";

const Cardsitemlista = ({ img, data, activeTab }) => {
  let navigate = useNavigate();
  const [favouriteIcon, setFavouriteIcon] = useState(false);
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo")); //GET LOGIN INFO
  let token;
  let userId;
  if (loginInfo) {
    token = loginInfo["token"]; //GET TOKEN
    userId = loginInfo["user"]["id"];
  }

  useEffect(() => {
    checkFavoruiteCar(data?.id);
  }, []);

  const favouriteCarList = async (id) => {
    if (userId) {
      const data = new FormData();
      data.append("user_id", userId);
      data.append("subject_id", id);
      await fetch(process.env.REACT_APP_URL + "/favorite", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message == "Added To Favourites") {
            setFavouriteIcon(true);
            toast.success(data.message);
          } else {
            setFavouriteIcon(false);
            toast.success(data.message);
          }
        });
    } else {
      toast.error("Please Login");
      setTimeout(() => {
        window.location.href = "https://cars.me/web-app/";
      }, 1000);
    }
  };

  // const checkFavoruiteCar = async (id) => {
  //   await fetch(
  //     process.env.REACT_APP_URL +
  //       `/check-favourite-car?user_id=${userId}&subject_id=${id}`,
  //     {
  //       method: "GET",
  //       headers: new Headers({
  //         Accept: "application/json",
  //       }),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(id, data);
  //       if (data.data == "true") {
  //         setFavouriteIcon(true);
  //       } else {
  //         setFavouriteIcon(false);
  //       }
  //     });
  // };

  const checkFavoruiteCar = async (id) => {
    if (userId) {
      await fetch(
        process.env.REACT_APP_URL +
          `/check-favourite-car?user_id=${userId}&subject_id=${id}`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.data == "true") {
            setFavouriteIcon(true);
          } else {
            setFavouriteIcon(false);
          }
        });
    }
  };

  const updateCarClick = async (slug, id) => {
    await fetch(process.env.REACT_APP_URL + `/update-car-clicks?id=${id}`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {});
    // console.log(activeTab, "cardItemactiveTab");

    if (activeTab === "all") {
      if (data?.type === "New") {
        navigate(`/new-car/${data?.slug}`);
      } else if (data?.type === "Used") {
        navigate(`/used-car/${data?.slug}`);
      }
      // navigate(`/car-detail/${slug}`);
    } else if (activeTab === "new") {
      navigate(`/new-car/${slug}`);
    } else if (activeTab === "old") {
      navigate(`/used-car/${slug}`);
    }
  };
  return (
    <Col lg="12" md="12" sm="12" className="cardscarlist">
      <div className="usedCarContainerMain">
        <div className="usedCarWrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
            }}
            className="imageBox card-img-wrapper"
          >
            <img
              style={{
                width: "20px",
                position: "absolute",
                right: "10px",
                top: "9px",
                cursor: "pointer",
              }}
              onClick={() => favouriteCarList(data?.id)}
              src={favouriteIcon ? favicon1 : favicon}
            />
            <img
              src={img}
              style={{
                // cursor: "pointer",
                // minWidth: "100%",
                // // width: "auto",
                // height: "auto",
                // // maxHeight: "200px",
                // // maxWidth: "300px",
                // display: "block",
                // margin: "0 auto",
                // borderRadius: "unset",
                height: "auto",
                maxWidth: "100%",
              }}
              className="card-img-top1"
              alt="..."
              onClick={() => {
                updateCarClick(data.slug, data.id);
              }}
            />
          </div>

          <div className="carDetailBox">
            <div className="carNameContainer">
              <p
                className="carHeading"
                onClick={() => {
                  updateCarClick(data.slug, data.id);
                }}
                style={{ cursor: "pointer", marginBottom: "0.8rem" }}
              >
                {data?.name}
              </p>
              {/* <p className="headingRight">PREMIUM</p> */}
            </div>
            <div className="cardDetailWrapper">
              <div>
                <p
                  className="locationTypo"
                  style={{
                    marginBottom: 0,
                    cursor: "pointer",
                    marginBottom: "0.8rem",
                  }}
                  onClick={() => {
                    updateCarClick(data.slug, data.id);
                  }}
                >
                  {data?.country}
                </p>
              </div>
              <div>
                <p
                  className="locationTypo"
                  onClick={() => {
                    updateCarClick(data.slug, data.id);
                  }}
                  style={{ cursor: "pointer", marginBottom: "0.8rem" }}
                >
                  {" "}
                  {data?.brand}
                </p>
              </div>
              <div
                className="cardSpecContainer"
                onClick={() => {
                  updateCarClick(data.slug, data.id);
                }}
                style={{ cursor: "pointer" }}
              >
                {/* {console.log(data, "data brand")} */}
                <p className="locationTypo" style={{ marginBottom: "0.8rem" }}>
                  {data?.year}
                </p>
                <p className="locationTypo" style={{ marginBottom: "0.8rem" }}>
                  {data?.meter_counter} km
                </p>
                <p className="locationTypo" style={{ marginBottom: "0.8rem" }}>
                  {data?.engine_type}
                </p>
                <p className="locationTypo" style={{ marginBottom: "0.8rem" }}>
                  {data?.power_type?.length > 5
                    ? data?.power_type?.slice(0, 4) + "..."
                    : data?.power_type}{" "}
                  cc
                </p>
                <p className="locationTypo" style={{ marginBottom: "0.8rem" }}>
                  {data?.transmission}
                </p>
              </div>
              <div className="updatedBox">
                <p
                  className="upatedtxt"
                  onClick={() => {
                    updateCarClick(data.slug, data.id);
                  }}
                  style={{ cursor: "pointer", marginBottom: "0.8rem" }}
                >
                  Updated {data?.time_ago}
                </p>
                {/* <button className="showPhone btnpadding">Show Phone No.</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="cardDetailWrapper">
        <div>
          <p className="locationTypo">{data?.country}</p>
        </div>
        <div className="cardSpecContainer">
          <p className="locationTypo">{data?.year}</p>
          <p className="locationTypo">{data?.meter_counter} km</p>
          <p className="locationTypo">{data?.engine_type}</p>
          <p className="locationTypo">{data?.power_type} cc</p>
          <p className="locationTypo">{data?.transmission}</p>
        </div>
        <div className="updatedBox">
          <p className="upatedtxt">Updated {data?.time_ago}</p>
        </div>
      </div> */}
    </Col>
  );
};

export default Cardsitemlista;
// export default Cardsitemlista;
