import React, { useEffect, useState, useRef } from "react";

import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";
import Slider from "react-slick";

import { Container, Row, Col } from "reactstrap";
import FindCarForm from "../components/UI/FindCarForm";
import AboutSection from "../components/UI/AboutSection";
import ServicesList from "../components/UI/ServicesList";
import carData from "../assets/data/carData";
import CarItem from "../components/UI/CarItem";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";
import Testimonial from "../components/UI/Testimonial";
import BrowseUsedCars from "../components/UI/BrowseUsedCars";
import { FaReact } from "react-icons/fa";
import favicon from "../assets/all-images/favorite-icon-png-0.jpg";
import AutoStoreCarPartsAccessories from "../components/UI/AutoStoreCarPartsAccessories";
import BlogList from "../components/UI/BlogList";
import NewCarsbyMake from "../components/UI/NewCarsbyMake";
import banner from "../assets/all-images/20890892.jpg";
import banner2 from "../assets/all-images/8254862.jpg";
import BootstrapModals from "../components/Modal/Modal";
import { toast } from "react-toastify";
import Info from "../assets/all-images/infoIcon.svg";
import "../styles/home.css";
const Home = () => {
  const settings = {
    dots: true,
    // infinite: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 587,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  let topBannerSliderCondition2;
  const [topBannerSliderCondition, setTopBannerSliderCondition] =
    useState(null);
  let bottomBannerSliderCondition2;
  const [bottomBannerSliderCondition, setBottomBannerSliderCondition] =
    useState(null);
  const [homeTopBanner, setHomeTopBanner] = useState();
  const [homeBottomBanner, setHomeBottomBanner] = useState();
  const [featuredUsedCars, setFeaturedUsedCars] = useState([]);
  const topBannerRef = useRef();
  const bottomBannerRef = useRef();
  const observer = useRef(null);
  const observer2 = useRef(null);

  useEffect(() => {
    localStorage.removeItem("brandId");
    localStorage.removeItem("fromPrice");
    localStorage.removeItem("countryId");
    localStorage.removeItem("transmission");
    localStorage.removeItem("toPrice");
    localStorage.removeItem("fromYear");
    localStorage.removeItem("toYear");
    localStorage.removeItem("fromMeterCounter");
    localStorage.removeItem("toMeterCounter");
    localStorage.removeItem("modelId");
    localStorage.removeItem("cityId");
    localStorage.removeItem("cityName");
    fetchHomeTopBanner();
    fetchHomeBottomBanner();
    fetchFeaturedUsedCars();
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeBannerId = document.querySelector(
            ".slick-active [data-top-banner-id]"
          );
          setTopBannerSliderCondition("abc");
          // console.log("Active Banner ID:", activeBannerId?.getAttribute('data-banner-id'));
          openBannerLinkPage(
            null,
            "0",
            activeBannerId?.getAttribute("data-top-banner-id"),
            "No",
            "Yes"
          );
        } else {
          setTopBannerSliderCondition("");
        }
      });
    });
    observer2.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeBannerId = document.querySelector(
            ".slick-active [data-bottom-banner-id]"
          );
          setBottomBannerSliderCondition("abc");
          // console.log("Active Banner ID:", activeBannerId?.getAttribute('data-banner-id'));
          openBannerLinkPage(
            null,
            "0",
            activeBannerId?.getAttribute("data-bottom-banner-id"),
            "No",
            "Yes"
          );
        } else {
          setBottomBannerSliderCondition("");
        }
      });
    });

    if (topBannerRef.current) {
      observer.current.observe(topBannerRef.current);
    }

    if (bottomBannerRef.current) {
      observer2.current.observe(bottomBannerRef.current);
    }

    return () => {
      observer.current.disconnect();
      observer2.current.disconnect();
    };
  }, []);

  const afterTopBannerChangeHandler = (currentSlide) => {
    const activeSlide = homeTopBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      topBannerSliderCondition2 = "xyz";
      if (
        topBannerSliderCondition === "abc" &&
        topBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
    }
  };
  let token;
  const afterBottomBannerChangeHandler = (currentSlide) => {
    const activeSlide = homeBottomBanner?.data?.[currentSlide];
    if (activeSlide) {
      const activeBannerId = activeSlide.id;
      bottomBannerSliderCondition2 = "xyz";
      if (
        bottomBannerSliderCondition === "abc" &&
        bottomBannerSliderCondition2 === "xyz"
      ) {
        openBannerLinkPage(null, "0", activeBannerId, "No", "Yes");
      }
      // Banner has changed through the slider and is on the screen
    }
  };

  const fetchHomeTopBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-banner?page_slug=home&position=Top",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHomeTopBanner(data.data); //get data in data
      });
  };
  const fetchHomeBottomBanner = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-banner?page_slug=home&position=Bottom",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHomeBottomBanner(data.data); //get data in data
      });
  };

  const fetchFeaturedUsedCars = async () => {
    await fetch(
      process.env.REACT_APP_URL + "/get-cars?is_featured=1&type=Used",
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setFeaturedUsedCars(data.data);
      });
  };

  const openBannerLinkPage = async (link, target, id, click, impression) => {
    await fetch(
      process.env.REACT_APP_URL +
        "/update-banner-clicks-and-impressions?id=" +
        id +
        "&click=" +
        click +
        "&impression=" +
        impression,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {});
    if (link) {
      if (target == 1) {
        window.open(link, "_blank");
      } else {
        window.location.href = link;
      }
    }
  };

  const [bannerId, setBannerId] = useState();

  const handlemidBannerRef = (e, id) => {
    setBannerId(id);
    setModalOpen(true);
  };
  const handlemidBannerRefClose = (e) => {
    // console.log(e);
    setModalOpen(false);
  };

  // banner calls end
  const [name1, setName1] = useState();
  const [email1, setEmail1] = useState();
  const [message1, setMessage1] = useState();
  const [contant1, setContant1] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // console.log(name1, email1, message1, contant1);

    if (
      name1 != "" &&
      name1 != undefined &&
      name1 != null &&
      email1 != "" &&
      email1 != undefined &&
      email1 != null &&
      message1 != "" &&
      message1 != undefined &&
      message1 != null &&
      contant1 != "" &&
      contant1 != undefined &&
      contant1 != null
    ) {
      // bannner data
      // IF INPUTS EMPTY OR NULL OR UNDEFINED
      const data = new FormData();
      data.append("name", name1);
      data.append("email", email1);
      data.append("message", message1);
      data.append("contact", contant1);
      data.append("banner_id", bannerId);
      fetch(process.env.REACT_APP_URL + "/create-banner-complaint", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);

          toast.success(data.message);
          setModalOpen(false);
          setName1();
          setEmail1();
          setMessage1();
          setContant1();
        });
      // .catch((e) => console.log(e));
    } else {
      toast.error("Invalid details");
    }
  };

  return (
    <Helmet title="Home">
      <BootstrapModals
        isOpen={modalOpen}
        onRequestClose={handlemidBannerRefClose}
        name={name1}
        setName={setName1}
        email={email1}
        setEmail={setEmail1}
        message={message1}
        setMessage={setMessage1}
        contact={contant1}
        setContact={setContant1}
        handleSubmit={handleSubmit1}
      />
      {/* ============= hero section =========== */}
      <section className="p-0 hero__slider-section mr-hero__slider-section">
        <HeroSlider />

        <div className="hero__form">
          <Container>
            <Row className="form__row">
              <Col lg="4" md="4">
                <div className="find__cars-left"></div>
              </Col>
              <Col lg="8" md="8" sm="12">
                <FindCarForm />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="section-margin-heading">
        <Container>
          <Row>
            <Col lg="12" className="text-center ">
              <h2
                className="section__title Categoriestitle section-margin-feature-car-heading  "
                // style={{ marginBottom: "32px" }}
                // className="section-margin-feature-car-heading "
              >
                Featured Used Cars
              </h2>
            </Col>
            {featuredUsedCars?.data ? (
              featuredUsedCars?.data?.slice(0, 6).map((car, index) => {
                return (
                  <React.Fragment key={index}>
                    <CarItem
                      car={car}
                      key={car.id}
                      style={{ objectFit: "contain" }}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <>
                {Array.from(new Array(6)).map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Col lg="4" md="4" sm="6" className="mb-5">
                        <div
                          style={{ position: "relative" }}
                          className="car__item"
                        >
                          <div
                            className="car__img placeholder-glow"
                            style={{ cursor: "pointer" }}
                          >
                            {/* <img
                            // src={car?.featured_image}
                            alt=""
                            className="w-100"
                            width="314"
                            height="167"
                          /> */}
                            <svg
                              className="bd-placeholder-img card-img-top placeholder"
                              width="100%"
                              height="180"
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              aria-label="Placeholder"
                              preserveAspectRatio="xMidYMid slice"
                              focusable="false"
                            >
                              <title>Placeholder</title>
                              <rect
                                width="100%"
                                height="100%"
                                fill="#2e3192"
                              ></rect>
                            </svg>
                          </div>
                          <div
                            className="car__item-content mt-4 placeholder-glow"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(3,1fr)",
                              gridColumnGap: "10px",
                            }}
                          >
                            <h6
                              className="section__title text-center  placeholder"
                              style={{
                                backgroundColor: "#2e3192",
                              }}
                            ></h6>
                            <h6
                              className="rent__price text-center mt-  placeholder"
                              style={{
                                backgroundColor: "#2e3192",
                              }}
                            ></h6>
                            <h6
                              className="rent__price text-center mt-  placeholder"
                              style={{
                                backgroundColor: "#2e3192",
                              }}
                            ></h6>
                          </div>
                        </div>
                      </Col>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Row>
        </Container>
      </section>
      <section
        id="slider1"
        ref={topBannerRef}
        className="section-margin section-margin-heading"
        // className="section-margin"
        // style={{
        //   padding: "0  !important",
        //   paddingBottom: 0,
        //   marginBottom: "48px",
        // }}
      >
        <Container>
          <Row style={{ position: "relative" }}>
            <Slider
              style={{ height: "385px", with: "100%" }}
              {...settings}
              afterChange={afterTopBannerChangeHandler}
            >
              {homeTopBanner?.data?.length == 0 && (
                <img
                  style={{ display: "block", margin: "0 auto" }}
                  src={banner}
                />
              )}
              {homeTopBanner?.data == undefined && (
                // <img className="sidebanner-slider-img" src={banner3} />
                <>
                  <div
                    // className="col-sm-12  slider-car-responsive col-sm-12 placeholder-glow sidebanner-slider-img"
                    className="placeholder-glow sidebanner-slider-img w-100"
                    style={{
                      position: "relative",
                      // width: "730px",
                      // height: "570px",
                      width: "100%",
                    }}
                  >
                    <svg
                      className="bd-placeholder-img card-img-top placeholder"
                      width="100%"
                      height="420"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#2e3192"></rect>
                    </svg>
                  </div>
                </>
              )}
              {homeTopBanner?.data?.length > 0 &&
                homeTopBanner?.data?.map((topBanner, index) => {
                  return (
                    <React.Fragment key={index}>
                      {topBanner?.image && (
                        <>
                          <img
                            src={topBanner?.image}
                            key={index}
                            style={{
                              width: "1116px",
                              height: "420px",
                              display: "block",
                              margin: "0 auto",
                            }}
                            className="banner-mobile-view"
                            onClick={() =>
                              openBannerLinkPage(
                                topBanner?.link,
                                topBanner?.target,
                                topBanner?.id,
                                "Yes",
                                "No"
                              )
                            }
                            data-top-banner-id={topBanner?.id}
                          />
                          <img
                            src={Info}
                            alt=""
                            className="iconforModal"
                            style={{
                              position: "absolute",
                              top: 10,
                              right: 10,
                              cursor: "pointer",
                              backgroundColor: "#fff",
                              borderRadius: "16px",
                            }}
                            onClick={(e) =>
                              handlemidBannerRef(e, topBanner?.id)
                            }
                          />
                        </>
                      )}
                      {topBanner?.script && (
                        <div
                          dangerouslySetInnerHTML={{ __html: topBanner.script }}
                          key={index}
                        />
                      )}
                    </React.Fragment>
                  );
                  // }
                })}
            </Slider>
          </Row>
        </Container>
      </section>
      <section className="section-margin">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title sm-browser-car-heading">
                Browse Used Cars
              </h2>
            </Col>
            <BrowseUsedCars />
          </Row>
        </Container>
      </section>
      <BecomeDriverSection />
      <section className="section-margin">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title" style={{ marginBottom: "32px" }}>
                Auto Store Car Parts & Accessories
              </h2>
            </Col>
            <AutoStoreCarPartsAccessories />
          </Row>
        </Container>
      </section>
      <section id="slider2" ref={bottomBannerRef} className="section-margin">
        <Container>
          <Row>
            <Slider
              style={{ height: "385px", with: "100%" }}
              {...settings}
              afterChange={afterBottomBannerChangeHandler}
            >
              {homeBottomBanner?.data?.length == 0 && (
                <img
                  style={{ display: "block", margin: "0 auto" }}
                  src={banner2}
                />
              )}
              {homeBottomBanner?.data?.length > 0 &&
                homeBottomBanner?.data?.map((bottomBanner, index) => {
                  return (
                    <React.Fragment key={index}>
                      {bottomBanner?.image && (
                        <>
                          <img
                            src={bottomBanner?.image}
                            key={index}
                            style={{
                              width: "1116px",
                              height: "420px",
                              display: "block",
                              margin: "0 auto",
                            }}
                            className="banner-mobile-view"
                            onClick={() =>
                              openBannerLinkPage(
                                bottomBanner?.link,
                                bottomBanner?.target,
                                bottomBanner?.id,
                                "Yes",
                                "No"
                              )
                            }
                            data-bottom-banner-id={bottomBanner?.id}
                          />
                          <img
                            src={Info}
                            alt=""
                            className="iconforModal"
                            style={{
                              position: "absolute",
                              top: 10,
                              right: 10,
                              cursor: "pointer",
                              backgroundColor: "#fff",
                              borderRadius: "16px",
                            }}
                            onClick={(e) =>
                              handlemidBannerRef(e, bottomBanner?.id)
                            }
                          />
                        </>
                      )}
                      {bottomBanner?.script && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: bottomBanner.script,
                          }}
                          key={index}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
            </Slider>
          </Row>
        </Container>
      </section>
      {/* <section style={{ height: "555px", padding: 0 }}> */}
      <section className="section-margin">
        <Container
          style={{ height: "455px !important", width: "100% !important" }}
        >
          {/* <Row style={{ height: "455px !important;width: 100%; !important;" }}> */}
          <Row>
            <Col lg="12" className=" text-center">
              <h2 className="section__title" style={{ marginBottom: "32px" }}>
                Happy Customers
              </h2>
            </Col>

            <Testimonial />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
