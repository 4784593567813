import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Link, Redirect, useNavigate } from "react-router-dom";
import "../../styles/browse-used-cars.css";
import MitsubishiIcon from "../../assets/all-images/BMW.png";
import PorscheIcon from "../../assets/all-images/Chevrolet.png";
import FordIcon from "../../assets/all-images/GMC.png";
import RangeRoverIcon from "../../assets/all-images/Jeep.png";
import HondaIcon from "../../assets/all-images/Lexus.png";
import MercedesIcon from "../../assets/all-images/Mercedes-Benz.png";
import NissanIcon from "../../assets/all-images/Nissan.png";
import ToyotaIcon from "../../assets/all-images/Porsche.png";
import GroupIcon from "../../assets/all-images/Toyota.png";

const BrowseUsedCars = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState();
  const [models, setModels] = useState();
  const [cities, setCities] = useState();
  const [activetag, setActivetag] = useState("Make");
  useEffect(() => {
    fetchAllBrands();
    fetchAllModels();
    fetchAllUaeCities();
  }, []);
  const fetchAllBrands = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-front-brands", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setBrands(data.data);
      });
  };
  const fetchAllModels = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-front-models", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setModels(data.data);
      });
  };

  const fetchAllUaeCities = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-uae-cities", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setCities(data.data);
      });
  };
  const searchWithBrandId = (id) => {
    localStorage.setItem("brandId", id);
    navigate(`/cars?brandId=${id}`);
  };
  const searchWithModelId = (id) => {
    localStorage.setItem("modelId", id);
    navigate(`/cars?modelId=${id}`);
  };
  const searchWithCityId = (id) => {
    localStorage.setItem("cityId", id);
    navigate(`/cars?cityId=${id}`);
  };
  const searchWithPriceFilter = (fromPrice, toPrice) => {
    localStorage.setItem("fromPrice", fromPrice);
    localStorage.setItem("toPrice", toPrice);
    navigate(`/cars?fromPrice=${fromPrice}?toPrice=${toPrice}`);
  };
  return (
    <>
      <div id="browse-used-cars" className="browse-used-cars row">
        {/* <div className="col-10 offset-1 mt-5"> */}
        <div className="col-10 offset-1">
          <ul className="nav nav-tabs row gy-4 d-flex" role="tablist">
            <li className="nav-item col" role="presentation">
              <a
                className={
                  activetag == "Make" ? "nav-link show active" : "nav-link"
                }
                data-bs-toggle="tab"
                data-bs-target="#tab-11"
                aria-selected="true"
                role="tab"
                onClick={() => setActivetag("Make")}
              >
                <h4 className="textNoWrap">Make</h4>
              </a>
            </li>

            <li className="nav-item col" role="presentation">
              <a
                className={
                  activetag == "Model" ? "nav-link show active" : "nav-link"
                }
                data-bs-toggle="tab"
                data-bs-target="#tab-11"
                aria-selected="true"
                role="tab"
                onClick={() => setActivetag("Model")}
              >
                <h4 className="textNoWrap">Model</h4>
              </a>
            </li>

            <li className="nav-item col" role="presentation">
              <a
                className={
                  activetag == "City" ? "nav-link show active" : "nav-link"
                }
                data-bs-toggle="tab"
                data-bs-target="#tab-11"
                aria-selected="true"
                role="tab"
                onClick={() => setActivetag("City")}
              >
                <h4 className="textNoWrap">City</h4>
              </a>
            </li>

            <li className="nav-item col" role="presentation">
              <a
                className={
                  activetag == "Prices" ? "nav-link show active" : "nav-link"
                }
                data-bs-toggle="tab"
                data-bs-target="#tab-11"
                aria-selected="true"
                role="tab"
                onClick={() => setActivetag("Prices")}
              >
                <h4 className="textNoWrap">Prices</h4>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            {activetag == "Make" && (
              <div className="tab-pane active show" id="tab-11" role="tabpanel">
                <div className="">
                  <div className="usedCarIconWrapper">
                    {brands?.data?.map((brand, index) => (
                      <React.Fragment key={index}>
                        <div className="col">
                          {/* <div className="col" onClick={() => searchWithBrandId(brand?.id)}> */}
                          <div className="makemodeliamge">
                            <img
                              // style={{ width: "120px", cursor: "pointer" }}
                              onClick={() => searchWithBrandId(brand?.id)}
                              src={brand?.image}
                              alt={`Car Icon ${index}`}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {activetag == "Model" && (
              <div className="tab-pane active show" id="tab-11" role="tabpanel">
                <div className="">
                  <div className="usedCarIconWrapper">
                    {models?.data?.map((model, index) => (
                      <>
                        <div className="col">
                          <div className="makemodeliamge">
                            <img
                              // style={{ width: "120px", cursor: "pointer" }}
                              onClick={() => searchWithModelId(model?.id)}
                              src={model?.image}
                              alt={`Car Icon ${index}`}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {activetag == "City" && (
              <div className="tab-pane active show" id="tab-11" role="tabpanel">
                <div className="">
                  <div className="usedCarIconWrapper">
                    {cities?.map((city, index) => (
                      <>
                        <div
                          style={{ minWidth: "150px", cursor: "pointer" }}
                          onClick={() => searchWithCityId(city?.id)}
                          className="col"
                        >
                          <h5>{city?.name}</h5>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {activetag == "Prices" && (
              <div className="tab-pane active show" id="tab-11" role="tabpanel">
                <div className="">
                  <div className="usedCarIconWrapper">
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 200000)}
                      className="col"
                    >
                      <h5>Car under 2 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 400000)}
                      className="col"
                    >
                      <h5>Car under 4 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 600000)}
                      className="col"
                    >
                      <h5>Car under 6 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 800000)}
                      className="col"
                    >
                      <h5>Car under 8 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 100000)}
                      className="col"
                    >
                      <h5>Car under 10 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 1200000)}
                      className="col"
                    >
                      <h5>Car under 12 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 1400000)}
                      className="col"
                    >
                      <h5>Car under 14 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 1600000)}
                      className="col"
                    >
                      <h5>Car under 16 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 1800000)}
                      className="col"
                    >
                      <h5>Car under 18 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 2000000)}
                      className="col"
                    >
                      <h5>Car under 20 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 2200000)}
                      className="col"
                    >
                      <h5>Car under 22 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => searchWithPriceFilter(0, 2400000)}
                      className="col"
                    >
                      <h5>Car under 24 Lakhs</h5>
                    </div>
                    <div
                      style={{ minWidth: "150px", cursor: "pointer" }}
                      onClick={() => navigate(`/cars`)}
                      className="col"
                    >
                      <h5>Car under 25+ Lakhs</h5>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="spacelogos"></div> */}
            <div className="tab-pane" id="tab-22" role="tabpanel">
              <div className="row gy-4">
                <div className="col-12"></div>
              </div>
            </div>

            <div className="tab-pane" id="tab-33" role="tabpanel">
              <div className="row gy-4">
                <div className="col-12"></div>
              </div>
            </div>

            <div className="tab-pane" id="tab-44" role="tabpanel">
              <div className="row gy-4">
                <div className="col-12"></div>
              </div>
            </div>

            <div className="tab-pane" id="tab-55" role="tabpanel">
              <div className="row gy-4">
                <div className="col-12"></div>
              </div>
            </div>

            <div className="tab-pane" id="tab-66" role="tabpanel">
              <div className="row gy-4">
                <div className="col-12"></div>
              </div>
            </div>

            <div className="tab-pane" id="tab-77" role="tabpanel">
              <div className="row gy-4">
                <div className="col-12"></div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12 mt-3"> */}
        <div className="col-12" style={{ marginTop: "16px" }}>
          <div className="col-8 offset-2 feature-ads-mb">
            <div className="featured-ads featureAdContainer">
              <div className="featuredAdPromoBox">
                <h2 className="featureAdHeading">
                  Get Your Ad Featured for more calls and to sell Quicker
                </h2>
                <p className="featuredAdPromo">
                  <i className="bi bi-check-lg text-primary"></i> Sell up to 10x
                  faster
                </p>
                <p className="featuredAdPromo">
                  <i className="bi bi-check-lg text-primary"></i> Get your Ad
                  noticed by all Buyers
                </p>
                <p className="featuredAdPromo">
                  <i className="bi bi-check-lg text-primary"></i> Standout with
                  the Feature Ad tag
                </p>
                <div className="">
                  {/* <button className="btn theme-color btn-learn-more btn-secondary">
                    Learn More
                  </button> */}
                </div>
              </div>
              <div className="featureAdImage">
                <img className="list-group-img" src={GroupIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrowseUsedCars;
