import React from "react";
import "../../styles/react-switch.css";

const Switcher = ({ checked, handleChange1, handleChange2 }) => {
  // const [checked, setChecked] = useState(false);
  // const handleChange1 = (nextChecked) => {
  //   setChecked(true);
  // };
  // const handleChange2 = (nextChecked) => {
  //   setChecked(false);
  // };
  return (
    <div className="container d-flex pl-0" style={{ paddingLeft: 0 }}>
      <div className="react-switch">
        <button
          onClick={handleChange1}
          style={{
            backgroundColor: checked ? "#27aae1" : "",
            color: checked ? "#fff" : "",
          }}
        >
          Cars
        </button>
        <button
          onClick={handleChange2}
          style={{
            backgroundColor: !checked ? "#27aae1" : "",
            color: !checked ? "#fff" : "",
          }}
        >
          Rental cars
        </button>
      </div>
    </div>
  );
};

export default Switcher;
