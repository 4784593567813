import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";
// import Avatar from "react-avatar";

import ava01 from "../../assets/all-images/ava-1.jpg";
import ava02 from "../../assets/all-images/ava-2.jpg";
import ava03 from "../../assets/all-images/ava-3.jpg";
import ava04 from "../../assets/all-images/ava-4.jpg";
import GoogleIcon from "../../assets/all-images/google-icon.png";
import Group160 from "../../assets/all-images/Group 160.png";
// import StarIcon from '@mui/icons-material/Star';
import starim from "../../assets/all-images/star.png";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 587,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  return (
    // <Slider style={{ height: "385px", with: "100%" }} {...settings}>
    <Slider style={{ with: "100%" }} {...settings}>
      {/* <div className="testimonial py-4 px-3"> */}
      <div className="testimonial px-3">
        {/* <div className="testimonial py-4 px-3"> */}
        <div className="testimonial px-3">
          <div className="">
            <div
              id="carouselExampleCaptions"
              className="carousel carousel-dark slide"
              data-bs-ride="false"
              style={{ width: "305px" }}
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card-group">
                    <div className="card">
                      <div className="card-body">
                        <p className="stars-flex-design">
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                        </p>
                        <h5 className="slidertitlecard">
                          Great work n adirsha rahim
                        </h5>
                      </div>
                      <div className="card-body mt-5 d-flex group1">
                        <div className="left-area mt-2">
                          <img src={Group160} />
                        </div>
                        <div className="mid-area mt-2">
                          <p className="title1">Jahanzab b...</p>
                          <p className="date12">8 Feberuary,2023</p>
                        </div>
                        <div className="right-area mt-2">
                          <img width="60" height="60" src={GoogleIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial  px-3">
        <div className="testimonial  px-3">
          <div className="">
            <div
              id="carouselExampleCaptions"
              className="carousel carousel-dark slide"
              data-bs-ride="false"
              style={{ width: "305px" }}
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card-group">
                    <div className="card">
                      <div className="card-body">
                        <p className="stars-flex-design">
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                        </p>
                        <h5 className="slidertitlecard">
                          Great work n adirsha rahim
                        </h5>
                      </div>
                      <div className="card-body mt-5 d-flex group1">
                        <div className="left-area mt-2">
                          <img src={Group160} />
                        </div>
                        <div className="mid-area mt-2">
                          <p className="title1">Jahanzab b...</p>
                          <p className="date12">8 Feberuary,2023</p>
                        </div>
                        <div className="right-area mt-2">
                          <img width="60" height="60" src={GoogleIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial  px-3">
        <div className="testimonial  px-3">
          <div className="">
            <div
              id="carouselExampleCaptions"
              className="carousel carousel-dark slide"
              data-bs-ride="false"
              style={{ width: "305px" }}
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card-group">
                    <div className="card">
                      <div className="card-body">
                        <p className="stars-flex-design">
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                        </p>
                        <h5 className="slidertitlecard">
                          Great work n adirsha rahim
                        </h5>
                      </div>
                      <div className="card-body mt-5 d-flex group1">
                        <div className="left-area mt-2">
                          <img src={Group160} />
                        </div>
                        <div className="mid-area mt-2">
                          <p className="title1">Jahanzab b...</p>
                          <p className="date12">8 Feberuary,2023</p>
                        </div>
                        <div className="right-area mt-2">
                          <img width="60" height="60" src={GoogleIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial  px-3">
        <div className="testimonial  px-3">
          <div className="">
            <div
              id="carouselExampleCaptions"
              className="carousel carousel-dark slide"
              data-bs-ride="false"
              style={{ width: "305px" }}
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card-group">
                    <div className="card">
                      <div className="card-body">
                        <p className="stars-flex-design">
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                        </p>
                        <h5 className="slidertitlecard">
                          Great work n adirsha rahim
                        </h5>
                      </div>
                      <div className="card-body mt-5 d-flex group1">
                        <div className="left-area mt-2">
                          <img src={Group160} />
                        </div>
                        <div className="mid-area mt-2">
                          <p className="title1">Jahanzab b...</p>
                          <p className="date12">8 Feberuary,2023</p>
                        </div>
                        <div className="right-area mt-2">
                          <img width="60" height="60" src={GoogleIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial  px-3">
        <div className="testimonial  px-3">
          <div className="">
            <div
              id="carouselExampleCaptions"
              className="carousel carousel-dark slide"
              data-bs-ride="false"
              style={{ width: "305px" }}
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card-group">
                    <div className="card">
                      <div className="card-body">
                        <p className="stars-flex-design">
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                          <img src={starim} />
                        </p>
                        <h5 className="slidertitlecard">
                          Great work n adirsha rahim
                        </h5>
                      </div>
                      <div className="card-body mt-5 d-flex group1">
                        <div className="left-area mt-2">
                          <img src={Group160} />
                        </div>
                        <div className="mid-area mt-2">
                          <p className="title1">Jahanzab b...</p>
                          <p className="date12">8 Feberuary,2023</p>
                        </div>
                        <div className="right-area mt-2">
                          <img width="60" height="60" src={GoogleIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonial;
